.popup_wrap_event {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  min-width: 320px;
  background-color: #fff;
  border: 1px solid #ddd;
  img {
    max-width: 100%;
    &.pc {
      max-width: 562px;
      @media screen and (max-width: 750px) {
        display: none;
      }
    }
    &.mb {
      display: none;
      max-width: 320px;
      @media screen and (max-width: 750px) {
        display: inline;
      }
    }
  }
  input {
    border: 1px solid #ddd;
    appearance: auto;
  }
  .b-eventClose {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    label {
      font-size: 17px;
      font-weight: 400;
      color: #333;
      display: flex;
      align-items: center;
      input {
        margin-right: 10px;
      }
    }
  }
  .b-eventClose__btn {
    border: 0;
    background-color: transparent;
    background-image: url('../../public/images/icon/img-eventCloseBtn.png');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 10px;
    background-size: 13px;
    cursor: pointer;
    span {
      font-size: 17px;
      font-weight: 400;
      color: #333;
      margin-right: 10px;
    }
  }
}