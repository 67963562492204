// 개인정보 동의
.personalInfo{
  padding: 0 0 70px 0;
  @media screen and (max-width: 800px){
    padding: 0 0 80px 0;
  }
  h3{
    margin: 0 0 45px 0;
    font-size: 26px;
    font-weight: 500;
    font-family: "NanumSquareNeo";
    color: #000000;
    text-align: left;
    @media screen and (max-width: 800px){
      margin: 0 0 26px 0;
      font-size: 22px;
    }
    @media screen and (max-width: 400px){
      margin: 0 0 20px 0;
      font-size:18px;
    }
  }
  .notice{
    margin: 0 0 20px 0;
    font-size: 15px;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.015em;
    color: #5c5c5c;
    @media screen and (max-width: 400px){
      margin: 0 0 10px 0 ;
      font-size: 14px;
    }
  }
  .useGuide{
    margin: 0 0 25px 0;
    padding: 30px 20px;
    box-shadow: 0px 0px 15px 0px rgba(184, 184, 184, 0.15) , 0px 2px 15px 0px rgba(184, 184, 184, 0.15);
    background: #ffffff;
    @media screen and (max-width: 400px){
      padding: 25px 20px;
    }
    .content{
      padding:0 15px;
      height: 240px;
      overflow-y: scroll;
      word-break: keep-all;
      -webkit-overflow-scrolling: touch;
      @media screen and (max-width: 400px){
        height: 300px;
      }
      & > p{
        margin: 0;
        color: #000000;
        font-size: 16px;
        font-weight: 300;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        line-height: 1.6;
        word-break: keep-all;
        text-align: left;
        @media screen and (max-width: 800px){
          font-size: 15px;
        }
        @media screen and (max-width: 400px){
          font-size: 14px;
        }
      }
      .terms_text {
        margin: 0 0 45px 0;
        @media screen and (max-width: 800px){
          margin: 0 0 40px 0;
        }
        @media screen and (max-width: 400px){
          margin: 0 0 30px 0;
        }
      }
      .terms_text_number {
        margin: 0 0 5px 0;
      }
      .terms_text_underText {
        margin: 0 0 5px 0;
      }
      .terms_text_list {
        margin: 0 0 5px 0;
      }
      .terms_subTitle {
        margin: 0 0 20px 0;
      }
      .terms_text_listTitle{
        margin: 0 0 5px 0;
      }
      .mb30{
        margin: 0 0 30px 0;
      }
      .mb20{
        margin: 0 0 20px 0;
      }
    }
  }
  .btn_chk {
    .checkBox{
      position: relative;
      padding: 0 !important;
      cursor: pointer;
      text-align: left;
      border: none !important;
      .check_box {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 17px;
        height: 17px;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        @media screen and (max-width: 400px) {
          top: 55%;
        }
      }
      input:checked ~ .checkmark {
        background: #4265ae;
        border: 1px solid #4265ae;
      }
      input:checked ~ .checkmark:after {
        content: '';
        position: absolute;
        top: 45%;
        left: 50%;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        cursor: pointer;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        -ms-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      }
      .agree {
        margin: 0 0 0 35px;
        font-size: 16px;
        font-family: "NanumSquareNeo";
        font-weight: 400;
        letter-spacing: -0.015em;
        color: #000000;
        text-align: left;
        @media screen and (max-width: 400px) {
          margin: 0 0 0 25px;
          font-size: 15px;
        }
      }
    }
  }
}