// main_visual
.main_visual {
  padding: 255px 50px 100px 50px;
  @media screen and (max-width: 1000px) {
    padding: 150px 50px 100px;
  }
  @media screen and (max-width: 900px) {
    padding: 150px 40px ;
  }
  @media screen and (max-width: 600px) {
    padding: 150px 30px 100px;
  }
  .container {
    position: relative;
    padding: 0 35px;
    @media screen and (max-width: 1700px) {
      padding: 0;
      width: auto;
    }
  }
  .container {
    gap: 170px;
    width: auto;
    @media screen and (max-width: 1700px) {
      justify-content: space-evenly;
    }
    @media screen and (max-width: 1300px) {
      gap: 0;
      justify-content: space-between;
    }
    @media screen and (max-width: 1150px) {
      gap: 50px;
    }
    @media screen and (max-width: 1000px) {
     flex-direction: column;
      align-items: center;
      gap: 0;
    }
    .title {
      @media screen and (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @media screen and (max-width: 1000px) {
        display: block;
        order: 2;
      }
      .topsub {
        margin: 0 0 38px 0;
        font-size: 22px;
        font-family: 'NanumSquareNeo';
        font-weight: 400;
        letter-spacing: -0.02em;
        color: #232a35;
        opacity: 0;
        line-height: initial;
        word-break: keep-all;
        @media screen and (max-width: 1150px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1000px) {
          margin: 0 0 30px 0;
          text-align: center;
        }
        @media screen and (max-width: 650px) {
          font-size: 17px;
        }
      }
      h2 {
        margin: 0 0 50px 0;
        font-size: 64px;
        font-weight: 500;
        font-family: 'NanumSquareNeo';
        line-height: 1.35;
        letter-spacing: -0.025em;
        color: #252525;
        word-break: keep-all;
        @media screen and (max-width: 1310px) {
          font-size: 62px;
        }
        @media screen and (max-width: 1250px) {
          font-size: 52px;
        }
        @media screen and (max-width: 1150px) {
          font-size: 45px;
        }
        @media screen and (max-width: 1000px) {
          text-align: center;
        }
        @media screen and (max-width: 650px) {
          margin: 0 0 35px 0;
          font-size: 36px;
        }
        .blue {
          color: #3869c1;
        }
      }
      .subtit {
        font-size: 24px;
        font-weight: 400;
        font-family: 'NanumSquareNeo';
        color: #272727;
        letter-spacing: -0.025em;
        line-height: 1.6;
        word-break: keep-all;
        @media screen and (max-width: 1250px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1000px) {
          text-align: center;
        }
        @media screen and (max-width: 650px) {
          font-size: 17px;
        }
        .bold {
          font-weight: 600;
        }
      }
    }
    .main_image {
      width: 550px;
      height: 520px;
      z-index: 0;
      //opacity: 0;
      @media screen and (max-width: 1000px) {
        order: 1;
        margin: 0 0 45px 0;
      }
      @media screen and (max-width: 800px) {
        margin: 0 0 80px 0;
        order: 1;
        width: 400px;
        height: 320px;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 70px 0;
        order: 1;
        width: 345px;
        height: 280px;
      }
      @media screen and (max-width: 400px) {
        margin: 0 0 100px 0;
        width: 300px;
        height: 220px;
      }
      img {
        position: relative;
      }
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: -10px;
        background-image: url(../../public/images/main_visual02.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 200px 210px;
        width: 200px;
        height: 210px;
        z-index: -3;
        opacity: 0;
        @media screen and (max-width: 1000px) {
          top: 15px;
          right: 25%;
          background-size: 85%;
        }
        @media screen and (max-width: 950px) {
          top: 3%;
          right: 20%;
          background-size: 85%;
        }
        @media screen and (max-width: 800px) {
          top: 0;
          right: 19%;
          background-size: 77%;
        }
        @media screen and (max-width: 700px) {
          top: 0;
          right: 14%;
          background-size: 77%;
        }
        @media screen and (max-width: 650px) {
          top: -3%;
          right: 12%;
          background-size: 63%;
        }
        @media screen and (max-width: 550px) {
          top: -3%;
          right: 8%;
          background-size: 61%;
        }
        @media screen and (max-width: 470px) {
          top: -3%;
          right: 0;
        }
        @media screen and (max-width: 400px) {
          top: -5.5%;
          right: -7%;
          background-size: 55%;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 77px;
        left: -10px;
        background-image: url(../../public/images/main_visual03.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 210px 180px;
        width: 210px;
        height: 180px;
        z-index: 1;
        opacity: 0;
        @media screen and (max-width: 1000px) {
          top: 80px;
          left: 23%;
          background-size: 85%;
        }
        @media screen and (max-width: 950px) {
          top: 12%;
          left: 20%;
          background-size: 87%;
        }
        @media screen and (max-width: 800px) {
          top: 6%;
          left: 18%;
          background-size: 73%;
        }
        @media screen and (max-width: 700px) {
          top: 6%;
          left: 13%;
          background-size: 70%;
        }
        @media screen and (max-width: 650px) {
          top: 5%;
          left: 13%;
          background-size: 57%;
        }
        @media screen and (max-width: 550px) {
          top: 3%;
          left: 6%;
          background-size: 55%;
        }
        @media screen and (max-width: 470px) {
          top: 5%;
          left: -1%;
        }
        @media screen and (max-width: 400px) {
          top: 2%;
          left: -6%;
          background-size: 49%;
        }
      }
    }
  }
  .totalbtn {
    position: absolute;
    bottom: -20%;
    left: 1.9%;
    justify-content: space-between;
    padding: 0 15px;
    margin: 0 0 60px 0;
    animation: ani_3 0.8s 1s;
    animation-fill-mode: both;
    @media screen and (max-width: 1700px) {
      left: 3.5%;
    }
    @media screen and (max-width: 1450px) {
      left: 2.8%;
    }
    @media screen and (max-width: 1350px) {
      left: -0.5%;
    }
    @media screen and (max-width: 1100px) {
      display: none;
    }
    .scrdwn {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      .line {
        position: relative;
        display: block;
        width: 2px;
        height: 31px;
        background: #000000;
        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: calc(50% - 7px);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #ffffff;
          border: 2px solid #000000;
          animation: down 1.3s steps(10) infinite;
          z-index: 1;
        }
      }
      .down {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 26px;
        font-family: 'NanumSquareNeo';
      }
    }
  }
}
.main_visual {
  &.active_mv {
    .topsub, h2 {
      animation: main_effect 0.5s ease-out;
      animation-fill-mode: both;
      opacity: 1;
      @media screen and (max-width: 1000px) {
        animation: main_effect 0.5s 1s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
    .subtit {
      animation: main_effect 0.5s 0.5s ease-out;
      animation-fill-mode: both;
      opacity: 1;
      @media screen and (max-width: 1000px) {
        animation: main_effect 0.5s 1.2s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
    .main_image {
      animation: main_effect 0.5s 1s ease-out;
      animation-fill-mode: both;
      opacity: 1;
      @media screen and (max-width: 1000px) {
        animation: main_effect 0.5s ease-out;
        opacity: 1;
      }
      &:before {
        animation: moving_icon1 1s 1.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      &:after {
        animation: moving_icon2 1s 1.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
//.active_mv .topsub, .active_mv .maintit {
//  animation: main_effect 0.5s ease-out;
//  animation-fill-mode: both;
//  opacity: 1;
//  @media screen and (max-width: 1000px) {
//    animation: main_effect 0.5s 1s ease-out;
//  }
//}
//.active_mv .subtit {
//  animation: main_effect 0.5s 0.5s ease-out;
//  animation-fill-mode: both;
//  opacity: 1;
//  @media screen and (max-width: 1000px) {
//    animation: main_effect 0.5s 1s ease-out;
//  }
//}
//.active_mv .main_image {
//  animation: main_effect 0.5s 1s ease-out;
//  animation-fill-mode: both;
//  opacity: 1;
//  @media screen and (max-width: 1000px) {
//    animation: main_effect 0.5s ease-out;
//    opacity: 1;
//  }
//}
//.active_mv .main_image:before {
//  animation: moving_icon1 0.8s 1.5s ease-out;
//  animation-fill-mode: both;
//  opacity: 1;
//}
//.active_mv .main_image:after {
//  animation: moving_icon2 0.8s 1.5s ease-out;
//  animation-fill-mode: both;
//  opacity: 1;
//}
.test {
  position: absolute;
  top: 50%;
  left: 600px;
  z-index: 998;
  h3 {
    color: #fff;
    font-size: 50px;
  }
}
//main01
.main_01 {
  padding: 127px 50px 150px 50px;
  background-image: url(../../public/images/main01_background.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  @media screen and (max-width: 1000px) {
    background-size: 100% 460px;
    padding: 127px 40px 100px 40px;
  }
  @media screen and (max-width: 750px) {
    padding: 50px 40px 80px 40px;
  }
  @media screen and (max-width: 480px) {
    background-size: 100% 390px;
    padding: 50px 30px 80px 30px;
  }
  .container {
    @media screen and (max-width: 1700px) {
      width: auto;
    }
  }
  h2 {
    margin: 0 0 35px 0;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
    transition: 0.5s;
    font-family: 'NanumSquareNeo';
    letter-spacing: -0.04em;
    color: #111111;
    word-break: keep-all;
    @media screen and (max-width: 1000px) {
      margin: 0 0 25px 0;
      font-size: 38px;
    }
    @media screen and (max-width: 700px) {
      font-size: 25px;
      line-height: 1.3;
    }
    .w700{
      display: none;
      @media screen and (max-width: 700px) {
        display: block;
      }
    }
    .blue {
      color: #3863a2;
      font-weight: 600;
      font-size: 43px;
      transition: 0.5s;
      @media screen and (max-width: 700px) {
        font-size: 38px;
      }
    }
  }
  .explanation {
    margin: 0 0 65px 0;
    font-size: 20px;
    font-weight: 300;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.035em;
    line-height: 1.4;
    text-align: center;
    color: #111111;
    transition: 0.5s;
    word-break: keep-all;
    @media screen and (max-width: 1000px) {
      margin: 0 0 60px 0;
      line-height: 1.6;
    }
    @media screen and (max-width: 700px) {
      margin: 0 0 45px 0;
      font-size: 16px;
    }
    br{
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    .bold {
      font-weight: 600;
    }
    .w800 {
      display: none;
      @media screen and (max-width: 1000px) {
        display: inline-block;
      }
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
  .link_box {
    justify-content: space-evenly;
    @media screen and (max-width: 1370px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 1250px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 1150px) {
      justify-content: space-evenly;
    }
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
    & > li {
      position: relative;
      padding: 300px 53px 60px;
      width: calc(100% / 3);
      transform: translateY(0);
      border-radius: 10px;
      transition: 0.3s;
      &:hover {
        transform: translateY(-20px);
        @media screen and (max-width: 1000px) {
          transform: translateY(0);
        }
      }
      @media screen and (max-width: 1368px) {
        padding: 250px 30px 60px;
        width: auto;
      }
      @media screen and (max-width: 1300px) {
        padding: 250px 30px 70px;
      }
      @media screen and (max-width: 1230px) {
        padding: 250px 30px 60px;
      }
      @media screen and (max-width: 1000px) {
        padding: 50px 80px;
        background-image: url(../../public/images/main01_inbound_width.png);
        background-size: 100%;
        max-width: initial;
      }
      @media screen and (max-width: 620px) {
        padding: 50px;
      }
      @media screen and (max-width: 620px) {
        padding: 40px;
      }
      &:nth-child(1) {
        background-image: url(../../public/images/main01_inbound.png);
        background-repeat: no-repeat;
        background-position: center;
        span {
          color: #a9c4ec;
        }
        &:after {
          content: "";
          position: absolute;
          top: 70px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url(../../public/images/icon/inbound_icon.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 189px;
          height: 189px;
          cursor: pointer;
          @media screen and (max-width: 1368px) {
            top: 9%;
            background-size: 90%;
          }
            @media screen and (max-width: 1300px) {
              top: 10%;
              background-size: 80%;
            }
            @media screen and (max-width: 1000px) {
              top: 50%;
              left: 10%;
              transform: translateY(-50%);
              background-size: 75%;
            }
          @media screen and (max-width: 720px) {
            top: 10%;
            left: initial;
            right: 7%;
            transform: translate(0, 0);
            background-image: url(../../public/images/icon/inbound_icon_400.png);
            width: 100px;
            height: 100px;
          }
          @media screen and (max-width: 530px) {
            top: 9%;
            width: 85px;
            height: 85px;
          }
          @media screen and (max-width: 470px) {
            top: 11%;
            width: 80px;
            height: 80px;
          }
          @media screen and (max-width: 430px) {
            top: 15%;
            width: 75px;
            height: 75px;
          }
          @media screen and (max-width: 385px) {
            top: 14%;
            width: 70px;
            height: 70px;
          }
        }
      }
      &:nth-child(2) {
        background-image: url(../../public/images/main01_outbound.png);
        background-repeat: no-repeat;
        background-position: center;
        @media screen and (max-width: 1250px) {
          margin: 0 20px;
        }
        @media screen and (max-width: 1000px) {
          margin: 0;
        }
        span {
          color: #bed7f5;
        }
        &:after {
          content: "";
          position: absolute;
          top: 70px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url(../../public/images/icon/outbound_icon.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 189px;
          height: 189px;
          cursor: pointer;
          @media screen and (max-width: 1368px) {
            top: 9%;
            background-size: 90%;
          }
          @media screen and (max-width: 1300px) {
            top: 10%;
            background-size: 80%;
          }
          @media screen and (max-width: 1000px) {
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
            background-size: 75%;
          }
          @media screen and (max-width: 720px) {
            top: 10%;
            left: initial;
            right: 7%;
            transform: translate(0, 0);
            background-image: url(../../public/images/icon/outbound_icon_400.png);
            width: 100px;
            height: 100px;
          }
          @media screen and (max-width: 530px) {
            top: 9%;
            width: 85px;
            height: 85px;
          }
          @media screen and (max-width: 470px) {
            top: 11%;
            width: 80px;
            height: 80px;
          }
          @media screen and (max-width: 430px) {
            top: 15%;
            width: 75px;
            height: 75px;
          }
          @media screen and (max-width: 385px) {
            top: 14%;
            width: 70px;
            height: 70px;
          }
        }
      }
      &:nth-child(3) {
        background-image: url(../../public/images/main01_network.png);
        background-repeat: no-repeat;
        background-position: center;
        h3 {
          color: #000000;
        }
        span {
          color: #797979;
        }
        p {
          color: #111111;
        }
        &:after {
          content: "";
          position: absolute;
          top: 70px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url(../../public/images/icon/network_icon.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 189px;
          height: 189px;
          cursor: pointer;
          @media screen and (max-width: 1300px) {
            top: 10%;
            background-size: 80%;
          }
          @media screen and (max-width: 1000px) {
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
            background-size: 75%;
          }
          @media screen and (max-width: 720px) {
            top: 10%;
            left: initial;
            right: 7%;
            transform: translate(0, 0);
            background-image: url(../../public/images/icon/network_icon_400.png);
            width: 100px;
            height: 100px;
          }
          @media screen and (max-width: 530px) {
            top: 11%;
            width: 85px;
            height: 85px;
          }
          @media screen and (max-width: 470px) {
            top: 12.5%;
            width: 80px;
            height: 80px;
          }
          @media screen and (max-width: 430px) {
            top: 15%;
            width: 75px;
            height: 75px;
          }
          @media screen and (max-width: 385px) {
            top: 16%;
            width: 70px;
            height: 70px;
          }
        }
      }
    }
    .box_tit {
      color: #ffffff;
      font-family: 'NanumSquareNeo';
      @media screen and (max-width: 1300px) {
        max-width: 305px;
        max-height: 130px;
      }
      @media screen and (max-width: 1230px) {
        max-width: 270px;
        max-height: 190px;
      }
      @media screen and (max-width: 1000px) {
        margin: 0 0 0 250px;
        max-width: 400px;
        max-height: 130px;
      }
      @media screen and (max-width: 850px) {
        margin: 0 0 0 200px;
        width: auto;
        height: auto;
      }
      @media screen and (max-width: 720px) {
        margin: 0;
      }
      h3 {
        margin: 0 0 10px 0;
        font-size: 38px;
        font-weight: 500;
        letter-spacing: -0.03em;
        @media screen and (max-width: 1300px) {
          font-size: 34px;
        }
        @media screen and (max-width: 530px) {
          margin: 0 0 5px 0;
          font-size: 24px;
        }
      }
      span {
        display: inline-block;
        margin: 0 0 35px 0;
        font-size: 16px;
        letter-spacing: -0.03em;
        color: #7494c5;
        @media screen and (max-width: 1300px) {
          margin: 0 0 20px 0;
          font-size: 15px;
        }
        @media screen and (max-width: 530px) {
          margin: 0 0 15px 0;
          font-size: 12px;
        }
      }
      p {
        font-size: 17px;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 1.5;
        letter-spacing: -0.025em;
        opacity: 1;
        word-break: keep-all;
        @media screen and (max-width: 1300px) {
          font-size: 16px;
        }
        @media screen and (max-width: 530px) {
          font-size: 12px;
        }
        .w1200 {
          @media screen and (max-width: 1210px) {
            display:  none;
          }
        }
      }
    }
  }
}
// main02
.main_02 {
  padding: 185px 50px 90px;
  background-image: url(../../public/images/main02_background.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 480px;
  @media screen and (max-width: 1300px) {
    background-image: url(../../public/images/main02_background.png);
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  @media screen and (max-width: 1000px) {
    background-position: center bottom;
    background-size: 100% 490px;
    padding: 185px 40px 90px;
  }
  @media screen and (max-width: 884px) {
    background-position: center bottom;
    background-size: 100% 530px;
    padding: 100px 40px 90px;
  }
  @media screen and (max-width: 764px) {
    background-position: center bottom;
    background-size: 100% 570px;
  }
  @media screen and (max-width: 745px) {
    background-position: center bottom;
    background-size: 100% 560px;
  }
  @media screen and (max-width: 700px) {
    background-position: center bottom;
    background-size: 100% 570px;
  }
  @media screen and (max-width: 686px) {
    background-position: center bottom;
    background-size: 100% 620px;
  }
  @media screen and (max-width: 600px) {
    background-position: center bottom;
    background-size: 100% 970px;
  }
  @media screen and (max-width: 465px) {
    background-position: center bottom;
    background-size: 100% 1100px;
    padding: 100px 30px 90px;
  }
  @media screen and (max-width: 330px) {
    background-position: center bottom;
    background-size: 100% 1300px;
  }
  * {
    transition: 0.5s;
  }
  .active_main02 .w1200 {
    animation: ani_1 0.6s ease-out;
    animation-fill-mode: both;
  }
  .w1200 {
    @media screen and (max-width: 1300px) {
      width: auto;
    }
    p {
      &:first-child {
        margin: 0 0 20px 0;
        font-size: 26px;
        font-weight: 300;
        font-family: 'NanumSquareNeo';
        color: #000000;
        letter-spacing: -0.015em;
        word-break: keep-all;
        @media screen and (max-width: 1200px) {
          margin: 0 0 20px 0;
          font-size: 20px;
          text-align: center;
        }
        @media screen and (max-width: 700px) {
          font-size: 18px;
          letter-spacing: -0.03em;
        }
        .w505{
          display: none;
          @media screen and (max-width: 505px) {
           display: block;
          }
        }
        .w630 {
          display: none;
          @media screen and (max-width: 630px) {
            display: block;
          }
        }
      }
      &:last-child {
        margin: 0 0 120px 0;
        font-size: 28px;
        font-weight: 400;
        font-family: 'NanumSquareNeo';
        color: #000000;
        @media screen and (max-width: 1200px) {
          margin: 0 0 100px 0;
          font-size: 22px;
          text-align: center;
        }
        @media screen and (max-width: 1200px) {
          margin: 0 0 60px 0;
          font-size: 20px;
          letter-spacing: -0.03em;
        }
        .w630 {
          display: none;
          @media screen and (max-width: 630px) {
            display: block;
          }
        }
      }
    }
    h2 {
      margin: 0 0 55px 0;
      font-size: 46px;
      font-weight: 400;
      font-family: 'NanumSquareNeo';
      color: #000000;
      letter-spacing: -0.04em;
      word-break: keep-all;
      .dis-n_m{
        display: none;
        @media screen and (max-width: 400px) {
          display: initial;
        }
      }
      @media screen and (max-width: 1300px) {
        line-height: 1.5;
      }
      @media screen and (max-width: 1200px) {
        text-align: center;
      }
      @media screen and (max-width: 1000px) {
        margin: 0 0 40px 0;
        font-size: 38px;
        text-align: center;
      }
      @media screen and (max-width: 700px) {
        font-size: 35px;
      }
      @media screen and (max-width: 500px) {
        margin: 0 0 35px 0;
        font-size: 29px;
        word-break: keep-all;
      }
      .bold {
        font-weight: 600;
      }
      .boldblue {
        font-weight: 600;
        color: #4265ae;
        .w800 {
          display: none;
          @media screen and (max-width: 1000px) {
            display: inline-block;
          }
          @media screen and (max-width: 400px) {
            display: none;
          }
        }
        .dotted {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: -13px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #67dbfd;
            @media screen and (max-width: 800px) {
              top: -18%;
              width: 7px;
              height: 7px;
            }
            @media screen and (max-width: 500px) {
              top: -18%;
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }
  }
  .w1300 {
    padding: 0 50px;
    justify-content: space-between;
    @media screen and (max-width: 1700px) {
      width: auto;
    }
    @media screen and (max-width: 1240px) {
      padding: 0;
    }
    @media screen and (max-width: 1200px) {
      justify-content: start;
      gap: 100px;
    }
    @media screen and (max-width: 1000px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 800px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: start;
      gap: 0;
    }
    .img {
      @media screen and (max-width: 1000px) {
        order: 2;
      }
      @media screen and (max-width: 600px) {
        order: 1;
        margin: 0 auto;
      }
      .num_pc {
        display: block;
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .num_m {
        display: none;
        @media screen and (max-width: 600px) {
         display: block;
        }
      }
    }
    .paragraph {
      margin: 67px 0 0 0;
      max-width: 650px;
      @media screen and (max-width: 1200px) {
        margin: 60px 0 0 0;
      }
      @media screen and (max-width: 1000px) {
        margin: 0;
        order: 1;
        width: auto;
      }
      //@media screen and (max-width: 700px) {
      //  order: 2;
      //}
        li {
        margin: 0 0 100px 0;
          @media screen and (max-width: 1000px) {
            margin: 0 0 100px 0;
          }
          @media screen and (max-width: 600px) {
            margin: 0;
            text-align: center;
            &:first-child {
              margin-top: 70px;
              &:before {
                display: none;
                margin: 20px auto 50px;
              }
            };
            &:before {
              content:  "";
              display: block;
              margin: 50px auto 50px;
              width: 40px;
              height: 1px;
              background: #ffffff;
            }
          }
        h3 {
          margin: 0 0 22px 0;
          font-size: 36px;
          font-weight: 400;
          font-family: 'NanumSquareNeo';
          line-height: 1.4;
          letter-spacing: -0.015em;
          color: #000000;
          word-break: keep-all;
          @media screen and (max-width: 1000px) {
            font-size: 30px;
          }
          @media screen and (max-width: 600px) {
            font-size: 28px;
            color: #ffffff;
          }
          @media screen and (max-width: 600px) {
            margin: 0 0 25px 0;
            font-size: 26px;
          }
          .boldblue {
            font-weight: 600;
            color: #4265ae;
            @media screen and (max-width: 600px) {
              color: #8ce4ff;
            }
          }
        }
        p {
          font-size: 20px;
          font-weight: 400;
          font-family: 'NanumSquareNeo';
          letter-spacing: -0.015em;
          line-height: 1.7;
          color: #2d2d2d;
          word-break: keep-all;
          @media screen and (max-width: 1000px) {
            font-size: 18px;
            letter-spacing: -0.04em;
          }
          @media screen and (max-width: 600px) {
            line-height: 1.6;
            color: #ffffff;
          }
          @media screen and (max-width: 600px) {
           font-size: 16px;
          }
          .w1300 {
            display: block;
            @media screen and (max-width: 1000px) {
              display: none;
            }
          }
          .w800 {
            display: none;
            @media screen and (max-width: 1000px) {
              display: none;
            }
          }
        }
        &:nth-child(2) {
          p {
            @media screen and (max-width: 1000px) {
              color: #ffffff;
            }
          }
        }
        &:last-child {
          @media screen and (max-width: 1000px) {
            margin: 0;
          }
          h3 {
            color: #ffffff;
          }
          p {
            font-weight: 400;
            color: #ffffff;
          }
          .boldblue {
            color: #67dbfd;
            font-weight: 500;
          }
        }
      }
    }
  }
}
// main_03
.main_03 {
  padding: 170px 0;
  color: #000000;
  text-align: right;
  @media screen and (max-width: 700px) {
    padding: 80px 0;
  }
  .main04_inner {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
    text-align: left;
    @media screen and (max-width: 1300px) {
      width: auto;
    }
    @media screen and (max-width: 1000px) {
      padding: 0 70px;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 30%;
      left: -20px;
      background-image: url(../../public/images/main03_line.png);
      background-repeat: no-repeat;
      background-position: center;
      width: 1600px;
      height: 2px;
      @media screen and (min-width: 1950px) {
        width: 1200px;
      }
      @media screen and (max-width: 1880px) {
        display: none;
      }
    }
  }
  .toptit {
    position: relative;
    margin: 0 0 140px 0;
    justify-content: space-between;
    transition: 0.5s;
    @media screen and (max-width: 1880px) {
      margin: 0 0 80px 0;
      padding: 0 0 40px 0;
      border-bottom: 2px solid #111111;
    }
    @media screen and (max-width: 1050px) {
      gap: 50px;
    }
    @media screen and (max-width: 1000px) {
      margin: 0 0 30px 0;
      padding: 0 0 25px 0;
      gap: 0;
    }
    @media screen and (max-width: 900px) {
      justify-content: space-between;
      gap: 20px;
    }
    @media screen and (max-width: 650px) {
      margin: 0 0 35px 0;
      padding: 0 0 35px 0;
      flex-direction: column;
      gap: 15px;
    }
    & > h2 {
      position: relative;
      font-size: 34px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      line-height: 1.45;
      letter-spacing: -0.025em;
      transition: 0.5s;
      word-break: keep-all;
      @media screen and (max-width: 1125px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 25px;
        line-height: 1.6;
        letter-spacing: -0.03em;
      }
      @media screen and (max-width: 650px) {
        order: 2;
        font-size: 24px;
        text-align: center;
      }
      .bold {
        font-weight: 500;
      }
      .none {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      .w500{
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
    }
    figure {
      @media screen and (max-width: 1000px) {
        width: 210px;
      }
      @media screen and (max-width: 800px) {
        width: 200px;
      }
      @media screen and (max-width: 710px) {
        padding: 27px 0 0 0;
        box-sizing: border-box;
      }
      @media screen and (max-width: 650px) {
        margin: 0 auto;
        padding: 0;
        order: 1;
        width: 180px;
      }
    }
  }
  .gap50 {
    position: relative;
    justify-content: space-between;
    transition: 0.5s;
    //@media screen and (max-width: 1260px) {
    //  justify-content: flex-start;
    //  gap: 50px;
    //}
    @media screen and (max-width: 1200px) {
      gap: 30px;
    }
    @media screen and (max-width: 1000px) {
      gap: 20px;
      flex-direction: column;
    }
    li {
      flex: 1;
      max-width: calc(100% / 3.33333);
      @media screen and (max-width: 1000px) {
        padding: 40px 0 40px 65px;
        background: #f5f5f5;
        border-radius: 10px;
        max-width: 100%;
      }
      @media screen and (max-width: 700px) {
        padding: 40px;
      }
      @media screen and (max-width: 650px) {
        padding: 40px 30px
      }
      p {
        word-break: keep-all;
        .w1300 {
          @media screen and (max-width: 1000px) {
            display: none;
          }
        }
        .w520 {
          display: none;
          @media screen and (max-width: 520px) {
            display: block;
          }
        }
        .w800 {
          display: none;
          @media screen and (max-width: 1000px) {
            display: block;
          }
        }
        &:first-child {
          font-size: 28px;
          font-weight: 400;
          font-family: 'NanumSquareNeo';
          letter-spacing: -0.015em;
          line-height: 1.3;
          color: #000000;
          @media screen and (max-width: 1165px) {
            font-size: 24px;
          }
          @media screen and (max-width: 800px) {
            font-size: 22px;
            line-height: initial;
          }
          @media screen and (max-width: 650px) {
            font-size: 22px;
            line-height: 1.5;
          }
          @media screen and (max-width: 550px) {
            margin: 0 0 20px 0;
            font-size: 20px;
          }
          .highlighter {
            //display: inline-block;
            font-weight: 400;
            margin: 5px 0 0 0;
            color: #ffffff;
            background: #3661ae;
            @media screen and (max-width: 1000px) {
              margin: 0;
            }
            .bold {
              font-weight: 500;
            }
          }
          &:after {
            content: "";
            display: block;
            margin: 40px 0;
            width: 30px;
            height: 1px;
            background: #000000;
            @media screen and (max-width: 1165px) {
              margin: 30px 0 35px;
            }
            @media screen and (max-width: 1000px) {
              margin: 23px 0 20px;
            }
            @media screen and (max-width: 550px) {
              display: none;
            }
          }
        }
        &:last-child {
          font-size: 19px;
          font-weight: 400;
          font-family: 'NanumSquareNeo';
          line-height: 1.5;
          color: #000000;
          letter-spacing: -0.03em;
          @media screen and (max-width: 550px) {
            font-size: 15px;
          }
        }
      }
    }
  }
}
// main_04
.main_04 {
  padding: 125px 0 135px 0;
  background: #f1f4f8;
  box-sizing: border-box;
  overflow: hidden;
  @media screen and (max-width: 700px) {
    padding: 70px 0 95px;
  }
  .w13000 {
    @media screen and (max-width: 1300px) {
      padding: 0 50px;
    }
    @media screen and (max-width: 800px) {
      padding: 0;
    }
    @media screen and (max-width: 480px) {
      width: auto;
      overflow-y: hidden;
    }
  }
  .w1200 {
    position: relative;
    box-sizing: border-box;
    @media screen and (max-width: 1300px) {
      width: auto;
      padding: 0 50px;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    .tit {
      margin: 0 0 60px 0;
      color: #2b3e67;
      transition: 0.5s;
      @media screen and (max-width: 700px) {
        margin: 0 0 45px 0;
      }
      & > p {
        margin: 0 0 20px 0;
        font-size: 24px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.015em;
        word-break: keep-all;
        @media screen and (max-width: 1300px) {
          margin: 0 0 15px 0;
          font-size: 18px;
        }
        @media screen and (max-width: 700px) {
          font-size: 16px;
          text-align: center;
          line-height: 1.5;
        }
      }
      h2 {
        font-size: 46px;
        font-weight: 600;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.015em;
        @media screen and (max-width: 1300px) {
          font-size: 38px;
        }
        @media screen and (max-width: 700px) {
          font-size: 34px;
          text-align: center;
          line-height: 1.5;
        }
        .boldblue {
          color: #3869c1;
        }
      }
    }
    .slideArrow {
      position: absolute;
      bottom: -30%;
      right: 15px;
      gap: 10px;
      justify-content: flex-end;
      margin: 0 0 35px 0;
      @media screen and (max-width: 1300px) {
        bottom: -49%;
        right: 0;
      }
      @media screen and (max-width: 800px) {
        right: 5%;
      }
      @media screen and (max-width: 750px) {
        display: none;
      }
      .arrow_left {
        position: relative;
        background: #ffffff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        cursor: pointer;
        @media screen and (max-width: 830px) {
          width: 45px;
          height: 45px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(../../public/images/icon/arrow_left.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 22px;
          height: 15px;
        }
        &:hover:before {
          background-image: url(../../public/images/icon/color_arrow_l.png);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .arrow_right {
        position: relative;
        background: #ffffff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        cursor: pointer;
        @media screen and (max-width: 830px) {
          width: 45px;
          height: 45px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(../../public/images/icon/arrow_right.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 22px;
          height: 15px;
        }
        &:hover:before {
          background-image: url(../../public/images/icon/color_arrow.png);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  .slide_box {
    margin: 0 auto;
    width: 1300px;
    overflow-x: hidden;
    overflow-y: hidden;
    @media screen and (max-width: 1300px) {
      width: auto;
    }
    @media screen and (max-width: 1200px) {
      padding: 0 0 0 50px;
      width: 1300px;
      overflow-x: hidden;
    }
    @media screen and (max-width: 800px) {
      padding: 0 40px;
      width: 1300px;
    }
    @media screen and (max-width: 650px) {
      padding: 0 80px;
      width: auto;
    }
    @media screen and (max-width: 550px) {
      padding: 0 30px;
    }
    .hover_desc {
      margin: 10px 0;
      padding: 60px 30px;
      width: calc(100% / 3.3333);
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 3px 3px 0px rgba(190, 200, 222, 0.2), 0px 1px 5px 0px rgba(190, 200, 222, 0.2);
      @media screen and (max-width: 1300px) {
        padding: 50px 35px 70px;
      }
      @media screen and (max-width: 1222px) {
        padding: 50px 35px 90px;
      }
      @media screen and (max-width: 1200px) {
        padding: 50px 25px 60px;
      }
      @media screen and (max-width: 600px) {
        padding: 50px 25px;
      }
      @media screen and (max-width: 555px) {
        padding: 45px 25px;
      }
      @media screen and (max-width: 416px) {
        padding: 45px 30px;
      }
      @media screen and (max-width: 380px) {
        padding: 50px 30px 80px;
      }
      @media screen and (max-width: 335px) {
        padding: 40px 30px 80px;
      }
      li {
        height: 310px;
        box-sizing: border-box;
        @media screen and (max-width: 1300px) {
          height: 350px;
        }
        @media screen and (max-width: 1230px) {
          height: 325px;
        }
        @media screen and (max-width: 1200px) {
          height: 300px;
        }
        @media screen and (max-width: 450px) {
          height: 350px;
        }
        @media screen and (max-width: 400px) {
          height: 360px;
        }
        span {
          display: inline-block;
          margin: 0 0 30px 0;
          font-size: 15px;
          font-weight: 600;
          font-family: 'Pretendard';
          text-transform: uppercase;
          color: #3869c1;
          border-bottom: 1px solid #3869c1;
          @media screen and (max-width: 1300px) {
            margin: 0 0 25px 0;
          }
          @media screen and (max-width: 1000px) {
            font-size: 16px;
            letter-spacing: -0.025em;
          }
        }
        h3 {
          margin: 0 0 35px 0;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.025em;
          line-height: 1.5;
          word-break: keep-all;
          white-space: pre-wrap;
          @media screen and (max-width: 1300px) {
            font-size: 21px;
          }
          @media screen and (max-width: 1230px) {
            font-size: 20px;
          }
          @media screen and (max-width: 450px) {
            height: 90px;
          }
        }
        p {
          font-size: 17px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          line-height: 1.8;
          word-break: keep-all;
          @media screen and (max-width: 400px) {
          font-size: 16px;
        }
        }
      }
    }
    .customDots {
      display: none !important;
      width: 800px;
      @media screen and (max-width: 800px) {
        display: flex !important;
        margin: 40px 25px 0;
        width: 650px;
      }
      @media screen and (max-width: 750px) {
        margin: 40px 10px 0;
        width: 650px;
      }
      @media screen and (max-width: 700px) {
        margin: 40px 30px 0;
        width: 550px;
      }
      @media screen and (max-width: 650px) {
        margin: 40px auto 0;
        width: auto;
      }
      & > li {
        width: 300px;
        height: 2px;
        background: #d6deea;
        transition: 0.3s;
        &.slick-active{
          background: #3869c1;
        }
        button {
          border: none;
          outline: none;
          background: transparent;
          font-size: 0;
        }
      }
    }
  }
  .slick-slide {
    padding: 0 15px;
    border-radius: 20px;
  }
}
// main_05
.main_05 {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  height: 360px;
  background-image: url(../../public/images/main05_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  @media screen and (max-width: 1300px) {
    width: auto;
  }
  @media screen and (max-width: 800px) {
    padding: 60px 0;
    background-image: url(../../public/images/main05_bg_800.png);
    height: 100%;
    background-size: cover;
  }
  .w1200 {
    @media screen and (max-width: 1300px) {
      padding: 0 50px;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    p {
      margin: 0 0 15px 0;
      font-size: 18px;
      font-family: "NanumSquareNeo";
      line-height: 1.5;
      letter-spacing: 0;
      color: #ffffff;
      @media screen and (max-width: 800px) {
        margin: 0;
      }
      @media screen and (max-width: 710px) {
        font-size: 16px;
      }
      @media screen and (max-width: 650px) {
        font-size: 14px;
      }
      @media screen and (max-width: 370px) {
        font-size: 13px;
      }
    }
    .contact {
      .flex {
        align-items: center;
        gap: 53px;
        @media screen and (max-width: 1300px) {
          gap: 40px;
        }
        @media screen and (max-width: 550px) {
          gap: 15px;
        }
        span {
          margin: 0;
          font-size: 90px;
          font-family: 'ErasITC';
          font-weight: 800;
          cursor: pointer;
          transition: all 0.3s;
          @media screen and (max-width: 1300px) {
            font-size: 70px;
          }
          @media screen and (max-width: 710px) {
            font-size: 60px;
          }
          @media screen and (max-width: 550px) {
            font-size: 45px;
          }
          @media screen and (max-width: 370px) {
            font-size: 37px;
          }
        }
        figure {
          transition: 0.3s;
          @media screen and (max-width: 1300px) {
            width: 90px;
            height: 61px;
          }
          @media screen and (max-width: 800px) {
            width: 75px;
            height: 50px;
          }
          @media screen and (max-width: 550px) {
            width: 50px;
            height: 35px;
          }
          @media screen and (max-width: 400px) {
            width: 40px;
            height: 30px;
          }
          @media screen and (max-width: 370px) {
            width: 35px;
            height: 25px;
          }
        }
      }
      &:hover {
        figure {
          margin-left: 25px;
        }
      }
    }
  }
}
// 고객지원, 뉴스 타이틀
.pageTitle {
  margin: 0 0 30px 0;
  font-size: 49px;
  font-weight: 600;
  font-family: 'NanumSquareNeo';
  letter-spacing: 0.01em;
  color: #333333;
  @media screen and (max-width: 800px) {
   font-size: 44px;
  }
  @media screen and (max-width: 600px) {
    margin: 0 0 20px 0;
    font-size: 34px;
  }
}
.pageTitle_news {
  margin: 0 0 30px 0;
  font-size: 49px;
  font-weight: 600;
  font-family: 'NanumSquareNeo';
  letter-spacing: -0.015em;
  color: #333333;
  @media screen and (max-width: 940px) {
    font-size: 44px;
  }
  @media screen and (max-width: 600px) {
    font-size: 34px;
    margin: 0 0 20px 0;
  }
}
// 고객지원
.customerSupport {
  padding: 240px 50px 200px;
  @media screen and (max-width: 1400px) {
    padding: 220px 50px 110px;
  }
  @media screen and (max-width: 800px) {
    padding: 160px 40px 110px;
  }
  @media screen and (max-width: 480px){
    padding: 135px 30px 80px;
  }
  .proposal {
    margin: 0 0 120px 0;
    font-size: 20px;
    font-weight: 400;
    font-family: 'NanumSquareNeo';
    letter-spacing: -0.025em;
    color: #898989;
    @media screen and (max-width: 800px) {
      margin: 0 0 60px 0;
      font-size: 18px;
    }
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  .csMenu {
    display: flex;
    gap: 50px;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #dddddd;
    @media screen and (max-width: 800px) {
      gap: 40px;
    }
    @media screen and (max-width: 445px) {
      gap: 25px;
    }
    @media screen and (max-width: 400px) {
      margin: 0 0 50px 0;
      padding: 0;
      border: none;
      gap: 30px;
    }
    @media screen and (max-width: 360px) {
      gap: 15px;
    }
    & > li {
      font-size: 22px;
      font-weight: 500;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      color: #b9b9be;
      cursor: pointer;
      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
      @media screen and (max-width: 400px) {
        font-size: 16px;
      }
      a {
        &.active {
          color: #000000;
          font-weight: 500;
          @media screen and (max-width: 400px) {
            padding: 0 0 5px 0;
            border-bottom: 1px solid #000000;
          }
        }
      }
    }
  }
  .faqMenu {
    justify-content: space-between;
    .csMenu {
      border-bottom: none;
    }
  }
}
//검색/select
.searchBox {
  gap: 30px;
  @media screen and (max-width: 940px){
    width: 100%;
    align-items: flex-end;
  }
  &.faqSearch {
    order: 1;
    @media screen and (max-width: 1190px) {
      gap: 10px;
    }
    @media screen and (max-width: 1100px) {
      margin: 0 0 70px 0;
      order: 0;
      width: 100%;
    }
    @media screen and (max-width: 480px) {
      margin: 0 0 60px 0;
    }
  }
  .dropBox {
    position: relative;
    width: 100px;
    .selected {
      .text {
        margin: 0;
        padding: 0 0 10px 3px;
        font-size: 18px;
        font-weight: 400;
        font-family: 'NanumSquareNeo';
        color: #a2a2a2;
        letter-spacing: 0.01em;
        border-bottom: 1px solid #444444;
        line-height: initial;
        cursor: pointer;
        @media screen and (max-width: 940px){
          padding: 0 0 10px 10px;
          font-size: 16px;
        }
        @media screen and (max-width: 480px){
          font-size: 14px;
        }
        &:after {
          content: "";
          display: inline-block;
          margin: 0 0 0 35px;
          background-image: url(../../public/images/icon/dropbox_arrow.png);
          background-repeat: no-repeat;
          background-position: right center;
          width: 20px;
          height: 11px;
          @media screen and (max-width: 940px){
            position: absolute;
            top: 40%;
            right: 3%;
            transform: translateY(-50%);
          }
          @media screen and (max-width: 550px){
            width: 17px;
            background-size: 100%;
          }
          @media screen and (max-width: 480px){
            top: 30%;
            width: 13px;
            height: 11px;
            background-size: 100%;
          }
        }
        &.active {
          &:after {
            background-image: url(../../public/images/icon/dropbox_arrow_up.png);
          }
        }
      }
    }
    &.faqDrop {
      @media screen and (max-width: 1300px){
        width: 90px;
      }
      @media screen and (max-width: 1150px){
        width: 80px;
      }
      @media screen and (max-width: 1100px){
        max-width: 15%;
        width: 100%;
      }
      @media screen and (max-width: 800px){
        max-width: 20%;
      }
      .faqSelected {
        .faqText{
          padding: 0 0 9px 10px;
          @media screen and (max-width: 1250px){
            padding: 0 0 11px 10px;
            font-size: 17px;
          }
          @media screen and (max-width: 1190px){
            padding: 0 0 11px 5px;
            font-size: 16px;
          }
          @media screen and (max-width: 480px){
            padding: 0 0 10px 5px;
            font-size: 14px;
          }
          &:after {
            @media screen and (max-width: 1300px){
              margin: 0 0 0 20px;
            }
            @media screen and (max-width: 1150px){
              margin: 0 0 0 15px;
            }
            @media screen and (max-width: 1100px){
              margin: 0 0 0 75px;
            }
            @media screen and (max-width: 970px){
              margin: 0 0 0 65px;
            }
            @media screen and (max-width: 960px){
              position: absolute;
              margin: 0;
              top: 35%;
              right: 3%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .selectDown {
        position: absolute;
        top: 29px;
        left: 0;
        display: none;
        border: 1px solid #cacaca;
        width: 100%;
        background: #ffffff;
        z-index: 10;
        & > li {
          padding: 0 0 0 10px;
          width: 100%;
          line-height: 40px;
          font-size: 16px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: 0.01em;
          color: #c9c9c9;
          cursor: pointer;
          &:hover {
            background: #f5f5f5;
            color: #999999;
          }
          @media screen and (max-width: 480px){
            padding: 0 0 0 5px;
            font-size: 14px;
            line-height: 30px;
          }
        }
        &.active {
          display: block;
        }
        @media screen and (max-width: 1300px){
          top: 30px;
        }
        @media screen and (max-width: 1250px){
          top: 28px;
        }
        @media screen and (max-width: 480px){
          top: 26px;
        }
      }
    }
    .dropDown {
      position: absolute;
      top: 29px;
      left: 0;
      display: none;
      border: 1px solid #cacaca;
      width: 100%;
      background: #ffffff;
      & > li {
        padding: 0 0 0 10px;
        width: 100%;
        line-height: 40px;
        font-size: 16px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: 0.01em;
        color: #c9c9c9;
        cursor: pointer;
        &:hover {
          background: #f5f5f5;
          color: #999999;
        }
        @media screen and (max-width: 480px){
          padding: 0 0 0 5px;
          font-size: 14px;
          line-height: 30px;
        }
      }
      &.active {
        display: block;
      }
    }
    @media screen and (max-width: 940px){
      width: 20%;
    }
  }
  form {
    &.faqForm {
      @media screen and (max-width: 1100px){
        width: 80%;
      }
    }
    .searchInput {
      position: relative;
      input {
        padding: 0 50px 10px 5px;
        width: 350px;
        border: none;
        outline: none;
        border-bottom: 1px solid #444444;
        &:focus::placeholder {
          color: transparent;
        }
        @media screen and (max-width: 480px){
          font-size: 14px;
        }
        &::placeholder {
          font-size: 18px;
          font-weight: 400;
          font-family: 'NanumSquareNeo';
          letter-spacing: -0.025em;
          color: #a2a2a2;
          @media screen and (max-width: 940px){
            font-size: 16px;
          }
          @media screen and (max-width: 480px){
            font-size: 14px;
          }
        }
        @media screen and (max-width: 940px){
          width: 100%;
        }
      }
      .searchBtn {
        position: absolute;
        bottom: 7%;
        right: 1%;
        outline: none;
        border: none;
        background: transparent;
        font-size: 25px;
        color: #5e5e5e;
        cursor: pointer;
        @media screen and (max-width: 480px){
          font-size: 20px;
        }
      }
      &.faqInput {
        @media screen and (max-width: 1100px){
          width: 100%;
        }
        input {
          width: 100%;
          @media screen and (max-width: 1300px){
            width: 300px;
          }
          @media screen and (max-width: 1250px){
            width: 250px;
          }
          @media screen and (max-width: 1150px){
            width: 230px;
          }
          @media screen and (max-width: 1100px){
            width: 100%;
          }
          @media screen and (max-width: 800px){
            width: 100%;
          }
          &::placeholder {
            @media screen and (max-width: 1190px){
              font-size: 16px;
            }
            @media screen and (max-width: 480px){
              font-size: 14px;
            }
          }
        }
        .searchBtn {
          font-size: 23px;
          @media screen and (max-width: 480px){
            font-size: 22px;
          }
          @media screen and (max-width: 400px){
            bottom: 9%;
            font-size: 21px;
          }
        }
      }
    }
    @media screen and (max-width: 940px){
      width: 75%;
    }
  }
}
// 고객지원 - 제품문의
.product {
  padding: 130px 0 0 0;
  @media screen and (max-width: 800px){
    padding: 100px 0 0 0;
  }
  @media screen and (max-width: 400px){
    padding: 35px 0 0 0;
  }
  h3 {
    margin: 0 0 45px 0;
    font-size: 26px;
    font-weight: 500;
    font-family: "NanumSquareNeo";
    color: #000000;
    @media screen and (max-width: 800px){
      margin: 0 0 26px 0;
      font-size: 22px;
    }
    @media screen and (max-width: 400px){
      margin: 0 0 30px 0;
      font-size: 18px;
    }
  }
  .selectForm {
    margin: 0 0 115px 0;
    @media screen and (max-width: 800px){
      margin: 0 0 80px 0;
    }
      .flex {
        padding: 35px 20px;
        justify-content: space-around;
        border: 1px solid #dbdbdb;
        @media screen and (max-width: 800px){
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 35px;
          gap: 30px 0;
        }
        @media screen and (max-width: 845px){
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 35px;
          gap: 30px 0;
        }
        @media screen and (max-width: 615px){
          padding: 30px 25px;
        }
        @media screen and (max-width: 550px){
          flex-direction: column;
        }
        .checkBox {
          position: relative;
          cursor: pointer;
          &:nth-child(3) {
            @media screen and (max-width: 800px) {
              margin-right: 0;
            }
          }
          &:nth-child(4) {
            @media screen and (max-width: 845px){
              margin-right: 105px;
            }
            @media screen and (max-width: 752px){
              margin-right: 75px;
            }
            @media screen and (max-width: 615px){
              margin-right: 55px;
            }
            @media screen and (max-width: 550px){
              margin-right: 0;
            }
          }
          @media screen and (max-width: 845px){
            margin-right: 80px;
          }
          @media screen and (max-width: 752px){
            margin-right: 50px;
          }
          @media screen and (max-width: 615px){
            margin-right: 30px;
          }
          @media screen and (max-width: 550px){
            margin-right: 0;
          }
          .radioBtn {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            width: 0;
            height: 0;
            cursor: pointer;
          }
          .checkmark {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #ffffff;
            border: 2px solid #dbdbdb;
          }
          input:checked ~ .checkmark {
            background: #4265ae;
            border: 2px solid #4265ae;
          }
          input:checked ~ .checkmark:after {
            content: '';
            position: absolute;
            top: 40%;
            left: 50%;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            cursor: pointer;
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
            -ms-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
          }
          .rTitle {
            margin: 0 0 0 35px;
            font-size: 20px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            color: #000000;
            @media screen and (max-width: 800px){
              margin: 0 0 0 30px;
            }
            @media screen and (max-width: 400px){
              font-size: 18px;
            }
          }
        }
      }
  }
  .inputForm {
    padding: 0 0 120px 0;
    @media screen and (max-width: 800px){
      padding: 0 0 80px 0;
    }
      .inquiryInput {
        gap: 30px;
        margin: 0 0 25px 0;
        @media screen and (max-width: 1000px){
          flex-direction: column;
        }
        @media screen and (max-width: 800px){
          gap: 20px;
          margin: 0 0 20px 0;
        }
        label {
          padding: 20px 30px;
          border: 1px solid #dbdbdb;
          width: 580px;
          background: #ffffff;
          @media screen and (max-width: 1214px){
            padding: 20px;
          }
          @media screen and (max-width: 1000px){
            width: auto;
          }
          @media screen and (max-width: 545px){
            padding: 15px 20px;
          }
          span {
            position: relative;
            display: inline-block;
            width: 110px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'NanumSquareNeo';
            color: #4265ae;
            @media screen and (max-width: 1174px) {
              max-width: 90px;
            }
            @media screen and (max-width: 650px) {
              max-width: 90px;
              width: 100%;
              font-size: 16px;
            }
            @media screen and (max-width: 410px) {
              max-width: 65px;
              font-size: 14px;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 2px;
              height: 24px;
              background: #cfd4d9;
              @media screen and (max-width: 410px) {
                height: 15px;
              }
            }
          }
          input {
            padding: 0 0 0 30px;
            outline: none;
            border: none;
            width: 370px;
            background-color: #ffffff !important;
            @media screen and (max-width: 1154px) {
              width: 300px;
            }
            @media screen and (max-width: 990px) {
              width: 370px;
            }
            @media screen and (max-width: 625px) {
              width: 300px;
            }
            @media screen and (max-width: 575px) {
              padding: 0 0 0 20px;
              width: 250px;
            }
            @media screen and (max-width: 445px){
              width: 215px;
            }
            @media screen and (max-width: 410px) {
              padding: 0 0 0 15px;
            }
            @media screen and (max-width: 385px) {
              width: 160px;
            }
            &::placeholder {
              font-size: 18px;
              font-weight: 400;
              font-family: 'NanumSquareNeo';
              letter-spacing: -0.015em;
              color: #b9b9be;
              @media screen and (max-width: 650px) {
                font-size: 16px;
              }
              @media screen and (max-width: 410px) {
                font-size: 14px;
              }
            }
            &:focus::placeholder {
              color: transparent;
            }
            &:focus {
              background-color: #ffffff !important;
            }
          }
        }
      }
      .address {
        @media screen and (max-width: 1095px){
          width: 100%;
        }
        .streetAdd {
          margin: 0 0 15px 0;
          gap: 10px;
          align-items: center;
          width: 580px;
          justify-content: space-between;
          @media screen and (max-width: 1095px){
            align-items: center;
            width: auto;
          }
          @media screen and (max-width: 545px){
            flex-direction: column;
            align-items: flex-start;
          }
          label {
            padding: 20px 30px;
            border: 1px solid #dbdbdb;
            width: 480px;
            margin: 0;
            @media screen and (max-width: 1174px) {
              padding: 20px;
            }
            @media screen and (max-width: 1095px){
              width: calc(100% - 100px);
            }
            @media screen and (max-width: 545px){
              padding: 15px 20px;
              width: 100%;
            }
            input {
              outline: none;
              border: none;
              padding: 0 0 0 30px;
              width: 285px;
              background: #ffffff;
              @media screen and (max-width: 625px) {
                width: 230px;
              }
              @media screen and (max-width: 575px) {
                padding: 0 0 0 20px;
                width: 200px;
              }
              @media screen and (max-width: 410px) {
                padding: 0 0 0 15px;
              }
              @media screen and (max-width: 385px) {
                width: 150px;
              }
              &::placeholder {
                font-size: 18px;
                font-weight: 400;
                font-family: 'NanumSquareNeo';
                letter-spacing: -0.015em;
                color: #b9b9be;
                @media screen and (max-width: 650px) {
                  font-size: 16px;
                }
                @media screen and (max-width: 410px) {
                  font-size: 14px;
                }
              }
              &:focus::placeholder {
                color: transparent;
              }
            }
            span {
              position: relative;
              display: inline-block;
              width: 110px;
              font-size: 18px;
              font-weight: 500;
              font-family: 'NanumSquareNeo';
              color: #4265ae;
              @media screen and (max-width: 1174px) {
                max-width: 90px;
              }
              @media screen and (max-width: 1095px) {
                width: 100%;
                /*max-width: 100px;*/
              }
              @media screen and (max-width: 650px) {
                max-width: 90px;
                width: 100%;
                font-size: 16px;
              }
              @media screen and (max-width: 410px) {
                max-width: 65px;
                font-size: 14px;
              }
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 2px;
                height: 24px;
                background: #cfd4d9;
                @media screen and (max-width: 650px) {
                  height: 20px;
                }
                @media screen and (max-width: 410px) {
                  height: 15px;
                }
              }
            }
          }
          .findBtn {
            @media screen and (max-width: 545px){
              display: block;
              width: 100%;
            }
            button {
              display: inline-block;
              padding: 22px 15px;
              outline: none;
              border: none;
              background: #4265ae;
              color: #ffffff;
              font-size: 15px;
              font-weight: 300;
              font-family: 'NanumSquareNeo';
              letter-spacing: -0.035em;
              cursor: pointer;
              margin-left: 2px;
              @media screen and (max-width: 545px){
                padding: 13px 0;
                width: 100%;
              }
            }
          }
        }
        .detailAdd {
          display: inline-block;
          padding: 20px 30px;
          border: 1px solid #dbdbdb;
          width: 580px;
          @media screen and (max-width: 1095px){
            width: 100%;
          }
          @media screen and (max-width: 545px){
            padding: 15px 20px;
          }
          input {
            outline: none;
            border: none;
            padding: 0;
            width: 450px;
            background: #ffffff;
            @media screen and (max-width: 565px){
              width: 300px;
            }
            @media screen and (max-width: 385px) {
              width: 200px;
            }
            &::placeholder {
              font-size: 18px;
              font-weight: 400;
              font-family: 'NanumSquareNeo';
              letter-spacing: -0.015em;
              color: #b9b9be;
              @media screen and (max-width: 650px) {
                font-size: 16px;
              }
              @media screen and (max-width: 410px) {
                font-size: 14px;
              }
            }
            &:focus::placeholder {
              color: transparent;
            }
          }
        }
      }
  }
  .textArea {
    padding: 0 0 115px 0;
    @media screen and (max-width: 800px) {
      padding: 0 0 80px 0;
    }
    textarea {
      padding: 20px;
      width: 100%;
      height: 350px;
      border: none;
      outline: none;
      resize: none;
      border: 1px solid #dddddd;
      @media screen and (max-width: 400px) {
        padding: 15px 20px;
      }
      &::placeholder {
        padding: 10px 0 0 15px;
        font-size: 18px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        color: #b9b9be;
        letter-spacing: -0.015em;
        word-break: keep-all;
        @media screen and (max-width: 650px) {
          font-size: 16px;
          line-height: 1.5;
        }
        @media screen and (max-width: 400px) {
          padding: 0 0 0 5px;
          font-size: 14px;
          line-height: 1.5;
        }
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    .apply {
      padding: 15px 65px;
      outline: none;
      border: none;
      font-size: 19px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      background: #4265ae;
      color: #ffffff;
      cursor: pointer;
      @media screen and (max-width: 600px){
        font-size: 16px;
      }
      @media screen and (max-width: 400px){
        padding: 15px 55px;
      }
    }
  }
}
// 고객지원 - 제휴문의
.partner {
  padding: 130px 0 0;
  @media screen and (max-width: 800px){
    padding: 100px 0 0 0;
  }
  @media screen and (max-width: 400px){
    padding: 30px 0 0 0;
  }
  h2 {
    margin: 0 0 50px 0;
    font-size: 36px;
    font-weight: 500;
    font-family: 'NanumSquareNeo';
    color: #000000;
    text-align: center;
    word-break: keep-all;
    .blue {
      color: #4265ae;
    }
    @media screen and (max-width: 800px){
      margin: 0 0 26px 0;
      font-size: 28px;
    }
    @media screen and (max-width: 670px){
      line-height: 1.4;
    }
    @media screen and (max-width: 400px){
      margin: 0 0 20px 0;
      font-size: 22px;
    }
  }
  h3 {
    margin: 0 0 45px 0;
    font-size: 26px;
    font-weight: 500;
    font-family: "NanumSquareNeo";
    color: #000000;
    word-break: keep-all;
    @media screen and (max-width: 800px){
      margin: 0 0 40px 0;
      font-size: 22px;
    }
    @media screen and (max-width: 400px){
      margin: 0 0 30px 0;
      font-size: 18px;
    }
  }
  .benefitsBox {
    display: flex;
    gap: 20px;
    @media screen and (max-width: 1030px){
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 600px){
      display: flex;
      flex-direction: column;
    }
    li {
      padding: 55px 30px 55px 25px;
      width: 290px;
      box-shadow: 0px 0px 10px rgba(83, 83, 83, 0.09);
      word-break: keep-all;
      background: #ffffff;
      @media screen and (max-width: 1030px){
        padding: 45px 50px;
        width: auto;
      }
      @media screen and (max-width: 800px){
        padding: 45px 30px
      }
      @media screen and (max-width: 400px){
        padding: 45px 30px 45px 25px;
      }
      .case {
        display: inline-block;
        margin: 0 0 45px 0;
        font-size: 18px;
        font-weight: 500;
        font-family: "Pretendard";
        color: #3869c1;
        border-bottom: 2px solid #3869c1;
        letter-spacing: -0.025em;
        @media screen and (max-width: 1030px){
          margin: 0 0 15px 0;
        }
        @media screen and (max-width: 800px){
          margin: 0 0 10px 0;
          font-size: 16px;
        }
      }
      .titleTop {
        display: block;
        margin: 0 0 30px 0;
        font-size: 22px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        color: #272727;
        white-space: pre-wrap;
        word-break: keep-all;
        @media screen and (max-width: 1200px) {
          white-space: normal;
        }
        @media screen and (max-width: 1030px) {
          margin: 0 0 25px 0;
        }
        @media screen and (max-width: 800px){
          margin: 0 0 20px 0;
          font-size: 20px;
        }
      }
      .benefitsDetail {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        white-space: pre-wrap;
        word-break: keep-all;
        letter-spacing: -0.015em;
        line-height: 1.7;
        color: #565656;
        @media screen and (max-width: 1030px) {
          white-space: normal;
        }
      }
    }
  }
  .inputForm {
    padding: 130px 0 125px 0;
    @media screen and (max-width: 400px) {
      padding: 80px 0;
    }
      .flex {
        gap: 30px;
        margin: 0 0 25px 0;
        @media screen and (max-width: 1000px){
          flex-direction: column;
        }
        @media screen and (max-width: 800px){
          gap: 20px;
          margin: 0 0 20px 0;
        }
        label {
          padding: 20px 30px;
          border: 1px solid #dbdbdb;
          width: 580px;
          background: #ffffff;
          @media screen and (max-width: 1174px) {
            padding: 20px;
          }
          @media screen and (max-width: 1000px){
            width: auto;
          }
          @media screen and (max-width: 545px){
            padding: 15px 20px;
          }
          span {
            position: relative;
            display: inline-block;
            width: 110px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'NanumSquareNeo';
            color: #4265ae;
            @media screen and (max-width: 1215px) {
              width: 90px;
            }
            @media screen and (max-width: 800px) {
              max-width: 100px;
            }
            @media screen and (max-width: 650px) {
              max-width: 90px;
              width: 100%;
              font-size: 16px;
            }
            @media screen and (max-width: 410px) {
              max-width: 65px;
              font-size: 14px;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 2px;
              height: 24px;
              background: #cfd4d9;
              @media screen and (max-width: 410px) {
                height: 15px;
              }
            }
          }
          input {
            padding: 0 0 0 30px;
            outline: none;
            border: none;
            width: 370px;
            background-color: #ffffff !important;
            @media screen and (max-width: 1215px) {
              width: 300px;
            }
            @media screen and (max-width: 625px) {
              width: 300px;
            }
            @media screen and (max-width: 575px) {
              padding: 0 0 0 20px;
              width: 250px;
            }
            @media screen and (max-width: 445px){
              width: 215px;
            }
            @media screen and (max-width: 410px) {
              padding: 0 0 0 15px;
            }
            @media screen and (max-width: 385px) {
              width: 160px;
            }
            &::placeholder {
              font-size: 18px;
              font-weight: 400;
              font-family: 'NanumSquareNeo';
              letter-spacing: -0.015em;
              color: #b9b9be;
              @media screen and (max-width: 650px) {
                font-size: 16px;
              }
              @media screen and (max-width: 410px) {
                font-size: 14px;
              }
            }
            &:focus::placeholder {
              color: transparent;
            }
            &:focus {
              background-color: #ffffff !important;
            }
          }
        }
      }
      .textArea {
        textarea {
          padding: 20px;
          width: 100%;
          height: 250px;
          border: none;
          outline: none;
          resize: none;
          border: 1px solid #dddddd;
          @media screen and (max-width: 400px) {
            padding: 15px 20px;
          }
          &::placeholder {
            padding: 10px 0 0 15px;
            font-size: 18px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            color: #b9b9be;
            letter-spacing: -0.015em;
            @media screen and (max-width: 650px) {
              padding: 0;
              font-size: 16px;
              line-height: 1.5;
            }
            @media screen and (max-width: 400px) {
              padding: 0;
              font-size: 14px;
              line-height: 1.5;
            }
          }
          &:focus::placeholder {
            color: transparent;
          }
        }
      }
  }
  .btn {
    display: flex;
    justify-content: center;
    .apply {
      padding: 15px 65px;
      outline: none;
      border: none;
      font-size: 19px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      background: #4265ae;
      color: #ffffff;
      cursor: pointer;
      @media screen and (max-width: 600px){
        font-size: 16px;
      }
      @media screen and (max-width: 400px){
        padding: 15px 55px;
      }
    }
  }
}
// 고객지원 - FAQ
.faq {
  padding: 110px 0 0;
  @media screen and (max-width: 1400px) {
   padding: 100px 0 0;
  }
  @media screen and (max-width: 400px) {
   padding: 0;
  }
  .container {
    @media screen and (max-width: 1300px) {
      width: auto;
    }
  }
  .menuFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 50px 0;
    @media screen and (max-width: 1300px) {
      margin: 0 0 45px 0;
    }
    @media screen and (max-width: 1100px) {
      display: block;
      margin: 0 0 40px 0;
      justify-content: normal;
    }
    @media screen and (max-width: 400px) {
      margin: 0 0 35px 0;
    }
    .menuBtn {
      display: flex;
      gap: 20px;
      @media screen and (max-width: 1250px) {
       gap: 15px;
      }
      @media screen and (max-width: 1190px) {
        gap: 10px;
      }
      @media screen and (max-width: 1100px) {
        justify-content: start;
        gap: 20px;
      }
      @media screen and (max-width: 825px) {
        justify-content: space-between;
        gap: 0;
      }
      @media screen and (max-width: 640px) {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        max-width: 420px;
      }
      //@media screen and (max-width: 640px) {
      //  flex-wrap: wrap;
      //  justify-content: start;
      //  align-items: center;
      //  margin: 0 auto;
      //  max-width: 330px;
      //}
      & > li {
        &:nth-child(4) {
          @media screen and (max-width: 640px) {
            margin-right: 0;
          }
          @media screen and (max-width: 496px) {
            margin-right: 15px;
          }
        }
        @media screen and (max-width: 640px) {
         margin-right: 15px;
          margin-bottom: 15px;
        }
        button {
          padding: 15px 25px;
          outline: none;
          border: none;
          font-size: 19px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          background: #f5f5f5;
          color: #484848;
          cursor: pointer;
          @media screen and (max-width: 1450px) {
            padding: 15px 20px;
            font-size: 17px;
          }
          @media screen and (max-width: 720px) {
            padding: 15px;
            font-size: 16px;
          }
        }
        &.active {
          button {
            background: #4265ae;
            color: #ffffff;
          }
        }
      }
    }
  }
  .faqList {
    padding: 0 0 90px 0;
    @media screen and (max-width: 800px) {
      padding: 0 0 80px 0;
    }
    li {
      border-top: 1px solid #dddddd;
      cursor: pointer;
      &:last-child {
        border-bottom: 1px solid #dddddd;
      }
      .w555 {
        display: none;
        @media screen and (max-width: 555px) {
          display: block;
          margin: 0 0 2px 65px;
          padding: 15px 0 0;
          font-size: 14px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          color: #4265ae;
        }
        @media screen and (max-width: 390px) {
          margin: 0 0 0 63px;
        }
        @media screen and (max-width: 390px) {
          margin: 0 0 0 65px;
        }
      }
      .faqcontent {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 35px;
        @media screen and (max-width: 800px) {
          padding: 20px;
        }
        @media screen and (max-width: 555px) {
          padding: 0 20px 20px;
        }
        &.active {
          .drop-down {
            background-image: url(../../public/images/icon/faq_arrow_up.png);
          }
        }
        & > div {
          display: flex;
          align-items: center;
          gap: 0;
          .divide {
            position: relative;
            display: inline-block;
            margin: 0 20px 0 0;
            width: 36px;
            height: 36px;
            line-height: 36px;
            font-size: 18px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            text-align: center;
            color: #ffffff;
            background: #4265ae;
            border-radius: 50%;
            @media screen and (max-width: 950px) {
              margin: 0 15px 0 0;
              font-size: 17px;
            }
            @media screen and (max-width: 800px) {
              font-size: 16px;
              width: 33px;
              height: 33px;
              line-height: 33px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
              width: 30px;
              height: 30px;
              line-height: 30px;
            }
            @media screen and (max-width: 555px) {
              margin: 0 15px 0 0;
            }
          }
          .divide + p {
            position: relative;
            margin: 0 35px 0 0;
            width: 180px;
            font-size: 18px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            color: #4265ae;
            @media screen and (max-width: 1300px) {
              width: 175px;
            }
            @media screen and (max-width: 1080px) {
              width: 150px;
            }
            @media screen and (max-width: 950px) {
              width: 130px;
              font-size: 17px;
            }
            @media screen and (max-width: 800px) {
              width: 100px;
              font-size: 16px;
            }
            @media screen and (max-width: 650px) {
              margin: 0 15px 0 0;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
            @media screen and (max-width: 555px) {
              display: none;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 2px;
              height: 25px;
              background: #cfd4d9;
              //@media screen and (max-width: 950px) {
              //  display: none;
              //}
              @media screen and (max-width: 800px) {
                display: none;
              }
            }
          }
          .question {
            margin: 0;
            font-size: 18px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.015em;
            color: #333333;
            @media screen and (max-width: 1060px) {
              max-width: calc(90% - 200px);
              font-size: 17px;
            }
            @media screen and (max-width: 950px) {
              font-size: 17px;
            }
            @media screen and (max-width: 800px) {
              font-size: 16px;
            }
            @media screen and (max-width: 667px) {
              max-width: 250px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
            @media screen and (max-width: 555px) {
              max-width: 250px;
              font-size: 14px;
            }
            @media screen and (max-width: 410px) {
              max-width: calc(100% - 70px);
            }
          }
        }
        .drop-down {
          display: block;
          background-image: url(../../public/images/icon/faq_arrow.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 10px;
          @media screen and (max-width: 800px) {
            width: 15px;
            background-size: 100%;
          }
        }
      }
      .answer {
        display: flex;
        padding: 30px 35px 40px;
        background: #f6f6f6;
        border-top: 1px solid #dddddd;
        @media screen and (max-width: 800px) {
          padding: 20px 60px 35px 20px;
        }
        @media screen and (max-width: 650px) {
          padding: 20px 60px 30px 20px;
        }
        @media screen and (max-width: 555px) {
          padding: 20px 30px 20px 20px;
        }
        @media screen and (max-width: 350px) {
          padding: 20px 10px 20px 20px;
        }
        p {
          &:nth-child(1) {
            margin: 0 20px 0 0;
            width: 36px;
            height: 37px;
            line-height: 36px;
            font-size: 18px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            text-align: center;
            color: #ffffff;
            background: #4265ae;
            border-radius: 50%;
            @media screen and (max-width: 950px) {
              margin: 0 15px 0 0;
              font-size: 17px;
            }
            @media screen and (max-width: 800px) {
              font-size: 16px;
              width: 33px;
              height: 33px;
              line-height: 33px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
              width: 30px;
              height: 30px;
              line-height: 30px;
            }
          }
          &:nth-child(2) {
            width: 95%;
            font-size: 18px;
            font-weight: 300;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.015em;
            line-height: 1.8;
            word-break: keep-all;
            color: #000000;
            @media screen and (max-width: 1300px) {
              font-size: 17px;
            }
            @media screen and (max-width: 950px) {
              font-size: 16px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
              max-width: calc(100% - 50px);
            }
            @media screen and (max-width: 555px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .noList {
    padding: 100px 0 0;
    font-size: 20px;
    font-weight: 500;
    font-family: 'NanumSquareNeo';
    text-align: center;
  }
}
// 페이지네이션
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  & li {
    button {
      padding: 0;
      outline: none;
      border: none;
      background: none;
      font-size: 18px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      color: #b9b9be;
      letter-spacing: 0.01em;
      cursor: pointer;
      &.firstPage {
        margin: 0 20px 0 0;
        @media screen and (max-width: 600px){
          margin: 0 15px 0 0;
        }
      }
      &.prevPage {
        margin: 0 20px 0 0;
        @media screen and (max-width: 600px){
          margin: 0 15px 0 0;
        }
      }
      &.pageNum {
        margin: 0 10px;
        &.active {
          color: #000000;
          font-weight: 500;
        }
      }
      &.nextPage {
        margin: 0 0 0 20px;
      }
      &.lastPage {
        margin: 0 0 0 20px;
      }
    }
  }
}
// 뉴스
.newspages {
  padding: 245px 0 200px;
  &.detail {
    padding: 245px 0 165px;
    @media screen and (max-width: 1450px){
      padding: 215px 0 100px;
    }
    @media screen and (max-width: 940px){
      padding: 150px 0 80px;
    }
    @media screen and (max-width: 600px){
      padding: 140px 0 20px;
    }
  }
  @media screen and (max-width: 1450px){
    padding: 200px 0 120px;
  }
  @media screen and (max-width: 940px){
    padding: 160px 0 110px;
  }
  @media screen and (max-width: 600px){
    padding: 135px 0 40px;
  }
  .container {
    @media screen and (max-width: 1450px){
      padding: 0px 50px;
    }
    @media screen and (max-width: 940px){
      padding: 0px 40px;
    }
    @media screen and (max-width: 600px){
      padding: 0px 30px;
    }
    .proposal {
      margin: 0 0 120px 0;
      font-size: 20px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      color: #898989;
      @media screen and (max-width: 940px){
        margin: 0 0 60px 0;
        font-size: 18px;
      }
      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
    .newsMenu {
      display: flex;
      gap: 48px;
      margin: 0 0 40px 0;
      @media screen and (max-width: 940px){
        gap: 45px;
        margin: 0px;
      }
      @media screen and (max-width: 600px){
        gap: 40px;
        margin: 0px;
      }
      &.mb75 {
        margin: 0 0 75px 0;
      }
      & > li {
        font-size: 22px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        color: #b9b9be;
        &.active {
          color: #000000;
          font-weight: 600;
        }
        a.active {
          color: #000000;
          font-weight: 600;
          @media screen and (max-width: 600px){
            box-sizing: border-box;
            padding-bottom: 5px;
            border-bottom: 2px solid #000;
          }
        }
        @media screen and (max-width: 940px){
          font-size: 20px;
        }
        @media screen and (max-width: 600px){
          font-size: 16px;
        }
      }
    }
    .nFlex {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 940px){
        flex-direction: column;
        gap: 90px;
      }
      @media screen and (max-width: 600px){
        gap: 40px;
      }
      .searchBox {
        gap: 30px;
        @media screen and (max-width: 940px){
          width: 100%;
          align-items: flex-end;
        }
        .dropBox {
          position: relative;
          width: 100px;
          .selected {
            p {
              margin: 0;
              padding: 0 0 15px 3px;
              font-size: 18px;
              font-weight: 400;
              font-family: 'NanumSquareNeo';
              color: #a2a2a2;
              letter-spacing: 0.01em;
              border-bottom: 1px solid #444444;
              line-height: initial;
              cursor: pointer;
              @media screen and (max-width: 940px){
                font-size: 16px;
              }
              @media screen and (max-width: 600px){
                font-size: 14px;
              }
              &:after {
                content: "";
                display: inline-block;
                background-image: url(../../public/images/icon/dropbox_arrow.png);
                background-repeat: no-repeat;
                background-position: right center;
                width: 20px;
                height: 11px;
                position: absolute;
                right: 3%;
                top: 20%;
                transform: translateY(-50%);
                @media screen and (max-width: 940px){
                  top: 40%;
                }
                @media screen and (max-width: 600px){
                  top: 30%;
                  width: 15px;
                  height: 11px;
                  background-size: 100%;
                }
              }
              &.active {
                &:after {
                  background-image: url(../../public/images/icon/dropbox_arrow_up.png);
                }
              }
            }
          }
          .dropDown {
            position: absolute;
            top: 29px;
            left: 0;
            display: none;
            border: 1px solid #cacaca;
            width: 100%;
            background: #ffffff;
            & > li {
              padding: 0 0 0 10px;
              width: 100%;
              line-height: 40px;
              font-size: 16px;
              font-weight: 400;
              font-family: "NanumSquareNeo";
              letter-spacing: 0.01em;
              color: #c9c9c9;
              cursor: pointer;
              &:hover {
                background: #f5f5f5;
                color: #999999;
              }
              @media screen and (max-width: 600px){
                padding: 0 0 0 5px;
                font-size: 14px;
                line-height: 30px;
              }
            }
            &.active {
              display: block;
            }
          }
          @media screen and (max-width: 940px){
            width: 20%;
          }
        }
        form {
          .searchInput {
            position: relative;
            input {
              padding: 0 50px 15px 5px;
              width: 350px;
              border: none;
              outline: none;
              border-bottom: 1px solid #444444;
              &:focus::placeholder {
                color: transparent;
              }
              &::placeholder {
                font-size: 18px;
                font-weight: 400;
                font-family: 'NanumSquareNeo';
                letter-spacing: -0.025em;
                color: #a2a2a2;
                @media screen and (max-width: 940px){
                  font-size: 16px;
                }
                @media screen and (max-width: 600px){
                  font-size: 14px;
                }
              }
              @media screen and (max-width: 940px){
                width: 100%;
              }
            }
            .searchBtn {
              position: absolute;
              bottom: 20%;
              right: 1%;
              outline: none;
              border: none;
              background: transparent;
              font-size: 25px;
              color: #5e5e5e;
              cursor: pointer;
              padding: 0px;
              @media screen and (max-width: 600px){
                font-size: 20px;
              }
            }
          }
          @media screen and (max-width: 940px){
            width: 75%;
          }
        }
      }
    }
    .itemBox {
      padding: 0 0 95px 0;
      @media screen and (max-width: 940px){
        padding: 0 0 70px 0;
      }
      @media screen and (max-width: 600px){
        padding: 0 0 50px 0;
      }
      .newsItem {
        a {
          padding: 30px 0;
          justify-content: space-between;
          border-bottom: 1px solid #dddddd;
          gap: 50px;
          @media screen and (max-width: 940px){
            padding: 35px 0;
            flex-direction: column;
            gap: 0px;
          }
        }
        .image {
          box-sizing: border-box;
          width: 420px;
          height: 265px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          @media screen and (max-width: 1080px){
            width: 45%;
          }
          @media screen and (max-width: 940px){
            width: 100%;
            height: 280px;
          }
          @media screen and (max-width: 600px){
            height: 195px;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 900px;
          color: #282828;
          h3 {
            margin: 0 0 25px 0;
            font-size: 24px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            word-break: keep-all;
            line-height: 1.4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media screen and (max-width: 1450px){
              font-size: 22px;
            }
            @media screen and (max-width: 940px){
              white-space: normal;
              margin: 30px 0 15px 0;
            }
            @media screen and (max-width: 600px){
              font-size: 19px;
            }
          }
          .newsContent {
            margin: 0 0 20px 0;
            font-size: 17px;
            font-weight: 400;
            font-family: "Pretendard";
            line-height: 1.5;
            color: #282828;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            word-break: keep-all;
            @media screen and (max-width: 600px){
              margin: 0 0 25px 0;
              font-size: 14px;
            }
          }
          .newsDate {
            font-size: 15px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: 0.01em;
            color: #999999;
          }
          @media screen and (max-width: 1450px){
            width: 60%;
          }
          @media screen and (max-width: 1250px){
            width: 50%;
          }
          @media screen and (max-width: 940px){
            width: 100%;
          }
        }
        @media screen and (max-width: 940px){
          flex-direction: column;
          gap: 65px;
        }
      }
    }
    .noList {
      font-size: 20px;
      font-weight: 500;
      font-family: 'NanumSquareNeo';
      padding: 100px 0 0;
      text-align: center;
    }
    .letterBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 35px 0 50px 0;
      & > li {
        margin: 0 0 70px 0;
        width: 31.5%;
        @media screen and (max-width: 1450px){
          width: 31%;
        }
        @media screen and (max-width: 940px){
          width: 100%;
        }
        @media screen and (max-width: 600px){
          margin: 0 0 40px 0;
        }
        figure {
          margin: 0 0 25px 0;
          width: 100%;
          overflow: hidden;
          height: 485px;
          @media screen and (max-width: 1450px){
            height: 415px;
          }
          @media screen and (max-width: 940px){
            height: 800px;
            margin: 0 0 35px 0;
          }
          @media screen and (max-width: 600px){
            height: 395px;
            margin: 0 0 25px 0;
          }
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center bottom;
          }
        }
        p {
          font-size: 24px;
          font-weight: 600;
          font-family: "Pretendard";
          text-align: center;
          color: #282828;
          letter-spacing: 0.01em;
          @media screen and (max-width: 1450px){
            font-size: 22px;
          }
          @media screen and (max-width: 600px){
            font-size: 18px;
          }
        }
      }
    }
    .datailPage {
      padding: 15px 0 80px 0;
      @media screen and (max-width: 1450px){
        padding: 10px 0px 85px 0px;
      }
      @media screen and (max-width: 940px){
        padding: 0px 0px 40px 0px;
      }
      @media screen and (max-width: 600px){
        padding: 0px 0px 35px 0px;
      }
      .title {
        display: flex;
        -webkit-box-pack: justify;
        margin: 0 0 85px 0;
        padding: 0 0 35px 0;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid #dddddd;
        color: #333333;
        @media screen and (max-width: 940px){
          margin: 0 0 60px 0;
          flex-direction: column;
          align-items: flex-start;
          padding: 0 0 10px 0;
        }
        @media screen and (max-width: 600px){
          margin: 0 0 35px 0;
        }
        h2 {
          width: calc(100% - 110px);
          font-size: 38px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          overflow: hidden;
          word-break: keep-all;
          line-height: 1.5;
          @media screen and (max-width: 1200px){
            font-size: 30px;
          }
          @media screen and (max-width: 940px){
            width: 100%;
            margin-bottom: 25px;
          }
          @media screen and (max-width: 600px){
            font-size: 20px;
            margin-bottom: 20px;
          }
        }
        span {
          font-size: 15px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          color: #999999;
          line-height: 1.9;
        }
      }
      .contentItem {
        border-bottom: 1px solid #dddddd;
        figure {
          margin: 0 0 80px 0;
        }
        .content {
          margin: 0 0 70px 0;
          font-size: 17px;
          font-weight: 400;
          font-family: "Pretendard";
          line-height: 1.5;
          color: #282828;
          letter-spacing: 0.02em;
          word-break: keep-all;
          overflow: hidden;
          word-wrap: break-word;
          @media screen and (max-width: 940px){
            font-size: 16px;
            margin: 0 0 55px 0;
          }
          @media screen and (max-width: 600px){
            font-size: 15px;
            margin: 0 0 45px 0;
          }
          & > p {
            font-size: 17px;
            font-weight: 400;
            font-family: "Pretendard";
            color: #282828;
            line-height: 1.5;
            letter-spacing: 0.02em;
            @media screen and (max-width: 940px){
              //margin: 0 0 45px 0;
              font-size: 16px;
            }
            @media screen and (max-width: 600px){
              font-size: 15px;
            }
          }
          * {
            font-family: "Pretendard" !important;
          }
        }
        .link {
          font-size: 17px;
          font-weight: 400;
          font-family: "Pretendard";
          margin-bottom: 125px;
          @media screen and (max-width: 940px){
            font-size: 16px;
            margin-bottom: 70px;
          }
          @media screen and (max-width: 600px){
            font-size: 15px;
            margin-bottom: 45px;
          }
          a {
            margin: 0 0 0 10px;
            letter-spacing: 0.02em;
            color: #282828;
            vertical-align: center;
            line-height: 1.5;
            @media screen and (max-width: 940px){
              font-size: 16px;
            }
            @media screen and (max-width: 600px){
              margin: 10px 0 0 0;
              font-size: 15px;
              display: block;
              overflow: hidden;
              word-break: keep-all;
              word-wrap: break-word;
            }
          }
        }
      }
    }
    .datailPage_letter {
      margin: 10px 0 75px 0;
      padding: 0px 0 95px 0;
      border-bottom: 1px solid #b9b9b9;
      @media screen and (max-width: 1450px){
        padding: 0px 0 85px 0;
      }
      @media screen and (max-width: 940px){
        margin: 0 0 50px 0;
        padding: 0px 0 50px 0;
      }
      @media screen and (max-width: 600px){
        margin: 0 0 40px 0;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 0 30px 0;
        margin-bottom: 75px;
        border-bottom: 1px solid #dddddd;
        @media screen and (max-width: 940px){
          padding: 0 0 25px 0;
          margin-bottom: 40px;
        }
        @media screen and (max-width: 600px){
          padding: 0 0 15px 0;
          margin-bottom: 30px;
        }
        h2 {
          font-size: 26px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.025em;
          color: #333333;
          @media screen and (max-width: 600px){
            font-size: 20px;
          }
        }
        .date {
          font-size: 15px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          color: #999999;
        }
      }
      .content{
        text-align: center;
      }
      figure {
        padding: 80px 0 100px 0;
        text-align: center;
      }
    }
  }
}
//상세페이지 이전글/다음글/목록
.direction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftArrow {
    visibility: visible;
    &.visible_n {
      visibility: hidden;
    }
    a {
      display: flex;
      align-items: center;
    }
    span {
      &:first-child {
        display: inline-block;
        margin: 0 15px 0 0;
        padding: 15px 17px;
        font-size: 22px;
        color: #999999;
        border: 1px solid #dddddd;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        transition: 0.3s;
        svg {
          padding: 0
        }
        &:hover {
          color: #6f6f6f;
          background: #f1f1f1;
          border: 1px solid #f1f1f1;
        }
        @media screen and (max-width: 600px){
          margin: 0 10px 0 0;
          padding: 10px 12px;
        }
        @media screen and (max-width: 380px){
          padding: 8px 10px;
          margin: 0 5px 0 0;
        }
      }
      &:last-child {
        font-size: 17px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        color: #6e6e6e;
        @media screen and (max-width: 940px){
          font-size: 16px;
        }
        @media screen and (max-width: 600px){
          font-size: 13px;
        }
      }
    }
  }
  .leftArrowNone {
    visibility: hidden;
  }
  .centerList {
    a {
      display: flex;
      gap: 20px;
      padding: 12px 45px;
      align-items: center;
      border: 1px solid #dddddd;
      border-radius: 150px;
      transition: 0.3s;
      @media screen and (max-width: 600px){
        padding: 10px 20px;
      }
      @media screen and (max-width: 380px){
        gap: 15px;
      }
      @media screen and (max-width: 350px){
        padding: 10px 15px;
      }
      &:hover {
        background: #436aa5;
        border: 1px solid #436aa5;
        p {
          color: #ffffff;
        }
        .listIcon {
          span {
            &:nth-child(1) {
              background: #ffffff;
            }
            &:nth-child(2) {
              background: #ffffff;
            }
            &:nth-child(3) {
              background: #ffffff;
            }
          }
        }
      }
      p {
        font-size: 19px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        color: #000000;
        @media screen and (max-width: 940px){
          font-size: 18px;
        }
        @media screen and (max-width: 600px){
          font-size: 13px;
        }
      }
      .listIcon {
        position: relative;
        width: 16px;
        height: 12px;
        @media screen and (max-width: 380px){
          width: 13px;
        }
        span {
          position: absolute;
          &:nth-child(1) {
            top: 0;
            left: 0;
            right: 0;
            width: 16px;
            height: 1px;
            background: #585858;
            @media screen and (max-width: 380px){
              width: 13px;
            }
          }
          &:nth-child(2) {
            top: 45%;
            left: 25%;
            //left: 5px;
            width: 16px;
            height: 1px;
            background: #585858;
            @media screen and (max-width: 380px){
              width: 13px;
            }
          }
          &:nth-child(3) {
            bottom: 0;
            left: 0;
            right: 0;
            width: 16px;
            height: 1px;
            background: #585858;
            @media screen and (max-width: 380px){
              width: 13px;
            }
          }
        }
      }
    }
  }
  .rightArrow {
    visibility: visible;
    &.visible_n {
      visibility: hidden;
    }
    &.rightArrowNone {
      visibility: hidden;
    }
    a {
      display: flex;
      align-items: center;
    }
    span {
      &:first-child {
        font-size: 17px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        color: #6e6e6e;
        @media screen and (max-width: 940px){
          font-size: 16px;
        }
        @media screen and (max-width: 600px){
          font-size: 13px;
        }
      }
      &:last-child {
        display: inline-block;
        margin: 0 0 0 15px;
        padding: 15px 17px;
        font-size: 22px;
        color: #999999;
        border: 1px solid #dddddd;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        transition: 0.3s;
        &:hover {
          color: #6f6f6f;
          background: #f1f1f1;
          border: 1px solid #f1f1f1;
        }
        @media screen and (max-width: 600px){
          padding: 10px 12px;
          margin: 0 0 0 10px;
        }
        @media screen and (max-width: 380px){
          margin: 0 0 0 5px;
          padding: 8px 10px;
        }
      }
    }
  }
}
//다운로드
.download {
  padding: 130px 0 0;
  @media screen and (max-width: 800px){
    padding: 100px 0 0 0;
  }
  @media screen and (max-width: 400px){
    padding: 30px 0 0 0;
  }
  .container {
    @media screen and (max-width: 1455px) {
      width: auto;
    }
    .flex {
      @media screen and (max-width: 1150px) {
        flex-direction: column;
        gap: 80px;
      }
      .egP {
        max-width: 50%;
        width: 100%;
        @media screen and (max-width: 1150px) {
          max-width: 100%;
        }
        &:first-child {
          margin: 0 20px 0 0;
          @media screen and (max-width: 1150px) {
            max-width: 100%;
          }
        }
      }
      .egL {
        max-width: 50%;
        width: 100%;
        @media screen and (max-width: 1150px) {
          max-width: 100%;
        }
      }
      h3 {
        font-size: 26px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        color: #000000;
        text-align: left;
        @media screen and (max-width: 400px) {
          font-size: 24px;
        }
        &:after {
          content: "";
          display: block;
          margin: 30px 0 25px;
          width: 30px;
          height: 2px;
          background: #b9b9be;
          @media screen and (max-width: 400px) {
            margin: 25px 0 20px;
          }
        }
      }
      .downloadBox {
        display: flex;
        @media screen and (max-width: 595px) {
          flex-direction: column;
          gap: 35px;
        }
        .downloadItem {
          padding: 45px 35px;
          border: 2px solid #ececec;
          background: #ffffff;
          cursor: pointer;
          width: 100%;
          transition: 0.3s;
          @media screen and (max-width: 800px) {
            padding: 50px 35px;
          }
          &:first-child {
            margin: 0 20px 0 0;
          }
          p {
            font-size: 20px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            color: #000000;
            @media screen and (max-width: 800px) {
              font-size: 22px;
            }
            &:after {
              content: "";
              display: block;
              margin: 25px 0 40px;
              width: 100%;
              height: 1px;
              background: #bfbfbf;
            }
          }
          button {
            outline: none;
            border: none;
            background: none;
            padding: 13px 43px;
            border: 1px solid #000000;
            cursor: pointer;
            span {
              position: relative;
              font-size: 16px;
              font-weight: 400;
              font-family: "NanumSquareNeo";
              letter-spacing: -0.015em;
              &:after {
                content: "";
                display: inline-block;
                margin: 0 0 0 8px;
                background-image: url(../../public/images/icon/download_icon.png);
                background-repeat: no-repeat;
                background-position: center top;
                width: 16px;
                height: 17px;
                vertical-align: text-bottom;
              }
            }
          }
          &:hover {
            &.egProposal {
              background: #4265ae;
              border: 2px solid #4265ae;
            }
            &.egBrochure {
              background: #4265ae;
              border: 2px solid #4265ae;
            }
            p {
              color: #ffffff;
              &:after {
                height: 1px;
                background: #ffffff;
              }
            }
            button {
              border: 1px solid #ffffff;
              span {
                color: #ffffff;
                &:after {
                  background-image: url(../../public/images/icon/download_icon_hover.png);
                }
              }
            }
          }
        }
      }
    }
  }
}
//모달
.modalWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  .modalBody {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 70px 35px 80px;
    background: #ffffff;
    width: 940px;
    @media screen and (max-width: 1300px) {
      top: 54.5%;
      padding: 65px 35px 75px;
    }
    @media screen and (max-width: 1020px) {
      padding: 65px 25px 75px;
      max-width: 650px;
      width: 100%;
    }
    @media screen and (max-width: 735px) {
      padding: 65px 25px 75px;
      max-width: 550px;
    }
    @media screen and (max-width: 610px) {
      padding: 60px 15px 65px;
      max-width: 400px;
      width: 100%;
    }
    @media screen and (max-width: 580px) {
      padding: 60px 15px;
      max-width: 400px;
    }
    @media screen and (max-width: 460px) {
      padding: 60px 15px;
      max-width: 320px;
    }
    @media screen and (max-width: 380px) {
      padding: 60px 15px;
    }
    &.appBox {
      padding: 55px 0 60px;
      width: 530px;
      @media screen and (max-width: 580px) {
        width: 320px;
        padding: 20px 0 30px;
      }
      @media screen and (max-width: 370px) {
        width: 260px;
        padding: 20px 0 30px;
      }
      &:before {
        content: "";
        display: block;
        margin: 0 auto 40px;
        background-image: url(../../public/images/icon/modal_comfirm_icon.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 60px;
        height: 60px;
        @media screen and (max-width: 580px) {
          width: 40px;
          height: 40px;
          background-size: 100%;
          margin: 20px auto 20px;
        }
      }
    }
    &.stBox {
      padding: 55px 0 60px;
      width: 530px;
      @media screen and (max-width: 580px) {
        width: 320px;
        padding: 20px 0 30px;
      }
      @media screen and (max-width: 370px) {
        width: 260px;
        padding: 20px 0 30px;
      }
      &:before {
        content: "";
        display: block;
        margin: 0 auto 40px;
        background-image: url(../../public/images/icon/modal_comfirm_icon02.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 60px;
        height: 60px;
        @media screen and (max-width: 580px) {
          width: 40px;
          height: 40px;
          background-size: 100%;
          margin: 20px auto 20px;
        }
      }
    }
    .title {
      @media screen and (max-width: 1020px) {
        padding: 0 30px;
        text-align: center;
      }
      @media screen and (max-width: 610px) {
        padding: 0;
      }
      &:after {
        content: "";
        display: block;
        margin: 30px auto;
        width: 710px;
        height: 1px;
        background: #dddddd;
        @media screen and (max-width: 1020px) {
          margin: 20px auto 25px;
          width: 100%;
        }
      }
      h2 {
        margin: 0 0 20px 0;
        font-size: 36px;
        font-weight: 600;
        font-family: "NanumSquareNeo";
        color: #333333;
        text-align: center;
        .blue {
          color: #4265ae;
        }
        @media screen and (max-width: 1020px) {
          margin: 0 0 15px 0;
          font-size: 28px;
        }
        @media screen and (max-width: 735px) {
          font-size: 26px;
        }
        @media screen and (max-width: 580px) {
          margin: 0 0 10px 0;
          font-size: 22px;
        }
        @media screen and (max-width: 380px) {
          font-size: 20px;
        }
      }
      p {
        font-size: 15px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        color: #898989;
        text-align: center;
        @media screen and (max-width: 1020px) {
          font-size: 14px;
        }
        @media screen and (max-width: 580px) {
          font-size: 13px;
        }
      }
    }
    .scroll {
      height: 450px;
      overflow-y: scroll;
      word-break: keep-all;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 6px;
        background: transparent;
        height: 150px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
      }
    }
    .modTitle{
      margin: 0 0 20px 0;
      h2 {
        font-size: 28px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        color: #010101;
        text-align: center;
        @media screen and (max-width: 580px) {
          font-size: 22px;
        }
        @media screen and (max-width: 370px) {
          font-size: 18px;
        }
      }
    }
    .salutation{
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0 0 40px 0;
        font-size: 16px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        text-align: center;
        color: #767676;
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
        @media screen and (max-width: 370px) {
          font-size: 12px;
        }
      }
      button {
        display: inline-block;
        padding: 13px 60px;
        font-size: 16px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        color: #ffffff;
        outline: none;
        border: none;
        background: #4584d2;
        cursor: pointer;
        &.stBtn {
          background: #00a1c7;
        }
        @media screen and (max-width: 580px) {
          padding: 10px 30px;
          font-size: 15px;
        }
        @media screen and (max-width: 370px) {
          padding: 10px 30px;
          font-size: 13px;
        }
      }
    }
    .closeBtn {
      &.positionAdj {
        position: fixed;
        top: 7%;
        right: 5%;
        button {
          outline: none;
          border: none;
          background: none;
          font-size: 21px;
          color: #b9b9be;
          cursor: pointer;
          background: none;
        }
      }
    }
    .w620 {
      @media screen and (max-width: 1020px) {
        padding: 0 70px;
        max-width: 720px;
        width: 100%;
      }
      @media screen and (max-width: 610px) {
        padding: 0 15px 0 0 ;
        width: auto;
      }
      .inputBox {
        margin: 0 0 45px 0;
        @media screen and (max-width: 1020px) {
          margin: 0 0 40px 0;
        }
        @media screen and (max-width: 580px) {
          margin: 0 0 30px 0;
        }
        label {
          display: block;
          margin: 0 0 15px 0;
          padding: 15px 30px;
          border: 1px solid #dddddd;
          @media screen and (max-width: 580px) {
            padding: 15px;
          }
          @media screen and (max-width: 460px) {
            padding: 13px 15px;
          }
          .inputTit {
            position: relative;
            display: inline-block;
            width: 105px;
            font-size: 16px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            color: #262626;
            @media screen and (max-width: 1020px) {
              width: 85px;
            }
            @media screen and (max-width: 735px) {
              max-width: 80px;
              font-size: 15px;
            }
            @media screen and (max-width: 610px) {
              max-width: 70px;
            }
            @media screen and (max-width: 580px) {
              font-size: 14px;
            }
            @media screen and (max-width: 460px) {
              max-width: 65px;
            }
            @media screen and (max-width: 380px) {
              max-width: 57px;
              font-size: 13px;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 2px;
              height: 22px;
              background: #cfd4d9;
              @media screen and (max-width: 580px) {
                height: 15px;
              }
              @media screen and (max-width: 380px) {
                height: 13px;
              }
            }
          }
          input {
            outline: none;
            border: none;
            padding: 0 0 0 30px;
            width: 370px;
            @media screen and (max-width: 1020px) {
              padding: 0 0 0 20px;
              width: 300px;
            }
            @media screen and (max-width: 735px) {
              max-width: 200px;
              width: 100%;
            }
            @media screen and (max-width: 610px) {
              max-width: 180px;
            }
            @media screen and (max-width: 580px) {
              padding: 0 0 0 15px;
              max-width: 220px;
            }
            @media screen and (max-width: 460px) {
              padding: 0 0 0 10px;
              max-width: 150px;
            }
            @media screen and (max-width: 380px) {
              max-width: 100px;
            }
            &::placeholder {
              font-size: 16px;
              font-weight: 400;
              font-family: 'NanumSquareNeo';
              letter-spacing: -0.020em;
              color: #b9b9be;
              @media screen and (max-width: 735px) {
                font-size: 15px;
              }
              @media screen and (max-width: 610px) {
                font-size: 14px;
              }
              @media screen and (max-width: 580px) {
                font-size: 13px;
              }
              @media screen and (max-width: 380px) {
                font-size: 12px;
              }
            }
            &:focus::placeholder {
              color: transparent;
            }
          }
        }
      }
      .personalInfo {
        padding: 0 0 45px 0;
        @media screen and (max-width: 1020px) {
          padding: 0;
        }
        h3 {
          margin: 0 0 30px 0;
          font-size: 20px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          text-align: center;
          color: #000000;
          @media screen and (max-width: 1020px) {
            margin: 0 0 20px 0;
            font-size: 18px;
          }
          @media screen and (max-width: 580px) {
            margin: 0 0 15px 0;
            font-size: 18px;
          }
          @media screen and (max-width: 380px) {
            margin: 0 0 10px 0;
            font-size: 17px;
            line-height: 1.4;
          }
        }
        .notice {
          padding: 0 10px;
          line-height: 1.6;
          word-break: keep-all;
          @media screen and (max-width: 1020px) {
            font-size: 14px;
          }
          @media screen and (max-width: 580px) {
            font-size: 13px;
            text-align: center;
          }
        }
        .useGuide {
          padding: 30px 20px;
          @media screen and (max-width: 1020px) {
            margin-bottom: 15px;
          }
          @media screen and (max-width: 580px) {
            padding: 15px 15px 20px;
          }
        }
        .content {
          height: 220px;
          @media screen and (max-width: 580px) {
            height: 240px;
          }
          & > p {
            font-size: 15px;
            @media screen and (max-width: 1020px) {
              font-size: 14px;
            }
            @media screen and (max-width: 580px) {
              font-size: 13px;
            }
          }
          .terms_text {
            margin: 0 0 30px 0;
            @media screen and (max-width: 1020px) {
              margin: 0 0 20px 0;
            }
          }
        }
        .btn_chk {
          @media screen and (max-width: 1020px) {
            margin: 0 0 30px 0;
          }
          .checkmark {
            @media screen and (max-width: 380px) {
              width: 15px;
              height: 15px;
            }
          }
          .checkmark {
            @media screen and (max-width: 380px) {
              top: 60%;
            }
          }
          .agree {
            margin: 0 0 0 25px;
            @media screen and (max-width: 1020px) {
              font-size: 14px;
            }
            @media screen and (max-width: 380px) {
              display: block;
              font-size: 13px;
              line-height: 1.2;
              width: 120px;
            }
          }
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        .apply {
          padding: 13px 65px;
          outline: none;
          border: none;
          font-size: 19px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          background: #4265ae;
          color: #ffffff;
          cursor: pointer;
          @media screen and (max-width: 1050px) {
            font-size: 16px;
          }
          @media screen and (max-width: 580px) {
            display: block;
            width: 100%;
            padding: 15px 0;
            font-size: 15px;
          }
        }
      }
    }
    .closeBtn {
      position: fixed;
      top: 4%;
      right: 3%;
      @media screen and (max-width: 580px) {
        top: 3%;
      }
      @media screen and (max-width: 460px) {
        top: 2.5%;
      }
      button {
        outline: none;
        border: none;
        background: none;
        font-size: 21px;
        color: #b9b9be;
        cursor: pointer;
        @media screen and (max-width: 580px) {
          font-size: 19px;
        }
        @media screen and (max-width: 460px) {
          font-size: 17px;
        }
        @media screen and (max-width: 380px) {
          font-size: 15px;
        }
      }
    }
  }
}
//eg-platform\
.egPlatform {
  padding: 200px 0 110px;
  width: 100vw;
  @media screen and (max-width: 900px) {
    padding: 180px 0 100px;
    width: auto;
  }
  @media screen and (max-width: 650px) {
    padding: 145px 0 75px;
    width: auto;
  }
  .w1200 {
    text-align: center;
    @media screen and (max-width: 1800px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    h2 {
      margin: 0 0 40px 0;
      font-size: 40px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      color: #111111;
      word-break: keep-all;
      letter-spacing: -0.04em;
      line-height: 1.5;
      @media screen and (max-width: 900px) {
        margin: 0 0 30px 0;
        font-size: 32px;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 25px 0;
        font-size: 27px;
        line-height: 1.4;
      }
      @media screen and (max-width: 500px) {
        font-size: 25px;
      }
      .bold {
        font-weight: 500;
      }
      .blue {
        color: #336fda;
        font-weight: 600;
        letter-spacing: -0.03em;
      }
      .w400 {
        display: none;
        @media screen and (max-width: 400px) {
          display: inline;
        }
      }
      br{
        @media screen and (max-width: 380px) {
          display: none;
        }
      }
    }
    .suggest {
      p {
        word-break: keep-all;
        line-height: initial;
        color: #686868;
        font-size: 20px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        line-height: 1.6;
        word-break: keep-all;
        @media screen and (max-width: 900px) {
          font-size: 17px;
        }
        @media screen and (max-width: 650px) {
          font-size: 16px;
        }
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
        .bold {
          font-weight: 500;
          color: #272727;
        }
      }
    }
    &.active_mv {
      p, h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      .suggest {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
  section {
    &.active {
      animation: aniTop 0.5s linear;
      animation-fill-mode: both;
    }
    opacity: 0;
    transform: translateY(500px);
  }
}
// 이지플랫폼 > 보안이슈
.securityIssue {
  position: relative;
  padding: 180px 0 120px;
  height: 920px;
  background: #376fc2;
  @media screen and (max-width: 1300px) {
    height: initial;
  }
  @media screen and (max-width: 900px) {
    padding: 150px 0 100px;
  }
  @media screen and (max-width: 650px) {
    padding: 140px 0 80px;
    width: auto;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background: #ffffff;
  line-height: 70px;
  @media screen and (max-width: 1200px) {
    width: auto;
  }
    @media screen and (max-width: 600px) {
      line-height: 60px;
    }
    @media screen and (max-width: 500px) {
      line-height: 45px;
    }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    border-top: none;
    line-height: 80px;
  }
  .tabMenu {
    display: flex;
    justify-content: space-between;
    &.w800 {
      @media screen and (max-width: 900px) {
        width: 650px;
      }
      @media screen and (max-width: 750px) {
        padding: 0 40px;
        width: auto;
      }
    }
    li {
      position: relative;
      border-bottom: 2px solid transparent;
      &.w450 {
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
      &.w400 {
        display: none;
        @media screen and (max-width: 540px) {
          display: block;
        }
      }
      &.active {
        //border-bottom: 3px solid #111111;
        button {
          position: relative;
          font-weight: 500;
          color: #111111;
          &:after {
            content: "";
            position: absolute;
            top: 70%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 2px;
            background: #111111;
            //border-bottom: 3px solid #111111;
          }
        }
      }
      &.active2 {
        border-bottom: 3px solid #00a1c7;
        button {
          font-weight: 500;
          color: #00a1c7;
        }
      }
      button {
        padding: 25px 0;
        outline: none;
        border: none;
        background: none;
        font-size: 18px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.02em;
        color: #959595;
        cursor: pointer;
        @media screen and (max-width: 900px) {
          font-size: 17px;
        }
        @media screen and (max-width: 600px) {
          font-size: 15px;
        }
        @media screen and (max-width: 530px) {
          font-size: 14px;
        }
        &.tBtn {
          color: #111111;
          border-bottom: 2px solid #111111;
        }
      }
    }
  }
}
  .w1200 {
    @media screen and (max-width: 1800px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 650px) {
      padding: 0 30px;
    }
    p {
      margin: 0 0 15px 0;
      font-size: 20px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      text-align: center;
      letter-spacing: -0.035em;
      line-height: initial;
      color: #ffffff;
      word-break: keep-all;
      @media screen and (max-width: 1300px) {
        line-height: 1.3;
      }
      @media screen and (max-width: 920px) {
        font-size: 18px;
      }
      @media screen and (max-width: 650px) {
        font-size: 17px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
    h2 {
      margin: 0 0 60px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      text-align: center;
      letter-spacing: -0.035em;
      color: #ffffff;
      word-break: keep-all;
      @media screen and (max-width: 920px) {
        margin: 0 0 50px 0;
        font-size: 32px;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 30px 0;
        font-size: 30px;
        line-height: 1.4;
      }
      @media screen and (max-width: 500px) {
        font-size: 26px;
      }
      .w575 {
        display: none;
        @media screen and (max-width: 575px) {
          display: block;
        }
      }
    }
    .flex {
      padding: 0 70px;
      gap: 20px;
      justify-content: center;
      @media screen and (max-width: 1165px) {
        padding: 0 50px;
      }
      @media screen and (max-width: 1125px) {
        padding: 0;
      }
      @media screen and (max-width: 970px) {
        padding: 0 70px;
        flex-direction: column;
        align-items: center;
      }
      @media screen and (max-width: 650px) {
        padding: 0;
      }
      & > div {
        padding: 70px 50px;
        width: 520px;
        background: #ffffff;
        color: #111111;
        border-radius: 10px;
        @media screen and (max-width: 950px) {
          padding: 60px 60px;
        }
        @media screen and (max-width: 640px) {
          padding: 60px 50px 50px;
          width: 100%;
        }
        @media screen and (max-width: 600px) {
          padding: 60px 40px 50px;
          width: 100%;
        }
        h3 {
          position: relative;
          margin: 0 0 35px 0;
          padding: 0 0 35px 0;
          font-size: 30px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          text-align: left;
          border-bottom: 1px solid #b8c4d9;
          word-break: keep-all;
          @media screen and (max-width: 920px) {
            margin: 0 0 25px 0;
            padding: 0 0 25px 0;
            font-size: 28px;
          }
          @media screen and (max-width: 650px) {
            margin: 0 0 20px 0;
            padding: 0 0 15px 0;
            font-size: 26px;
          }
          @media screen and (max-width: 500px) {
            font-size: 22px;
          }
        }
        &:first-child {
          h3 {
            &:after {
              content: "";
              position: absolute;
              bottom: 40%;
              right: 0;
              background-image: url(../../public/images/icon/sub_eglpatform_icon_01.png);
              background-repeat: no-repeat;
              background-position: center center;
              width: 45px;
              height: 60px;
              @media screen and (max-width: 650px) {
                bottom: 20%;
                width: 35px;
                height: 60px;
                background-size: 100%;
              }
            }
          }
        }
        &:last-child {
          h3 {
            &:after {
              content: "";
              position: absolute;
              bottom: 40%;
              right: 0;
              background-image: url(../../public/images/icon/sub_eglpatform_icon_02.png);
              background-repeat: no-repeat;
              background-position: center;
              width: 45px;
              height: 60px;
              @media screen and (max-width: 650px) {
                bottom: 20%;
                width: 35px;
                height: 60px;
                background-size: 100%;
              }
            }
          }
        }
        & > ul {
          li {
            position: relative;
            &:not(:last-of-type) {
              margin: 0 0 30px 0;
              @media screen and (max-width: 1300px) {
                margin: 0 0 25px 0;
              }
              @media screen and (max-width: 920px) {
                margin: 0 0 20px 0;
              }
            }
            & > p {
              margin: 0 0 0 30px;
              font-size: 22px;
              font-weight: 400;
              font-family: "NanumSquareNeo";
              letter-spacing: -0.04em;
              word-break: keep-all;
              color: #111111;
              text-align: left;
              word-break: keep-all;
              @media screen and (max-width: 920px) {
                font-size: 20px;
              }
              @media screen and (max-width: 650px) {
                font-size: 18px;
              }
              @media screen and (max-width: 500px) {
                font-size: 16px;
              }
              @media screen and (max-width: 450px) {
                line-height: 1.4;
              }
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-image: url(../../public/images/icon/check.png);
                background-repeat: no-repeat;
                background-position: center bottom;
                width: 21px;
                height: 21px;
              }
            }
          }
        }
      }
    }
  }
}
//이지플랫폼>기능체계
.functionalSystem {
  padding: 120px 0 0;
  //height: 653px;
  @media screen and (max-width: 1300px) {
    height: initial;
  }
  @media screen and (max-width: 900px) {
    padding: 95px 0 0;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0 0;
  }
  .container {
    text-align: center;
    @media screen and (max-width: 1800px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    h2 {
      margin: 0 0 27px 0;
      font-size: 36px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        margin: 0 0 25px 0;
        font-size: 30px;
        line-height: 1.5;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 20px 0;
        font-size: 26px;
        line-height: 1.3;
      }
      @media screen and (max-width: 500px) {
        font-size: 24px;
      }
      .w800{
        display: none;
        @media screen and (max-width: 900px) {
          display: block;
        }
      }
      .blue {
        font-weight: 600;
        color: #286be4;
        @media screen and (max-width: 900px) {
          font-size: 36px;
        }
        @media screen and (max-width: 650px) {
          font-size: 33px;
        }
        @media screen and (max-width: 500px) {
          font-size: 30px;
        }
      }
    }
    p {
      //margin: 0 0 60px 0;
      font-size: 18px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #696969;
      line-height: 1.6;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        margin: 0 0 45px 0;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 35px 0;
        font-size: 17px;
      }
      @media screen and (max-width: 500px) {
        margin: 0 0 30px 0;
        font-size: 16px;
      }
      .bold {
        font-weight: 500;
        color: #2a2a2a;
      }
    }
    figure {
      border: 1px solid #c3d3f0;
    }
  }
}
//이지플랫폼>특장점01
.features {
  //padding: 150px 0 110px;
  padding: 110px 0;
  background-image: url(../../public/images/sub_egplatform_bg01.png);
  background-repeat: no-repeat;
  background-position: bottom;
  height: 820px;
  @media screen and (max-width: 1300px) {
    height: initial;
  }
  @media screen and (max-width: 900px) {
    padding: 100px 0;
    background-size: 100% 400px;
  }
  @media screen and (max-width: 400px) {
    background-size: 100% 300px;
  }
  .w1200 {
    @media screen and (max-width: 1400px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      text-align: center;
      color: #111111;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        font-size: 32px;
      }
      @media screen and (max-width: 650px) {
        font-size: 28px;
      }
      @media screen and (max-width: 500px) {
        font-size: 26px;
      }
      .blue {
        font-weight: 600;
        color: #286be4;
      }
    }
    p {
      margin: 0 0 50px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #000000;
      line-height: initial;
      word-break: keep-all;
      @media screen and (max-width: 650px) {
        margin: 0 0 35px 0;
        font-size: 17px;
      }
      @media screen and (max-width: 580px) {
        margin: 0 0 30px 0;
        line-height: 1.5;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
      .w1070{
        @media screen and (max-width: 1070px) {
          display: none;
        }
      }
    }
    .flex {
      gap: 30px;
      //justify-content: space-between;
      @media screen and (max-width: 1300px) {
        gap: 25px;
      }
      @media screen and (max-width: 960px) {
        padding: 0 60px;
        flex-direction: column;
        gap: 20px;
      }
      @media screen and (max-width: 650px) {
        padding: 0;
      }
      & > li {
        flex: 1;
        position: relative;
        padding: 60px 60px 55px;
        width: 385px;
        border: 1px solid #2c78ff;
        background: #ffffff;
        @media screen and (max-width: 1300px) {
          width: auto;
          padding: 60px 50px 55px;
        }
        @media screen and (max-width: 1200px) {
          padding: 55px 40px 55px;
        }
        @media screen and (max-width: 960px) {
          padding: 0 70px;
          display: flex;
        }
        @media screen and (max-width: 800px) {
          padding: 20px 40px 20px 30px;
        }
        @media screen and (max-width: 650px) {
          padding: 20px 30px 20px 20px;
        }
        &:nth-child(1) {
          &:before {
            content: "";
            display: block;
            margin: 0 auto 35px;
            background-image: url(../../public/images/icon/sub_egplatform_icon01.png);
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 100%;
            width: 93px;
            height: 85px;
            @media screen and (max-width: 1070px) {
              margin: 0 auto 30px;
            }
            @media screen and (max-width: 960px) {
              position: absolute;
              top: 50%;
              left: 10%;
              transform: translateY(-50%);
            }
            @media screen and (max-width: 800px) {
              left: 9.5%;
              background-size:  100%;
              width: 70px;
              height: 75px;
            }
            @media screen and (max-width: 600px) {
              left: 7%;
              background-size: 100%;
              width: 55px;
              height: 65px;
            }
            @media screen and (max-width: 515px) {
              left: 6%;
              background-size: 100%;
              width: 50px;
              height: 65px;
            }
            @media screen and (max-width: 415px) {
              background-size: 100%;
              width: 45px;
              height: 55px;
            }
          }
        }
        &:nth-child(2) {
          &:before {
            content: "";
            display: block;
            margin: 0 auto 35px;
            background-image: url(../../public/images/icon/sub_egplatform_icon02.png);
            background-repeat: no-repeat;
            background-position: center top;
            background-size:  100%;
            width: 80px;
            height: 85px;
            @media screen and (max-width: 1070px) {
              margin: 0 auto 30px;
            }
            @media screen and (max-width: 960px) {
              position: absolute;
              top: 50%;
              left: 10%;
              transform: translateY(-50%);
            }
            @media screen and (max-width: 800px) {
              left: 10%;
              background-size: 100%;
              width: 60px;
              height: 75px;
            }
            @media screen and (max-width: 600px) {
              left: 7%;
              background-size: 100%;
              width: 55px;
              height: 65px;
            }
            @media screen and (max-width: 515px) {
              left: 6%;
              background-size: 100%;
              width: 50px;
              height: 65px;
            }
            @media screen and (max-width: 415px) {
              background-size: 100%;
              width: 45px;
              height: 55px;
            }
          }
        }
        &:nth-child(3) {
          &:before {
            content: "";
            display: block;
            margin: 0 auto 35px;
            background-image: url(../../public/images/icon/sub_egplatform_icon03.png);
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 100%;
            width: 77px;
            height: 85px;
            @media screen and (max-width: 1070px) {
              margin: 0 auto 30px;
            }
            @media screen and (max-width: 960px) {
              position: absolute;
              top: 50%;
              left: 10%;
              transform: translateY(-50%);
            }
            @media screen and (max-width: 800px) {
              left: 10%;
              background-size: 100%;
              width: 60px;
              height: 75px;
            }
            @media screen and (max-width: 600px) {
              left: 7%;
              background-size: 100%;
              width: 55px;
              height: 65px;
            }
            @media screen and (max-width: 515px) {
              left: 6%;
              background-size: 100%;
              width: 50px;
              height: 65px;
            }
            @media screen and (max-width: 415px) {
              background-size: 100%;
              width: 45px;
              height: 55px;
            }
          }
        }
        & >div {
          @media screen and (max-width: 960px) {
            display: flex;
            margin: 0 0 0 150px;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 180px;
          }
          @media screen and (max-width: 800px) {
            height: 130px;
          }
          @media screen and (max-width: 650px) {
            width: auto;
            height: auto;
          }
          @media screen and (max-width: 600px) {
            margin: 0 0 0 100px;
          }
          @media screen and (max-width: 515px) {
            margin: 0 0 0 80px;
          }
          @media screen and (max-width: 415px) {
            margin: 0 0 0 65px;
          }
          h3 {
            margin: 0 0 30px 0;
            font-size: 24px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            line-height: 1.5;
            text-align: center;
            color: #111111;
            word-break: keep-all;
            @media screen and (max-width: 1070px) {
              margin: 0 0 20px 0;
              font-size: 23px;
            }
            @media screen and (max-width: 960px) {
              margin: 0 0 15px 0;
              font-size: 22px;
              text-align: left;
              line-height: 1.45;
            }
            @media screen and (max-width: 650px) {
             font-size: 20px;
            }
            @media screen and (max-width: 500px) {
              font-size: 18px;
            }
            .w1160 {
              display: none;
              @media screen and (max-width: 1154px) {
                display: block;
              }
              @media screen and (max-width: 960px) {
                display: none;
              }
            }
            .w1070{
              @media screen and (max-width: 960px) {
                display: none;
              }
            }
          }
          p {
            margin: 0;
            font-size: 18px;
            font-weight: 300;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            line-height: 1.7;
            color: #000000;
            text-align: center;
            @media screen and (max-width: 1070px) {
              font-size: 17px;
            }
            @media screen and (max-width: 960px) {
              text-align: left;
              line-height: 1.5;
            }
            @media screen and (max-width: 650px) {
              font-size: 16px;
            }
            @media screen and (max-width: 500px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
//이지플랫폼>지원혜택(구조도)
.introMap {
  padding: 110px 0 90px;
  height: 470px;
  @media screen and (max-width: 900px) {
    padding: 100px 0 85px;
    height: initial;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0 60px;
  }
  .w1200 {
    @media screen and (max-width: 1800px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
  }
  h2 {
    margin: 0 0 20px 0;
    font-size: 36px;
    font-weight: 400;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.035em;
    color: #111111;
    text-align: center;
    word-break: keep-all;
    @media screen and (max-width: 900px) {
      font-size: 28px;
    }
    @media screen and (max-width: 600px) {
      font-size: 26px;
    }
    @media screen and (max-width: 560px) {
      line-height: 1.4;
    }
    .w560{
      display: none;
      @media screen and (max-width: 560px) {
        display: block;
      }
      @media screen and (max-width: 470px) {
        display: none;
      }
    }
    .bold {
      font-weight: 500;
    }
  }
  p {
    margin: 0 0 55px 0;
    font-size: 18px;
    font-weight: 300;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.025em;
    color: #111111;
    text-align: center;
    line-height: initial;
    @media screen and (max-width: 900px) {
      margin: 0 0 50px 0;
    }
    @media screen and (max-width: 600px) {
      font-size: 17px;
    }
    @media screen and (max-width: 560px) {
      font-size: 16px;
    }
    @media screen and (max-width: 443px) {
      margin: 0 0 40px 0;
      line-height: 1.5;
    }
  }
  figure {
    text-align: center;
    @media screen and (max-width: 1300px) {
      padding: 0 100px;
    }
    @media screen and (max-width: 1000px) {
      padding: 0;
    }
    //@media screen and (max-width: 450px) {
    //  width: 100%;
    //}
    .w1920 {
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .w800 {
      display: none;
      @media screen and (max-width: 1300px) {
        display: none;
      }
      @media screen and (max-width: 900px) {
        display: block;
      }
      @media screen and (max-width: 450px) {
        display: none;
      }
    }
    .w400 {
      display: none;
      @media screen and (max-width: 450px) {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
//이지플랫폼>도입형태
.egService {
  padding: 100px 0;
  background: #3d75c8;
  height: 662px;
  @media screen and (max-width: 1300px) {
    height: initial;
  }
  @media screen and (max-width: 900px) {
    padding: 95px 0 85px;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0 70px;
  }
  .w1200 {
    padding: 0 30px;
    @media screen and (max-width: 1300px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 30px;
    }
    p {
      margin: 0 0 17px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      text-align: center;
      letter-spacing: -0.025em;
      color: #e2edfc;
      word-break: keep-all;
      line-height: initial;
      @media screen and (max-width: 450px){
        line-height: 1.4;
      }
      .dis-n_m{
        display: none;
        @media screen and (max-width: 450px){
          display: initial;
        }
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 15px 0;
        font-size: 17px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
      @media screen and (max-width: 435px) {
        line-height: 1.5;
      }
    }
    h2 {
      margin: 0 0 50px 0;
      font-size: 36px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      text-align: center;
      letter-spacing: -0.035em;
      color: #ffffff;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        font-size: 32px;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 30px 0;
        font-size: 28px;
      }
      @media screen and (max-width: 500px) {
        font-size: 26px;
      }
      @media screen and (max-width: 472px) {
        line-height: 1.4;
      }
      .bold {
        font-weight: 500;
      }
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      @media screen and (max-width: 1300px) {
        padding: 0 35px;
      }
      @media screen and (max-width: 1070px) {
        padding: 0;
      }
      @media screen and (max-width: 970px) {
        display: flex;
        flex-direction: column;
        padding: 0 80px;
      }
      @media screen and (max-width: 750px) {
        padding: 0 20px;
      }
      @media screen and (max-width: 650px) {
        padding: 0;
      }
      .item {
        padding: 35px 0 30px;
        background: #ffffff;
        color: #111111;
        border-radius: 10px;
        @media screen and (max-width: 1105px) {
          padding: 35px 10px 30px;
        }
        @media screen and (max-width: 970px) {
          padding: 40px 95px 30px;
        }
        @media screen and (max-width: 740px) {
          padding: 40px 75px 30px;
        }
        @media screen and (max-width: 650px) {
          padding: 40px 50px 30px;
        }
        @media screen and (max-width: 400px) {
          padding: 40px 30px 30px;
        }
        &:nth-child(1),&:nth-child(2),&:nth-child(4) {
          @media screen and (max-width: 500px) {
            padding: 40px 30px 54px;
          }
          @media screen and (max-width: 350px) {
            padding: 40px 30px 54px;
          }
        }
        h3 {
          margin: 0 0 15px 0;
          font-size: 28px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          text-align: center;
          color: #111111;
          @media screen and (max-width: 650px) {
            font-size: 25px;
          }
          @media screen and (max-width: 500px) {
            font-size: 22px;
          }
        }
        p {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          line-height: 1.5;
          letter-spacing: -0.03em;
          color: #565656;
          word-break: keep-all;

          @media screen and (max-width: 650px) {
            font-size: 17px;
          }
          @media screen and (max-width: 500px) {
            font-size: 16px;
          }
          .w800 {
            @media screen and (max-width: 800px) {
              display: none;
            }
          }
          .w740 {
            @media screen and (max-width: 800px) {
              display: none;
            }
          }
          .w650 {
            @media screen and (max-width: 650px) {
              display: none;
            }
          }
        }
        .small {
          font-size: 14px;
          word-break: keep-all;
          @media screen and (max-width: 650px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
//이지플랫폼>도입기업
.enterprise {
  padding: 120px 0;
  height: 727px;
  @media screen and (max-width: 1300px) {
    height: initial;
  }
  @media screen and (max-width:900px) {
    padding: 100px 0;
  }
  @media screen and (max-width:900px) {
    padding: 80px 0;
  }
  .w1300 {
    @media screen and (max-width: 1800px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      text-align: center;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        font-size: 32px;
      }
      @media screen and (max-width: 650px) {
        font-size: 28px;
      }
      @media screen and (max-width: 500px) {
        font-size: 26px;
        line-height: 1.4;
      }
      .blue {
        font-weight: 600;
        color: #286be4;
      }
    }
    p {
      margin: 0 0 50px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #000000;
      text-align: center;
      line-height: initial;
      word-break: keep-all;
      @media screen and (max-width: 725px) {
        margin: 0 0 40px 0;
        line-height: 1.5;
      }
      @media screen and (max-width: 650px) {
        margin: 0 0 30px 0;
        font-size: 17px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      border-top: 1px solid #c3d3f0;
      border-left: 1px solid #c3d3f0;
      grid-column: 0px;
      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @media screen and (max-width: 730px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (max-width: 625px) {
        grid-template-columns: 1fr 1fr;
      }
      & > div {
        border-right: 1px solid #c3d3f0;
        border-bottom: 1px solid #c3d3f0;
      }
      .item {
        position: relative;
        height: 90px;
        overflow: hidden;
        @media screen and (max-width: 625px) {
          height: 80px;
        }
        @media screen and (max-width: 420px) {
          height: 75px;
        }
        figure {
          text-align: center;
          overflow: hidden;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            //height: 50px;
            object-fit: contain;
          }
        }
        &:nth-child(1) {
          img {
            height: 30px;
            @media screen and (max-width: 400px) {
              height: 25px;
            }
          }
        }
        &:nth-child(2) {
          img {
            top: 53%;
            height: 45px;
            @media screen and (max-width: 400px) {
              height: 40px;
            }
          }
        }
        &:nth-child(3) {
          img {
            top: 53%;
            height: 55px;
          }
        }
        &:nth-child(4) {
          img {
            top: 53%;
            height: 40px;
            @media screen and (max-width: 400px) {
              height: 35px;
            }
          }
        }
        &:nth-child(5) {
          img {
            top: 53%;
            height: 50px;
            @media screen and (max-width: 400px) {
              height: 47px;
            }
          }
        }
        &:nth-child(6) {
          img {
            top: 53%;
            height: 55px;
            @media screen and (max-width: 400px) {
              height: 50px;
            }
          }
        }
        &:nth-child(7) {
          img {
            top: 53%;
            height: 30px;
            @media screen and (max-width: 400px) {
              height: 25px;
            }
          }
        }
        &:nth-child(8) {
          img {
            top: 53%;
            height: 45px;
            @media screen and (max-width: 400px) {
              height: 43px;
            }
          }
        }
        &:nth-child(9) {
          img {
            height: 30px;
            @media screen and (max-width: 400px) {
              height: 25px;
            }
          }
        }
        &:nth-child(10) {
          img {
            top: 53%;
            height: 40px;
            @media screen and (max-width: 400px) {
              height: 37px;
            }
          }
        }
        &:nth-child(11) {
          img {
            top: 53%;
            height: 35px;
          }
        }
        &:nth-child(12) {
          img {
            top: 53%;
            height: 80px;
            @media screen and (max-width: 400px) {
              height: 75px;
            }
          }
        }
        &:nth-child(13) {
          img {
            top: 53%;
            height: 40px;
            @media screen and (max-width: 400px) {
              height: 38px;
            }
          }
        }
        &:nth-child(14) {
          img {
            top: 53%;
            height: 40px;
            @media screen and (max-width: 400px) {
              height: 38px;
            }
          }
        }
        &:nth-child(15) {
          img {
            height: 45px;
            @media screen and (max-width: 400px) {
              height: 43px;
            }
          }
        }
        &:nth-child(16) {
          img {
            height: 37px;
            @media screen and (max-width: 400px) {
              height: 35px;
            }
          }
        }
        &:nth-child(17) {
          img {
            height: 50px;
            @media screen and (max-width: 400px) {
              height: 45px;
            }
          }
        }
        &:nth-child(18) {
          img {
            height: 40px;
            @media screen and (max-width: 400px) {
              height: 37px;
            }
          }
        }
        &:nth-child(19) {
          img {
            height: 50px;
            @media screen and (max-width: 400px) {
              height: 47px;
            }
          }
        }
        &:nth-child(20) {
          img {
            height: 35px;
            @media screen and (max-width: 400px) {
              height: 33px;
            }
          }
        }
        &:nth-child(21) {
          img {
            height: 50px;
            @media screen and (max-width: 400px) {
              height: 47px;
            }
          }
        }
        &:nth-child(22) {
          img {
            height: 35px;
            @media screen and (max-width: 400px) {
              height: 30px;
            }
          }
        }
        &:nth-child(23) {
          img {
            height: 35px;
            @media screen and (max-width: 400px) {
              height: 33px;
            }
          }
        }
        &:nth-child(24) {
          img {
            height: 35px;
            @media screen and (max-width: 400px) {
              height: 33px;
            }
          }
        }
      }
    }
  }
}
//이지플랫폼>궁금한 점
.inquiry {
  padding: 100px 0;
  text-align: center;
  background: #e2edfc;
  height: 477px;
  @media screen and (max-width: 1300px) {
    height: initial;
  }
  @media screen and (max-width: 900px) {
    padding: 95px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0;
  }
  .w1200 {
    @media screen and (max-width: 1300px) {
      padding: 0 50px;
      width: auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0 40px;
    }
  }
  h2 {
    margin: 0 0 50px 0;
    font-size: 36px;
    font-weight: 500;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.035em;
    color: #000000;
    word-break: keep-all;
    @media screen and (max-width: 780px) {
      margin: 0 0 35px 0;
      line-height: 1.5;
    }
    @media screen and (max-width: 650px) {
      font-size: 36px;
    }
    @media screen and (max-width: 500px) {
      font-size: 26px;
    }
    .blue {
      font-weight: 600;
      color: #296be3;
    }
  }
  .flex {
    //justify-content: space-between;
    gap: 20px;
    @media screen and (max-width: 1075px) {
      gap: 15px;
    }
    @media screen and (max-width: 920px) {
      padding: 0 100px;
      flex-direction: column;
    }
    @media screen and (max-width: 700px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 535px) {
      padding: 0;
    }
    li {
      flex: 1;
      padding: 40px 75px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 3px 5px 15px rgba(43, 88, 163, 0.2);
      transition: 0.3s;
      @media screen and (max-width: 1310px) {
        padding: 40px 60px;
      }
      @media screen and (max-width: 1195px) {
        padding: 40px 40px;
      }
      @media screen and (max-width: 920px) {
        padding: 40px 60px;
      }
      @media screen and (max-width: 535px) {
        padding: 40px 10px
      }
      @media screen and (max-width: 400px) {
        padding: 40px 10px;
      }
      &:hover {
        transform: translateY(-10px);
        @media screen and (max-width: 920px) {
          transform: translateY(0px);
        }
      }
      h3 {
        margin: 0 0 10px 0;
        font-size: 24px;
        font-weight: 500;
        font-family: 'NanumSquareNeo';
        letter-spacing: -0.025em;
        color: #111111;
        @media screen and (max-width: 650px) {
          font-size: 23px;
        }
        @media screen and (max-width: 500px) {
          font-size: 21px;
        }
      }
      p {
        margin: 0 0 20px 0;
        font-size: 15px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        line-height: initial;
        color: #111111;
        word-break: keep-all;
        @media screen and (max-width: 500px) {
          margin: 0 0 15px 0;
          font-size: 14px;
        }
        .w1065 {
          display: none;
          @media screen and (max-width: 1065px) {
            display: block;
          }
          @media screen and (max-width: 920px) {
            display: none;
          }
        }
      }
      button {
        display: inline-block;
        padding: 10px 65px;
        outline: none;
        border: none;
        background: none;
        font-size: 18px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.025em;
        color: #ffffff;
        border-radius: 50px;
        cursor: pointer;
        @media screen and (max-width: 960px) {
          padding: 10px 55px;
        }
        @media screen and (max-width: 650px) {
          padding: 10px 50px;
        }
        @media screen and (max-width: 500px) {
          font-size: 17px;
        }
      }
      &:nth-child(1) {
        button {
          background: #6c9bf6;
        }
      }
      &:nth-child(2) {
        //@media screen and (max-width: 960px) {
        //  padding: 10px 55px;
        //}
        button {
          background: #3775e5;
        }
      }
      &:nth-child(3) {
        button {
          background: #1d4a95;
        }
      }
    }
  }
}
@keyframes aniTop {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
//모의훈련 > 메인
.simulated {
  background-image: url(../../public/images/sub_straining_mainbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 520px;
  .container {
    padding: 200px 0 0 40px;
    .engTitle {
      margin: 0 0 25px 0;
      font-size: 16px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      color: #e0e4ed;
      line-height: initial;
      word-break: keep-all;
    }
    h2 {
      margin: 0 0 45px 0;
      font-size: 54px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.05em;
      color: #ffffff;
    }
    h2 ~ p {
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: 1.9;
      word-break: keep-all;
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
    }
    &.active_mv {
      .engTitle {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 ~ p {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
//모의훈련 > 추진개요
.outLine {
  position: relative;
  padding: 180px 50px 130px;
  background: #f5f7f9;
  @media screen and (max-width: 800px) {
    padding: 140px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 130px 30px 60px;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    line-height: 70px;
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      border-top: none;
      line-height: 80px;
    }
    .tabMenu {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 750px) {
        padding: 0 40px;
        width: auto;
      }
      @media screen and (max-width: 400px) {
        padding: 0 10px;
      }
      &.w800 {
        @media screen and (max-width: 900px) {
          width: 650px;
        }
        @media screen and (max-width: 750px) {
          padding: 0 40px;
          width: auto;
        }
      }
      li {
        position: relative;
        border-bottom: 2px solid transparent;
        &.w450 {
          @media screen and (max-width: 540px) {
            display: none;
          }
        }
        &.w400 {
          display: none;
          @media screen and (max-width: 540px) {
            display: block;
          }
        }
        &.active {
          button {
            position: relative;
            font-weight: 500;
            color: #111111;
            &:after {
              content: "";
              position: absolute;
              top: 70%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 2px;
              background: #111111;
              //border-bottom: 3px solid #111111;
            }
          }
        }
        &.active2 {
          border-bottom: 3px solid #00a1c7;
          button {
            font-weight: 500;
            color: #00a1c7;
          }
        }
        button {
          padding: 25px 0;
          outline: none;
          border: none;
          background: none;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #959595;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
          }
          @media screen and (max-width: 360px) {
            font-size: 13px;
          }
          &.tBtn {
            color: #111111;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }
  .container {
    text-align: center;
    h2 {
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.015em;
      color: #111111;
      word-break: keep-all;
      @media screen and (max-width: 800px) {
        font-size: 32px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    h2 + p {
      margin-bottom: 50px;
      font-size: 20px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      color: #272727;
      letter-spacing: -0.015em;
      word-break: keep-all;
      line-height: initial;
      @media screen and (max-width: 800px) {
        font-size: 18px;
        margin-bottom: 35px;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 1.4;
      }
    }
    .flex {
      justify-content: space-between;
      max-width: 1400px;
      @media screen and (max-width: 1050px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
      }
      & > li {
        padding: 55px 20px 0;
        width: calc(20% - 10px);
        height: 270px;
        background: #ffffff;
        border: 1px solid #add4de;
        @media screen and (max-width: 1050px) {
          width: calc(100% / 3 - 6px);
          &:nth-of-type(n + 3){
            width: calc(100% / 3 - 7px);
          }
        }
        @media screen and (max-width: 800px) {
          height: 225px;
          padding: 40px 25px;
        }
        @media screen and (max-width: 630px) {
          width: 100%;
          height: auto;
          padding: 40px 20px;
          &:nth-of-type(n + 3){
            width: 100%;
          }
        }
        &:before {
          content: "";
          display: inline-block;
          margin-bottom: 30px;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 100%;
          @media screen and (max-width: 800px) {
            margin-bottom: 20px;
          }
        }
        &:nth-child(1) {
          &:before {
            background-image: url(../../public/images/icon/sub_straining_support01.png);
            width: 67px;
            height: 65px;
            @media screen and (max-width: 800px) {
              width: 57px;
              height: 55px;
            }
          }
        }
        &:nth-child(2) {
          &:before {
            background-image: url(../../public/images/icon/sub_straining_support02.png);
            width: 60px;
            height: 65px;
            @media screen and (max-width: 800px) {
              width: 50px;
              height: 55px;
            }
          }
        }
        &:nth-child(3) {
          &:before {
            background-image: url(../../public/images/icon/sub_straining_support03.png);
            width: 63px;
            height: 65px;
            @media screen and (max-width: 800px) {
              width: 53px;
              height: 55px;
            }
          }
        }
        &:nth-child(4) {
          &:before {
            background-image: url(../../public/images/icon/sub_straining_support04.png);
            width: 45px;
            height: 65px;
            @media screen and (max-width: 800px) {
              width: 45px;
              height: 55px;
            }
          }
        }
        &:nth-child(5) {
          &:before {
            background-image: url(../../public/images/icon/sub_straining_support05.png);
            width: 60px;
            height: 65px;
            @media screen and (max-width: 800px) {
              width: 50px;
              height: 55px;
            }
          }
        }
        p {
          font-size: 18px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          line-height: 1.5;
          word-break: keep-all;
          br{
            @media screen and (max-width: 1350px) {
              display: none;
            }
            @media screen and (max-width: 630px) {
              display: inherit;
            }
          }
        }
      }
    }
  }
}
//모의훈련 > 추진필요성
.necessity {
  padding: 110px 50px 130px;
  @media screen and (max-width: 1300px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 800px) {
    padding: 100px 30px 80px;
  }
  .w1200 {
    @media screen and (max-width: 700px) {
      padding: 0 50px;
    }
    @media screen and (max-width: 450px) {
      padding: 0 0px;
    }
    h2 {
      margin: 0 0 15px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      text-align: center;
      color: #111111;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 1050px) {
       font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 24px;
      }
      .cyan {
        color: #00a1c7;
        @media screen and (max-width: 1050px) {
          display: block;
        }
        @media screen and (max-width: 380px) {
          display: inline;
        }
      }
    }
    p {
      margin: 0 0 40px 0;
      font-size: 20px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.015em;
      line-height: initial;
      text-align: center;
      color: #272727;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 600px) {
        margin: 0 0 35px 0;
        font-size: 16px;
        line-height: 1.5;
      }
      .bold {
        font-weight: 500;
      }
    }
    .flex {
      justify-content: space-between;
      max-width: 1200px;
      @media screen and (max-width: 700px) {
        display: block;
      }
      & > li {
        position: relative;
        padding: 65px 0px 0px 50px;
        width: calc(33.3% - 15px);
        height: 385px;
        border-radius: 10px;
        @media screen and (max-width: 1050px) {
          padding: 35px 0px 0px 25px;
          height: 230px;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          height: 230px;
          margin: 0 auto 20px;
        }
        span {
          display: inline-block;
          margin-bottom: 30px;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          text-align: left;
          color: #ffffff;
          word-break: keep-all;
          @media screen and (max-width: 1050px) {
            margin-bottom: 10px;
            font-size: 16px;
          }
        }
        p {
          font-size: 60px;
          font-weight: 600;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.015em;
          line-height: initial;
          text-align: left;
          color: #ffffff;
          text-shadow: 2px 2px 4px rgba(16, 92, 110, 0.15);
          @media screen and (max-width: 1050px) {
            font-size: 40px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 13%;
          right: 13%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
        }
        &:nth-child(1) {
          background: #55c2dd;
          &:after {
            background-image: url(../../public/images/icon/sub_straining_necesseity01.png);
            width: 75px;
            height: 75px;
            @media screen and (max-width: 1050px) {
              width: 50px;
              height: 50px;
            }
          }
        }
        &:nth-child(2) {
          background: #00a8d0;
          &:after {
            background-image: url(../../public/images/icon/sub_straining_necesseity02.png);
            width: 85px;
            height: 65px;
            @media screen and (max-width: 1050px) {
              width: 57px;
              height: 45px;
            }
          }
        }
        &:nth-child(3) {
          background: #0090b2;
          &:after {
            background-image: url(../../public/images/icon/sub_straining_necesseity03.png);
            width: 80px;
            height: 77px;
            @media screen and (max-width: 1050px) {
              width: 60px;
              height: 57px;
            }
          }
        }
      }
    }
  }
}
//모의훈련 > 훈련과정
.trainingCourse {
  padding: 110px 50px 105px;
  background: #f5f7f9;
  @media screen and (max-width: 800px) {
    padding:80px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding:80px 30px 80px;
    background: #009bbf;
  }
  .w1200 {
    @media screen and (max-width: 600px) {
      padding: 0 25px;
    }
    @media screen and (max-width: 370px) {
      padding: 0;
    }
    h2 {
      margin: 0 0 20px 0;
      font-size: 38px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.04em;
      color: #111;
      text-align: center;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 900px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
        color: #ffffff;
      }
    }
    h2 + p {
      margin-bottom: 75px;
      font-size: 20px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #111;
      line-height: initial;
      text-align: center;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        font-size: 18px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
        margin-bottom: 35px;
        color: #ffffff;
        line-height: 1.4;
      }
    }
    .flex {
      @media screen and (max-width: 600px) {
        display: block;
      }
      & > li {
        flex: 1;
        padding: 38px 20px 50px;
        background: #ffffff;
        text-align: center;
        border: 1px solid #add4de;
        @media screen and (max-width: 900px) {
          padding: 35px;
        }
        span {
          display: inline-block;
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #00a0c7;
          border-bottom: 1px solid #00a0c7;
          line-height: 1.4;
          word-break: keep-all;
          @media screen and (max-width: 900px) {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
        h3 {
          font-size: 27px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          line-height: 1.4;
          word-break: keep-all;
          @media screen and (max-width: 900px) {
            font-size: 22px;
          }
          &:after {
            content: "";
            display: block;
            margin: 20px auto;
            width: 32px;
            height: 1px;
            background: #111111;
            @media screen and (max-width: 900px) {
              margin: 10px auto;
              width: 26px;
            }
          }
        }
        p {
          font-size: 19px;
          font-weight: 300;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          line-height: 1.5;
          color: #111111;
          word-break: keep-all;
          @media screen and (max-width: 900px) {
           font-size: 16px;
          }
        }
        &:nth-child(2) {
          position: relative;
          border-right:0;
          border-left: 0;
          @media screen and (max-width: 600px) {
            border-left:1px solid #add4de;
            border-right:1px solid #add4de;
            border-top: 0;
            border-bottom: 0;
          }
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
            background-image: url(../../public/images/icon/sub_straining_arrow.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 30px;
            height: 30px;
            @media screen and (max-width: 600px) {
              width: 25px;
              height: 25px;
              top: 0;
              left: 50%;
              background-size: 100%;
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            background-image: url(../../public/images/icon/sub_straining_arrow.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 30px;
            height: 30px;
            @media screen and (max-width: 600px) {
              width: 25px;
              height: 25px;
              top: unset;
              bottom: 0;
              left: 50%;
              background-size: 100%;
              transform: translate(-50%, 50%) rotate(90deg);
            }
          }
        }
        &:nth-child(3) {
          border: none;
          border: 1px solid #add4de;
        }
      }
    }
  }
}
//모의훈련 > 진행절차
.proceedings {
  padding: 110px 50px 130px;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .container {
    h2 {
      margin: 0 0 20px 0;
      font-size: 38px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #111111;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        font-size: 32px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    p {
      margin-bottom: 75px;
      font-size: 20px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      line-height: initial;
      text-align: center;
      color: #272727;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        font-size: 18px;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    figure {
      margin: 0 auto;
      max-width: 1370px;
      width: 100%;
      height: 150px;
      @media screen and (max-width: 900px) {
        text-align: center;
        height: auto;
      }
      .img-pc {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      .img-m {
        display: none;
        @media screen and (max-width: 900px) {
          display: inline;
        }
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .img-mm {
        display: none;
        @media screen and (max-width: 600px) {
          display: inline;
        }
      }
    }
  }
}
//모의훈련 > 문의
.stInquiry {
  padding: 110px 50px 130px;
  background: #f5f7f9;
  @media screen and (max-width: 900px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .personalInfo{
    @media screen and (max-width: 900px) {
      max-width: 600px;
      margin: 0 auto;
      padding-bottom: 60px;
      h3{
          font-size: 22px;
          margin-bottom: 20px;
      }
      @media screen and (max-width: 600px) {
        font-size: 20px;
        word-break: keep-all;
        line-height: 1.4;
      }
      .notice{
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      .useGuide{
        margin-bottom: 20px;
        padding: 20px 10px 20px 20px;
        .content{
          @media screen and (max-width: 600px) {
            padding: 0;
          }
        }
      }
      .useGuide .content > p{
        font-size: 15px;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
    }

  }
  form {
    h2 {
      margin-bottom: 20px;
      font-size: 38px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #111111;
      @media screen and (max-width: 900px) {
        font-size: 32px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
        word-break: keep-all;
        line-height: 1.4;
      }
    }
    h2 + p {
      margin: 0 0 65px 0;
      font-size: 20px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      line-height: initial;
      text-align: center;
      color: #272727;
      @media screen and (max-width: 900px) {
        font-size: 18px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 1.4;
      }
    }
    .inputForm {
      padding-bottom: 80px;
      @media screen and (max-width: 900px) {
        padding-bottom: 50px;
      }
      @media screen and (max-width: 600px) {
        padding-bottom: 40px;
      }
        .flex {
          gap: 30px;
          margin: 0 0 25px 0;
          @media screen and (max-width: 900px) {
            display: block;
            text-align: center;
            margin:0;
          }
          label {
            padding: 20px 10px 20px 30px;
            border: 1px solid #dbdbdb;
            max-width: 600px;
            width: 100%;
            background: #ffffff;
            display: flex;
            @media screen and (max-width: 900px) {
              margin: 0 auto;
              margin-bottom: 20px;
            }
            @media screen and (max-width: 600px) {
              padding: 15px;
            }
            span {
              position: relative;
              display: inline-block;
              width: 22%;
              font-size: 18px;
              font-weight: 500;
              font-family: 'NanumSquareNeo';
              color: #111111;
              text-align: left;
              min-width: 80px;
              @media screen and (max-width: 600px) {
                font-size: 14px;
                min-width: 65px;
                width: 20%;
              }
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 2px;
                height: 24px;
                background: #cfd4d9;
                @media screen and (max-width: 600px) {
                  width: 1px;
                  height: 15px;
                }
              }
            }
            input {
              padding: 0 0 0 30px;
              outline: none;
              border: none;
              width: 78%;
              font-size: 18px;
              background-color: #ffffff !important;
              @media screen and (max-width: 600px) {
                width: 80%;
                font-size: 14px;
                padding: 0 0 0 15px;
              }
              &::placeholder {
                font-size: 18px;
                font-weight: 400;
                font-family: 'NanumSquareNeo';
                letter-spacing: -0.015em;
                color: #b9b9be;
                @media screen and (max-width: 600px) {
                  font-size: 14px;
                }
              }
              &:focus::placeholder {
                color: transparent;
              }
              &:focus {
                background-color: #ffffff !important;
              }
            }
          }
        }
        .textArea {
          text-align: center;
          textarea {
            padding: 20px;
            width: 100%;
            height: 250px;
            border: none;
            outline: none;
            resize: none;
            border: 1px solid #dddddd;
            line-height: 1.4;
            @media screen and (max-width: 900px) {
              max-width: 600px;
            }
            @media screen and (max-width: 600px) {
              font-size: 14px;
              padding: 15px;
            }
            &::placeholder {
              padding: 10px 0 0 15px;
              font-size: 18px;
              font-weight: 400;
              line-height: 1.4;
              font-family: "NanumSquareNeo";
              color: #b9b9be;
              letter-spacing: -0.015em;
              @media screen and (max-width: 600px) {
                font-size: 14px;
                padding: 0;
                word-break: keep-all;
              }
            }
            &:focus::placeholder {
              color: transparent;
            }
          }
        }

    }
    .btn {
      display: flex;
      justify-content: center;
      .apply {
        padding: 15px 65px;
        outline: none;
        border: none;
        background: #00a1c7;
        font-size: 19px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        color: #ffffff;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
  }
}
//제품구성>수신보안
.inboundSecu {
  background-image: url(../../public/images/sub_inbound_mainbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 520px;
  .container {
    @media screen and (max-width: 800px) {
      padding: 180px 0 0 40px;
    }
  }
  &.simulated{
    background-image: url(../../public/images/sub_straining_mainbg.png);
    .container .engTitle {
      color: #e0e4ed;
    }
    .container {
      @media screen and (max-width: 800px) {
        padding: 180px 0 0 40px;
      }
      @media screen and (max-width: 600px) {
        padding: 120px 0 0 0px;
      }
    }
    @media screen and (max-width: 800px) {
      background-image: url(../../public/images/sub_straining_mainbg_800.png);
      height: 490px;
    }
    @media screen and (max-width: 600px) {
      background-image: url(../../public/images/sub_straining_mainbg-m.png);
      height: 460px;
    }
    br{
      @media screen and (max-width: 460px) {
        display: none;
      }
    }
  }
  &.tech{
    background-image: url(../../public/images/sub_techcertify_mainbg.png);
    .container .engTitle {
      color: #ffffff;
    }
    .container {
      @media screen and (max-width: 800px) {
        padding: 180px 0 0 40px;
      }
      @media screen and (max-width: 600px) {
        padding: 120px 0 0 0px;
      }
    }
    @media screen and (max-width: 800px) {
      background-image: url(../../public/images/sub_techcertify_mainbg_800.png);
      height: 490px;
      background-color: #2b3449;
    }
    @media screen and (max-width: 600px) {
      background-image: url(../../public/images/sub_techcertify_mainbg-m.png);
      height: 460px;
      background-color: #2b3449;
    }
    br{
      @media screen and (max-width: 380px) {
        display: none;
      }
    }
  }
  &.cc{
    background-image: url(../../public/images/sub_cc_mainbg.png);
    .container .engTitle {
      color: #ffffff;
    }
    .container {
      @media screen and (max-width: 800px) {
        padding: 180px 0 0 40px;
      }
      @media screen and (max-width: 600px) {
        padding: 120px 0 0 0px;
      }
    }
    @media screen and (max-width: 800px) {
      background-image: url(../../public/images/sub_cc_mainbg_800.png);
      height: 490px;
      background-color: #2b3449;
    }
    @media screen and (max-width: 600px) {
      background-image: url(../../public/images/sub_cc_mainbg-m.png);
      height: 460px;
      background-color: #2b3449;
    }
    br{
      @media screen and (max-width: 380px) {
        display: none;
      }
    }
  }
  &.emlSub{
    background-image: url(../../public/images/sub_eglinker_mainbg.png);
    .container {
      @media screen and (max-width: 800px) {
        padding: 180px 0 0 40px;
      }
      @media screen and (max-width: 600px) {
        padding: 120px 0 0 0px;
      }
    }
    @media screen and (max-width: 800px) {
      background-image: url(../../public/images/sub_eglinker_mainbg_800.png);
      height: 490px;
      background-color: #2b3449;
    }
    @media screen and (max-width: 600px) {
      background-image: url(../../public/images/sub_eglinker_mainbg-m.png);
      height: 460px;
      background-color: #2b3449;
    }
    br{
      @media screen and (max-width: 380px) {
        display: none;
      }
    }
  }
  &.out{
    background-image: url(../../public/images/sub_outgoing_mainbg.png);
    .container {
      @media screen and (max-width: 800px) {
        padding: 180px 0 0 40px;
      }
      @media screen and (max-width: 600px) {
        padding: 120px 0 0 0px;
      }
    }
    @media screen and (max-width: 800px) {
      background-image: url(../../public/images/sub_outgoing_mainbg_800.png);
      height: 490px;
      background-color: #3869c1;
    }
    @media screen and (max-width: 600px) {
      background-image: url(../../public/images/sub_outgoing_mainbg-m.png);
      height: 460px;
      background-color: #3784e4;
    }
  }
  @media screen and (max-width: 800px) {
    background-image: url(../../public/images/sub_inbound_mainbg_800.png);
    height: 490px;
    background-color: #3869c1;
  }
  @media screen and (max-width: 600px) {
    background-image: url(../../public/images/sub_inbound_mainbg-m.png);
    height: 460px;
    background-color: #3869c1;
  }
  .container {
    padding: 200px 0 0 40px;
    @media screen and (max-width: 600px) {
      text-align: center;
      padding: 120px 0 0 0px;
    }
    .engTitle {
      margin-bottom: 25px;
      font-size: 16px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      color: #e0e4ed;
      line-height: initial;
      @media screen and (max-width: 800px) {
        margin-bottom: 15px;
      }
      @media screen and (max-width: 600px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    h2 {
      margin-bottom: 45px;
      font-size: 54px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.05em;
      color: #ffffff;
      @media screen and (max-width: 800px) {
        font-size: 44px;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    p {
     font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: 1.9;
      word-break: keep-all;
      @media screen and (max-width: 800px) {
        font-size: 16px;
      }
      @media screen and (max-width: 600px) {
        font-size: 15px;
        padding: 0 10px;
      }
      br{
        &.w1920 {
          display: block;
          @media screen and (max-width: 880px) {
            display: none;
          }
        }
        &.w330 {
          display: block;
          @media screen and (max-width: 800px) {
            display: none;
          }
          @media screen and (max-width: 330px) {
            display: none;
          }
        }
        &.w960 {
          display: none;
          @media screen and (max-width: 960px) {
            display: block;
          }
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
        &.w920 {
          display: none;
          @media screen and (max-width: 920px) {
            display: block;
          }
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
        &.w920_none {
          display: block;
          @media screen and (max-width: 920px) {
            display: none;
          }
        }
        &.w880 {
          display: none;
          @media screen and (max-width: 880px) {
            display: block;
          }
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
        &.w815 {
          display: none;
          @media screen and (max-width: 815px) {
            display: block;
          }
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
        &.w800 {
          display: none;
          @media screen and (max-width: 800px) {
            display: block;
          }
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
        &.w757 {
          display: none;
          @media screen and (max-width: 757px) {
            display: block;
          }
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
        &.w757_none {
          display: block;
          @media screen and (max-width: 757px) {
            display: none;
          }
        }
        @media screen and (max-width: 330px) {
          display: none;
        }
      }
    }
    &.active_mv {
      .engTitle {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 + p {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
//수신보안>기능체계
.system {
  position: relative;
  padding: 180px 50px 110px;
  @media screen and (max-width: 800px) {
    padding: 140px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 130px 30px 50px;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    line-height: 70px;
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      border-top: none;
      line-height: 80px;
    }
    .tabMenu {
      display: flex;
      justify-content: space-between;
      &.w660{
       max-width: 660px;
        width: 100%;
        padding: 0 30px;
        @media screen and (max-width: 400px) {
          padding: 0 10px;
        }
      }
      &.w800 {
        @media screen and (max-width: 900px) {
          width: 650px;
        }
        @media screen and (max-width: 750px) {
          padding: 0 40px;
          width: auto;
        }
      }
      li {
        position: relative;
        border-bottom: 2px solid transparent;
        &.w450 {
          @media screen and (max-width: 540px) {
            display: none;
          }
        }
        &.w400 {
          display: none;
          @media screen and (max-width: 540px) {
            display: block;
          }
        }
        &.active {
          button {
            position: relative;
            font-weight: 500;
            color: #111111;
            &:after {
              content: "";
              position: absolute;
              top: 70%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 2px;
              background: #111111;
              //border-bottom: 3px solid #111111;
            }
          }
        }
        &.active2 {
          border-bottom: 3px solid #00a1c7;
          button {
            font-weight: 500;
            color: #00a1c7;
          }
        }
        button {
          padding: 25px 0;
          outline: none;
          border: none;
          background: none;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #959595;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
          }
          @media screen and (max-width: 360px) {
            font-size: 13px;
          }
          &.tBtn {
            color: #111111;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }
  .w1320{
    h2 {
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      text-align: center;
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 30px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 25px;
        height: 1px;
        background: #2e2e2e;
      }
      @media screen and (max-width: 800px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
      @media screen and (max-width: 380px) {
        font-size: 24px;
        line-height: 1.4;
        word-break: keep-all;
      }
    }
    p {
      margin-bottom: 60px;
      font-size: 18px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      text-align: center;
      color: #2d2d2d;
      line-height: 1.7;
       br{
         @media screen and (max-width: 1050px) {
           display: none;
         }
       }
      @media screen and (max-width: 800px) {
        font-size: 17px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
      .bold{
        font-weight: 600;
        color: #111111;
      }
    }
    figure {
      max-width: 1320px;
      width: 100%;
      height: calc(100% - 2px);
      border: 1px solid #d2d2d2;
      @media screen and (max-width: 900px) {
        padding: 30px 15px;
      }
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }
}
.img-inbound_pc{
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.img-inbound_m{
  display: none;
  @media screen and (max-width: 900px) {
    display: inline;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.img-inbound_m2{
  display: none;
  @media screen and (max-width: 900px) {
    display: inline;
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
}
.img-inbound_mm{
  display: none;
  @media screen and (max-width: 600px) {
    display: inline;
  }
}
.img-inbound_mm2{
  display: none;
  @media screen and (max-width: 500px) {
    display: inline;
  }
}
//수신보안 > 스팸가드
.spamGuard {
  margin: 0 0 30px 0;
  padding: 100px 50px;
  background: #dceded;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .w1320 {
    justify-content: space-between;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    .titBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 50px;
      @media screen and (max-width: 1150px) {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }
      h2 {
        margin: 0 0 25px 0;
        font-size: 45px;
        font-weight: 800;
        font-family: "Pretendard";
        letter-spacing: -0.015em;
        line-height: 1.15;
        color: #2e2e2e;
        @media screen and (max-width: 800px) {
          font-size: 37px;
        }
        @media screen and (max-width: 600px) {
          font-size: 31px;
        }
        .green {
          font-size: 45px;
          color: #0d6a6a;
          @media screen and (max-width: 800px) {
            font-size: 37px;
          }
          @media screen and (max-width: 600px) {
            font-size: 31px;
          }
        }
        br {
          @media screen and (max-width: 1150px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
      p {
        font-size: 18px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.03em;
        color: #3c3c3c;
        word-break: keep-all;
        @media screen and (max-width: 1150px) {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
        br{
          @media screen and (max-width: 1150px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
    }
    .itemBox {
      flex: 2;
      justify-content: space-between;
      max-width: 910px;
      width: 100%;
      @media screen and (max-width: 1150px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 600px) {
        display: block;
      }
      & > li {
        position: relative;
        padding: 45px 20px 50px;
        width: 32%;
        background: #ffffff;
        text-align: center;
        @media screen and (max-width: 800px) {
          padding: 30px 20px 35px;
        }
        @media screen and (max-width: 600px) {
          margin: 0 auto;
          max-width: 280px;
          width: 100%;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 400px){
          max-width: 230px;
        }
        h3 {
          margin-bottom: 15px;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          word-break: keep-all;
          line-height: 1.4;
          @media screen and (max-width: 800px) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 300;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          word-break: keep-all;
          @media screen and (max-width: 800px) {
            font-size: 15px;
          }
          br {
            @media screen and (max-width: 1300px) {
              display: none;
            }

          }
        }
        &:before{
          content: "";
          display: block;
          margin: 0 auto 20px;
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100%;
          width: 70px;
          height: 80px;
          @media screen and (max-width: 800px) {
            margin: 0 auto 10px;
          }
        }
        &:nth-child(1) {
         &:before{
           background-image: url(../../public/images/icon/sub_inbound_spam01.png);
         }
        }
        &:nth-child(2) {
         &:before{
           background-image: url(../../public/images/icon/sub_inbound_spam02.png);
         }
        }
        &:nth-child(3) {
         &:before {
           background-image: url(../../public/images/icon/sub_inbound_spam03.png);
           width: 60px;
           height: 80px;
         }
        }
      }
    }
  }
}
//수신보안 > 리시브가드
.receiveGuard {
  padding: 100px 50px;
  background: #e8edf6;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .w1320 {
    justify-content: space-between;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .gridContainer {
      display: grid;
      gap: 15px;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 900px;
      width: 100%;
      @media screen and (max-width: 1150px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 600px) {
        display: block;
      }
      .item {
        padding: 40px 10px 50px;
        width: 100%;
        background: #ffffff;
        text-align: center;
        @media screen and (max-width: 800px) {
          padding: 30px 20px 35px;
        }
        @media screen and (max-width: 600px) {
          margin: 0 auto;
          max-width: 280px;
          width: 100%;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 400px){
          max-width: 230px;
        }
        h3 {
          margin-bottom: 20px;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          word-break: keep-all;
          line-height: 1.4;
          @media screen and (max-width: 800px) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 300;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          word-break: keep-all;
          @media screen and (max-width: 800px) {
            font-size: 15px;
          }
          br{
            @media screen and (max-width: 1300px){
                display: none;
            }
          }
        }
        &:before {
          content: "";
          display: block;
          margin: 0 auto 20px;
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100%;
          height: 80px;
          @media screen and (max-width: 800px) {
            margin: 0 auto 10px;
          }
        }
        &:nth-child(1) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_receive01.png);
            width: 65px;
          }
        }
        &:nth-child(2) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_receive02.png);
            width: 60px;
          }
        }
        &:nth-child(3) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_receive03.png);
            width: 60px;
          }
        }
        &:nth-child(4) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_receive04.png);
            width: 60px;
          }
        }
        &:nth-child(5) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_receive05.png);
            width: 70px;
          }
        }
        &:nth-child(6) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_receive06.png);
            width: 60px;
          }
        }
      }
    }
    .titBox {
      margin: 60px 0 0 50px;
      @media screen and (max-width: 1150px) {
        width: 100%;
        text-align: center;
        margin:0;
      }
      h2 {
        margin: 0 0 30px 0;
        font-size: 45px;
        font-weight: 800;
        font-family: "Pretendard";
        letter-spacing: -0.035em;
        line-height: 1.15;
        color: #2e2e2e;
        @media screen and (max-width: 800px) {
          font-size: 37px;
        }
        @media screen and (max-width: 600px) {
          font-size: 31px;
        }
        .purple {
          font-size: 45px;
          color: #201141;
          @media screen and (max-width: 1150px) {
            display: inline;
          }
          @media screen and (max-width: 800px) {
            font-size: 37px;
          }
          @media screen and (max-width: 600px) {
            font-size: 31px;
          }
        }
        br{
          @media screen and (max-width: 1150px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
      p {
        font-size: 18px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.03em;
        color: #3c3c3c;
        word-break: keep-all;
        @media screen and (max-width: 1150px) {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
        br{
          @media screen and (max-width: 1150px) {
           display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
    }
  }
}
//수신보안 > 추천기업
.recommend {
  padding: 120px 50px 90px;
  text-align: center;
  @media screen and (max-width: 800px) {
    padding: 100px 40px 60px;
  }
  @media screen and (max-width: 800px) {
    padding: 80px 30px 30px;
  }
  h2 {
    margin-bottom: 70px;
    font-size: 36px;
    font-weight: 400;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.035em;
    color: #111111;
    word-break: keep-all;
    line-height: 1.4;
    @media screen and (max-width: 800px) {
      margin-bottom: 40px;
      font-size: 32px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 30px;
      font-size: 26px;
    }
    .bold {
      font-weight: 500;
      @media screen and (max-width: 600px) {
        display: block;
      }
    }
  }
  .flex {
    justify-content: space-between;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    & > li {
      padding: 50px 20px 60px;
      width: 32%;
      background: #ffffff;
      box-shadow: 0px 0px 15px 0px rgba(131, 162, 205, 0.1), 0px 2px 15px 0px rgba(131, 162, 205, 0.1);
      @media screen and (max-width: 1150px) {
        width: 100%;
        position: relative;
        padding-left: 35%;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 800px) {
        padding: 30px 0px 40px 30%;
      }
      @media screen and (max-width: 600px) {
        padding: 190px 20px 45px;
      }
      p {
        font-size: 22px;
        font-weight: 400;
        font-family: NanumSquareNeo;
        letter-spacing: -0.03em;
        color: #111111;
        @media screen and (max-width: 800px) {
          font-size: 20px;
        }
        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
        .red {
          font-weight: 500;
          color: #ec1d1d;
        }
        .blue {
          font-weight: 500;
          color: #1c58c5;
        }
        &:first-child {
          &:after{
            content: "";
            display: block;
            margin: 15px auto;
            background-image: url(../../public/images/icon/sub_inbound_arrow.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 17px;
            height: 23px;
          }
        }
      }
      &:before {
        content: "";
        display: block;
        margin: 0 auto 40px;
        background-repeat: no-repeat;
        background-position: center top;
        width: 156px;
        height: 156px;
        @media screen and (max-width: 1150px) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          left: 15%;
        }
        @media screen and (max-width: 800px) {
          left: 10%;
        }
        @media screen and (max-width: 600px) {
          left: 50%;
          transform: translateX(-50%);
          top: 30px;
          width: 125px;
          height: 125px;
          background-size: 100%;
        }
      }
      &:nth-child(1) {
        &:before {
          background-image: url(../../public/images/icon/sub_inbound_recommend01.png);
        }
      }
      &:nth-child(2) {
        &:before {
          background-image: url(../../public/images/icon/sub_inbound_recommend02.png);
        }
      }
      &:nth-child(3) {
        &:before {
          background-image: url(../../public/images/icon/sub_inbound_recommend03.png);
        }
      }
    }
  }
}
//수신보안 > 기술인증
.certification {
  padding: 90px 50px 150px;
  background-image: url(../../public/images/sub_inbound_certify_bg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  text-align: center;
  .w1320 {
    padding: 0 35px;
    @media screen and (max-width: 800px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 0px;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 60px 40px 80px;
    background-size: 100% 540px;
  }
  @media screen and (max-width: 700px) {
    padding: 60px 40px 80px;
    background-size: 100% 1000px;
  }
  @media screen and (max-width: 600px) {
    padding: 30px 30px 80px;
  }
  h2 {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 400;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.035em;
    color: #111111;
    @media screen and (max-width: 800px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 600px) {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .blue{
      font-weight: 600;
      color: #3869c1;
    }
  }
  p {
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 300;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.025em;
    color: #000000;
    line-height: initial;
    line-height: 1.4;
    @media screen and (max-width: 800px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  .flex{
    justify-content: space-between;
    max-width: 1250px;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    & > li {
      padding: 55px 20px 20px;
      width: 32%;
      background: #ffffff;
      box-shadow: 0px 0px 15px 0px rgba(131, 162, 205, 0.1), 0px 2px 15px 0px rgba(131, 162, 205, 0.1);
      z-index: 0;
      @media screen and (max-width: 1150px) {
        width: 100%;
        display: flex;
        padding: 30px;
        margin-bottom: 25px;
        justify-content: space-between;
      }
      @media screen and (max-width: 700px) {
        display: block;
        padding: 50px 15px 15px;
      }
      h3 {
        position: relative;
        margin: 0 0 25px 0;
        font-size: 22px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.035em;
        line-height: 1.6;
        @media screen and (max-width: 1150px) {
          width: 250px;
          text-align: left;
          margin-left: 10px;
          padding-top: 60px;
        }
        @media screen and (max-width: 800px) {
          font-size: 20px;
          width: 220px;
        }
        @media screen and (max-width: 700px) {
          text-align: center;
          width: 100%;
          margin-left: 0;
          padding-top: 0;
        }
      }
      figure {
        width: 100%;
        height: 210px;
        background: #f5f9ff;
        @media screen and (max-width: 1150px) {
          width: calc(100% - 220px);
          max-width: 400px;
        }
      }
      &:nth-child(1) {
        h3{
          z-index: 9;
          &:before {
            content: "";
            position: absolute;
            top: -15px;
            left: 15px;
            background-image: url(../../public/images/icon/sub_inbound_number01.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 88px;
            height: 70px;
            z-index: -1;
            @media screen and (max-width: 1150px) {
              top: 3px;
              left: 0px;
            }
            @media screen and (max-width: 800px) {
              width: 68px;
              height: 50px;
              background-size: 100%;
              top: 20px;
            }
            @media screen and (max-width: 700px) {
              top: 5px;
              left: 115px;
              background-size: 98%;
            }
            @media screen and (max-width: 600px) {
              left: 27%;
              transform: translateX(-50%);
              background-size: 90%;
            }
            @media screen and (max-width: 600px) {
              left: 5%;
              transform: translateX(0);
              background-size: 90%;
            }
          }
        }
        figure {
          padding: 35px 7px;
          @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            max-width: 100%;
          }
        }
      }
      &:nth-child(2) {
        h3{
          &:before {
            content: "";
            position: absolute;
            top: -23px;
            left: 10px;
            background-image: url(../../public/images/icon/sub_inbound_number02.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 120px;
            height: 87px;
            z-index: -1;
            @media screen and (max-width: 1150px) {
              top: -5px;
              left: 0px;
            }
            @media screen and (max-width: 800px) {
              width: 90px;
              height: 57px;
              background-size: 100%;
              top: 15px;
            }
            @media screen and (max-width: 700px) {
              top: 0px;
              left: 105px;
              background-size: 98%;
            }
            @media screen and (max-width: 600px) {
              left: 27%;
              transform: translateX(-50%);
              background-size: 90%;
            }
            @media screen and (max-width: 600px) {
              left: 5%;
              transform: translateX(0);
              background-size: 90%;
            }
          }
        }
        figure {
          padding: 20px 0;
          @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            max-width: 100%;
          }
        }
      }
      &:nth-child(3) {
        h3{
          &:before {
            content: "";
            position: absolute;
            top: -23px;
            left: 10px;
            background-image: url(../../public/images/icon/sub_inbound_number03.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 120px;
            height: 87px;
            z-index: -1;
            @media screen and (max-width: 1150px) {
              top: -5px;
              left: 0px;
            }
            @media screen and (max-width: 800px) {
              width: 90px;
              height: 57px;
              background-size: 100%;
              top: 15px;
            }
            @media screen and (max-width: 700px) {
              top: 0px;
              left: 105px;
              background-size: 98%;
            }
            @media screen and (max-width: 600px) {
              left: 27%;
              transform: translateX(-50%);
              background-size: 90%;
            }
            @media screen and (max-width: 600px) {
              left: 5%;
              transform: translateX(0);
              background-size: 90%;
            }
          }
        }
        figure {
          display: flex;
          padding: 0 20px;
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            max-width: 320px;
            width: 100%;
          }
          @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            max-width: 100%;
            padding:30px 20px;
            max-height: 190px;
            text-align: center;
            justify-content: center;

          }
        }
      }
    }
  }
}
//발신보안
.outgoingSecu{
  background-image: url(../../public/images/sub_outgoing_mainbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 520px;

  .container {
    padding: 200px 0 0 40px;
    .engTitle {
      margin: 0 0 25px 0;
      font-size: 16px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      color: #e0e4ed;
      line-height: initial;
    }
    h2 {
      margin: 0 0 45px 0;
      font-size: 54px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.05em;
      color: #ffffff;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: 1.9;
      word-break: keep-all;
    }
    &.active_mv {
      .engTitle {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 ~ p {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
// 발신보안 > 기능체계
.outgoingSystem {
  position: relative;
  padding: 180px 50px 110px;;
  @media screen and (max-width: 800px) {
    padding: 140px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 130px 30px 50px;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    line-height: 70px;
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      border-top: none;
      line-height: 80px;
    }
    .tabMenu {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 750px) {
        padding: 0 40px;
        width: auto;
      }
      @media screen and (max-width: 400px) {
        padding: 0 10px;
      }
      &.w800 {
        @media screen and (max-width: 900px) {
          width: 650px;
        }
        @media screen and (max-width: 750px) {
          padding: 0 40px;
          width: auto;
        }
      }
      li {
        position: relative;
        border-bottom: 2px solid transparent;
        &.w450 {
          @media screen and (max-width: 540px) {
            display: none;
          }
        }
        &.w400 {
          display: none;
          @media screen and (max-width: 540px) {
            display: block;
          }
        }
        &.active {
          button {
            position: relative;
            font-weight: 500;
            color: #111111;
            &:after {
              content: "";
              position: absolute;
              top: 70%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 2px;
              background: #111111;
              //border-bottom: 3px solid #111111;
            }
          }
        }
        &.active2 {
          border-bottom: 3px solid #00a1c7;
          button {
            font-weight: 500;
            color: #00a1c7;
          }
        }
        button {
          padding: 25px 0;
          outline: none;
          border: none;
          background: none;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #959595;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
          }
          @media screen and (max-width: 360px) {
            font-size: 13px;
          }
          &.tBtn {
            color: #111111;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }
  .w1570 {
    text-align: center;
    max-width: 1570px;
    width: 100%;
    h2 {
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      &:after {
        content: "";
        display: block;
        margin: 30px auto;
        width: 25px;
        height: 1px;
        background: #2e2e2e;
      }
      @media screen and (max-width: 800px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
      @media screen and (max-width: 380px) {
        font-size: 24px;
        line-height: 1.4;
        word-break: keep-all;
      }
    }
    p {
      margin: 0 0 60px 0;
      font-size: 18px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #2d2d2d;
      line-height: 1.7;
      word-break: keep-all;
      br{
        @media screen and (max-width: 830px) {
          display: none;
        }
      }
      @media screen and (max-width: 800px) {
        font-size: 17px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
      .bold {
        font-weight: 600;
      }
    }
    figure {
      height: 100%;
      width: 100%;
      border: 1px solid #d2d2d2;
      padding: 0 15px;
      @media screen and (max-width: 900px) {
        padding: 30px 15px;
      }
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }
}
// 발신보안 > 스팸가드
.outgoingSpam {
  padding: 100px 50px;
  background: #e2edfc;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .w1320 {
    justify-content: space-between;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    .titBox{
      margin-top: 40px;
      margin-right:50px;
      @media screen and (max-width: 1400px) {
        width: 350px;
      }
      @media screen and (max-width: 1150px) {
        width: 100%;
        text-align: center;
        margin:0;
      }
      h2 {
        margin: 0 0 25px 0;
        font-size: 45px;
        font-weight: 800;
        font-family: "Pretendard";
        letter-spacing: -0.015em;
        color: #2e2e2e;
        line-height: 1.15;
        @media screen and (max-width: 800px) {
          font-size: 37px;
        }
        @media screen and (max-width: 600px) {
          font-size: 31px;
        }
        .darkBlue {
          font-size: 45px;
          color: #2b81ec;
          @media screen and (max-width: 800px) {
            font-size: 37px;
          }
          @media screen and (max-width: 600px) {
            font-size: 31px;
          }
        }
        br{
          @media screen and (max-width: 1150px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
      p {
        font-size: 18px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.03em;
        color: #3c3c3c;
        word-break: keep-all;
        @media screen and (max-width: 1150px) {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
        br{
          @media screen and (max-width: 1330px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 900px;
      width: 100%;
      gap: 15px;
      @media screen and (max-width: 1150px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 600px) {
        display: block;
      }
      .item {
        padding: 45px 10px;
        width: 100%;
        background: #ffffff;
        text-align: center;
        @media screen and (max-width: 800px) {
          padding: 30px 20px 35px;
        }
        @media screen and (max-width: 600px) {
          margin: 0 auto;
          max-width: 280px;
          width: 100%;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 400px){
          max-width: 230px;
        }
        h3 {
          margin: 0 0 25px 0;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          word-break: keep-all;
          line-height: 1.4;
          @media screen and (max-width: 800px) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 300;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          @media screen and (max-width: 800px) {
            font-size: 15px;
          }
          br{
            @media screen and (max-width: 1300px){
              display: none;
            }
          }
        }
        &:before {
          content: "";
          display: block;
          margin: 0 auto 10px;
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100%;
        }
        &:nth-child(1) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_send01.png);
            width: 65px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 45px;
              height: 60px;
            }
          }
        }
        &:nth-child(2) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_send02.png);
            width: 70px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 50px;
              height: 60px;
            }
          }
        }
        &:nth-child(3) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_send03.png);
            width: 70px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 50px;
              height: 60px;
            }
          }
        }
        &:nth-child(4) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_send04.png);
            width: 80px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 60px;
              height: 60px;
            }
          }
        }
        &:nth-child(5) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_send05.png);
            width: 75px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 55px;
              height: 60px;
            }
          }
        }
        &:nth-child(6) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_send06.png);
            width: 65px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 45px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}
// 발신보안 > 추천기업
.outgoingRecommend {
  padding: 120px 50px 90px;
  @media screen and (max-width: 800px) {
    padding: 100px 40px 60px;
  }
  @media screen and (max-width: 800px) {
    padding: 80px 30px 30px;
  }
  .w1320 {
    text-align: center;
    h2 {
      margin-bottom: 70px;
      font-size: 36px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 800px) {
        margin-bottom: 40px;
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 30px;
        font-size: 26px;
      }
      .bold {
        font-weight: 500;
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
    }
    .flex {
      justify-content: space-between;
      @media screen and (max-width: 1150px) {
        flex-wrap: wrap;
      }
      & > li {
        padding: 50px 20px 60px;
        width: 32%;
        background: #ffffff;
        box-shadow: 0px 0px 15px 0px rgba(131, 162, 205, 0.1), 0px 2px 15px 0px rgba(131, 162, 205, 0.1);
        @media screen and (max-width: 1150px) {
          width: 100%;
          position: relative;
          padding-left: 35%;
          margin-bottom: 30px;
        }
        @media screen and (max-width: 800px) {
          padding: 30px 0px 40px 30%;
        }
        @media screen and (max-width: 600px) {
          padding: 190px 20px 45px;
        }
        p {
          font-size: 22px;
          font-weight: 400;
          font-family: NanumSquareNeo;
          letter-spacing: -0.03em;
          color: #111111;
          @media screen and (max-width: 800px) {
            font-size: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
          .red {
            font-weight: 500;
            color: #ec1d1d;
          }
          .blue {
            font-weight: 500;
            color: #1c58c5;
          }
          &:first-child {
            &:after {
              content: "";
              display: block;
              margin: 15px auto;
              background-image: url(../../public/images/icon/sub_inbound_arrow.png);
              background-repeat: no-repeat;
              background-position: center;
              width: 17px;
              height: 23px;
            }
          }
        }
        &:before {
          content: "";
          display: block;
          margin: 0 auto 40px;
          background-repeat: no-repeat;
          background-position: center top;
          width: 156px;
          height: 156px;
          @media screen and (max-width: 1150px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            left: 15%;
          }
          @media screen and (max-width: 800px) {
            left: 10%;
          }
          @media screen and (max-width: 600px) {
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
            width: 125px;
            height: 125px;
            background-size: 100%;
          }
        }
        &:nth-child(1) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_recommend01.png);
          }
        }
        &:nth-child(2) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_recommend02.png);
          }
        }
        &:nth-child(3) {
          &:before {
            background-image: url(../../public/images/icon/sub_outgoing_recommend03.png);
          }
        }
      }
    }
  }
}
// 발신보안 > 기술인증
.outgoingCertify {
  padding: 90px 50px 150px;
  background-image: url(../../public/images/sub_outgoing_certify_bg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  text-align: center;
  .w1320 {
    padding: 0 35px;
    @media screen and (max-width: 800px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 0px;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 60px 40px 80px;
    background-size: 100% 540px;
  }
  @media screen and (max-width: 700px) {
    padding: 60px 40px 80px;
    background-size: 100% 1000px;
  }
  @media screen and (max-width: 600px) {
    padding: 30px 30px 80px;
  }
  h2 {
    margin: 0 0 25px 0;
    font-size: 36px;
    font-weight: 400;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.035em;
    color: #111111;
    @media screen and (max-width: 800px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 600px) {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .blue{
      font-weight: 600;
      color: #2f86f2;
    }
  }
  p {
    margin: 0 0 50px 0;
    font-size: 18px;
    font-weight: 300;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.025em;
    color: #000000;
    line-height: initial;
    line-height: 1.4;
    word-break: keep-all;
    @media screen and (max-width: 800px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  .flex{
    justify-content: space-between;
    max-width: 1250px;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    & > li {
      padding: 55px 20px 20px;
      width: 32%;
      background: #ffffff;
      box-shadow: 0px 0px 15px 0px rgba(131, 162, 205, 0.1), 0px 2px 15px 0px rgba(131, 162, 205, 0.1);
      z-index: 0;
      @media screen and (max-width: 1150px) {
        width: 100%;
        display: flex;
        padding: 30px;
        margin-bottom: 25px;
        justify-content: space-between;
      }
      @media screen and (max-width: 700px) {
        display: block;
        padding: 50px 15px 15px;
      }
      h3 {
        position: relative;
        margin: 0 0 25px 0;
        font-size: 22px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.035em;
        line-height: 1.6;
        @media screen and (max-width: 1150px) {
          width: 250px;
          text-align: left;
          margin-left: 10px;
          padding-top: 60px;
        }
        @media screen and (max-width: 800px) {
          font-size: 20px;
          width: 220px;
        }
        @media screen and (max-width: 700px) {
          text-align: center;
          width: 100%;
          margin-left: 0;
          padding-top: 0;
        }
      }
      figure {
        width: 100%;
        height: 210px;
        background: #f5f9ff;
        @media screen and (max-width: 1150px) {
          width: calc(100% - 220px);
          max-width: 400px;
        }
      }
      &:nth-child(1) {
        h3{
          z-index: 9;
          &:before {
            content: "";
            position: absolute;
            top: -15px;
            left: 15px;
            background-image: url(../../public/images/icon/sub_inbound_number01.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 88px;
            height: 70px;
            z-index: -1;
            @media screen and (max-width: 1150px) {
              top: 3px;
              left: 0px;
            }
            @media screen and (max-width: 800px) {
              width: 68px;
              height: 50px;
              background-size: 100%;
              top: 20px;
            }
            @media screen and (max-width: 700px) {
              top: 5px;
              left: 115px;
              background-size: 98%;
            }
            @media screen and (max-width: 600px) {
              left: 27%;
              transform: translateX(-50%);
              background-size: 90%;
            }
            @media screen and (max-width: 600px) {
              left: 5%;
              transform: translateX(0);
              background-size: 90%;
            }
          }
        }
        figure {
          padding: 35px 7px;
          @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            max-width: 100%;
          }
        }
      }
      &:nth-child(2) {
        h3{
          &:before {
            content: "";
            position: absolute;
            top: -23px;
            left: 10px;
            background-image: url(../../public/images/icon/sub_inbound_number02.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 120px;
            height: 87px;
            z-index: -1;
            @media screen and (max-width: 1150px) {
              top: -5px;
              left: 0px;
            }
            @media screen and (max-width: 800px) {
              width: 90px;
              height: 57px;
              background-size: 100%;
              top: 15px;
            }
            @media screen and (max-width: 700px) {
              top: 0px;
              left: 105px;background-size: 98%;
            }
            @media screen and (max-width: 600px) {
              left: 27%;
              transform: translateX(-50%);
              background-size: 90%;
            }
            @media screen and (max-width: 600px) {
              left: 5%;
              transform: translateX(0);
              background-size: 90%;
            }
          }
        }
        figure {
          padding: 20px 0;
          @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            max-width: 100%;
          }
        }
      }
      &:nth-child(3) {
        h3{
          &:before {
            content: "";
            position: absolute;
            top: -23px;
            left: 10px;
            background-image: url(../../public/images/icon/sub_inbound_number03.png);
            background-repeat: no-repeat;
            background-position: center;
            width: 120px;
            height: 87px;
            z-index: -1;
            @media screen and (max-width: 1150px) {
              top: -5px;
              left: 0px;
            }
            @media screen and (max-width: 800px) {
              width: 90px;
              height: 57px;
              background-size: 100%;
              top: 15px;
            }
            @media screen and (max-width: 700px) {
              top: 0px;
              left: 105px;background-size: 98%;
            }
            @media screen and (max-width: 600px) {
              left: 27%;
              transform: translateX(-50%);
              background-size: 90%;
            }
            @media screen and (max-width: 600px) {
              left: 5%;
              transform: translateX(0);
              background-size: 90%;
            }
          }
        }
        figure {
          display: flex;
          padding: 0 20px;
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            max-width: 320px;
            width: 100%;
          }
          @media screen and (max-width: 700px) {
            width: 100%;
            height: auto;
            max-width: 100%;
            padding:30px 20px;
            max-height: 190px;
            text-align: center;
            justify-content: center;

          }
        }
      }
    }
  }
}
//기술인증
.techCertify {
  background-image: url(../../public/images/sub_techcertify_mainbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 520px;
  .container {
    padding: 200px 0 0 40px;
    .engTitle {
      margin: 0 0 25px 0;
      font-size: 16px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.015em;
      color: #ffffff;
      line-height: initial;
    }
    h2 {
      margin: 0 0 45px 0;
      font-size: 54px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.05em;
      color: #ffffff;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: 1.9;
      word-break: keep-all;
    }
    &.active_mv {
      .engTitle {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 ~ p {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
// 기술인증 > CC인증
.ccCertify {
  position: relative;
  padding: 180px 50px 110px;
  @media screen and (max-width: 800px) {
    padding: 140px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 130px 30px 80px;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    line-height: 70px;
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      border-top: none;
      line-height: 80px;
    }
    .tabMenu {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 750px) {
        padding: 0 40px;
        width: auto;
      }
      @media screen and (max-width: 400px) {
        padding: 0 10px;
      }
      &.w800 {
        @media screen and (max-width: 900px) {
          width: 650px;
        }
        @media screen and (max-width: 750px) {
          padding: 0 40px;
          width: auto;
        }
      }
      li {
        position: relative;
        border-bottom: 2px solid transparent;
        &.w450 {
          @media screen and (max-width: 540px) {
            display: none;
          }
        }
        &.w400 {
          display: none;
          @media screen and (max-width: 540px) {
            display: block;
          }
        }
        &.active {
          button {
            position: relative;
            font-weight: 500;
            color: #111111;
            &:after {
              content: "";
              position: absolute;
              top: 70%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 2px;
              background: #111111;
              //border-bottom: 3px solid #111111;
            }
          }
        }
        &.active2 {
          border-bottom: 3px solid #00a1c7;
          button {
            font-weight: 500;
            color: #00a1c7;
          }
        }
        button {
          padding: 25px 0;
          outline: none;
          border: none;
          background: none;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #959595;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
          }
          @media screen and (max-width: 360px) {
            font-size: 13px;
          }
          &.tBtn {
            color: #111111;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }
  .w1320 {
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      text-align: center;
      color: #111111;
      @media screen and (max-width: 1050px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    p {
      margin: 0 0 40px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #272727;
      @media screen and (max-width: 600px) {
        font-size: 15px;
        line-height: 1.8;
        word-break: keep-all;
        margin-bottom: 35px;
      }
      br{
        @media screen and (max-width: 700px) {
         display: none;
        }
      }
    }
    .flex {
      justify-content: space-between;
      padding: 0 145px;
      background: #ffffff;
      border: 1px solid #dadada;
      @media screen and (max-width: 1200px) {
        padding: 0 83px;
      }
    /*  @media screen and (max-width: 1190px) {
        padding: 0 30px;
      }*/
/*      @media screen and (max-width: 1100px) {
        padding: 0;
      }*/
      @media screen and (max-width: 1040px) {
        display: block;
        text-align: center;
        padding: 30px 83px 0;
      }
      @media screen and (max-width: 550px) {
        display: block;
        text-align: center;
        padding: 30px 20px 0;
      }
      figure {
        display: flex;
        align-items: center;
        margin-right: 30px;
        @media screen and (max-width: 1200px) {
          width: 350px;
        }
        @media screen and (max-width: 1040px) {
          width: 315px;
          margin: 0 auto;
        }
        @media screen and (max-width: 600px) {
          max-width: 260px;
          width: 100%;
        }
      }
      .text {
        padding: 45px 0;
        span {
          display: inline-block;
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #555555;
          word-break: keep-all;
          @media screen and (max-width: 1040px) {
            font-size: 16px;
            margin-bottom: 15px;
          }
          br{
            @media screen and (max-width: 460px) {
              display: none;
            }
          }
          &:last-child{
            margin: 0;
            line-height: 1.5;
          }
        }
        h3 {
          margin: 0 0 30px 0;
          font-size: 44px;
          font-weight: 900;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.015em;
          color: #201141;
          @media screen and (max-width: 1040px) {
            font-size: 36px;
          }
          @media screen and (max-width: 600px) {
            font-size: 26px;
            line-height: 1.4;
            margin: 0 0 20px 0;
          }
        }
        p {
          margin: 0 0 15px 0;
          font-size: 28px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #2e2e2e;
          text-align: left;
          line-height: initial;

          @media screen and (max-width: 1040px) {
            text-align: center;
            font-size: 24px;
          }
          @media screen and (max-width: 600px) {
            font-size: 22px;
          }
        }
      }
    }
  }
}
// 기술인증 > 가트너
.gartner {
    padding: 90px 50px;
    background: #f5f7f9;
  @media screen and (max-width: 1050px) {
    padding: 80px;
  }
  @media screen and (max-width: 680px) {
    padding: 80px 30px;
  }
  .dis-n_m{
    @media screen and (max-width: 1050px) {
      display: none;
    }
  }
  .dis-b_m{
    display: none;
    @media screen and (max-width: 1050px) {
      display: inline;
    }
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  .dis-b_mm{
    display: none;
    @media screen and (max-width: 400px) {
      display: inline;
    }
  }
    .w1320 {
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      text-align: center;
      @media screen and (max-width: 1050px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    p {
      margin: 0 0 40px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #272727;
      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 1.4;
        word-break: keep-all;
      }
    }
    .flex {
      margin: 0 0 20px 0;
      gap: 20px;
      @media screen and (max-width: 1050px) {
        display: block;
      }
      .reportBox {
        width: calc(100% / 2);
        background: #ffffff;
        border: 1px solid #dddddd;
        @media screen and (max-width: 1050px) {
          width: 100%;
          margin-bottom: 20px;
        }
        h3 {
          position: relative;
          padding: 11px 10px 11px 35px;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          color: #ffffff;
          background: linear-gradient(90deg, rgba(42,105,201,0) 0%, rgba(42,105,201,1) 0%, rgba(42,105,201,1) 0%, rgba(42,105,201,1) 70%, rgba(87,162,219,1) 100%, rgba(84,457,220,1) 100%);
          @media screen and (max-width: 1150px) {
            font-size: 21px;
            padding: 10px 10px 10px 20px;
          }
          @media screen and (max-width: 1050px) {
            padding: 10px 10px 10px 35px;
          }
          @media screen and (max-width: 600px) {
            font-size: 17px;
            padding: 10px;
            word-break: keep-all;
            line-height: 1.4;
          }
          .title{
            position: relative;
            display: inline-block;
            margin-right: 20px;
            width: 100px;
            @media screen and (max-width: 600px) {
              width: auto;
              padding-right: 10px;
              margin-right: 10px;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 2px;
              height: 17px;
              background: #ffffff;
            }
          }
        }
        & > div {
          padding: 20px 35px;
          @media screen and (max-width: 1050px) {
            padding: 30px 30px 20px;
          }
          @media screen and (max-width: 1037px) {
            padding: 25px 30px;
          }
          @media screen and (max-width: 600px) {
            padding: 20px;
          }
          p {
            margin: 0 0 15px 0;
            font-size: 15px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.02em;
            color: #555555;
            line-height: 1.5;
            text-align: left;
            .bold {
              font-weight: 500;
              color: #111111;
            }
          }
        }
      }
      .listBox {
        width: calc(100% / 2);
        background: #ffffff;
        border: 1px solid #dddddd;
        @media screen and (max-width: 1050px) {
          width: 100%;
        }
        h3 {
          position: relative;
          padding: 10px 10px 10px 35px;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          color: #ffffff;
          background: linear-gradient(90deg, rgba(42,105,201,0) 0%, rgba(42,105,201,1) 0%, rgba(42,105,201,1) 0%, rgba(42,105,201,1) 70%, rgba(87,162,219,1) 100%, rgba(84,457,220,1) 100%);
          @media screen and (max-width: 1150px) {
            font-size: 21px;
            padding: 10px 10px 10px 20px;
          }
          @media screen and (max-width: 1050px) {
            padding: 10px 10px 10px 35px;
          }
          @media screen and (max-width: 600px) {
            font-size: 17px;
            padding: 10px;
            word-break: keep-all;
            line-height: 1.4;
          }
          .title{
            position: relative;
            display: inline-block;
            margin: 0 20px 0 0;
            width: 100px;
            @media screen and (max-width: 600px) {
              width: auto;
              padding-right: 10px;
              margin-right: 10px;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 2px;
              height: 17px;
              background: #ffffff;
            }
          }
        }
        & > div {
          padding: 20px 20px 20px 35px;
          @media screen and (max-width: 1050px) {
            padding: 30px 30px 20px;
          }
          @media screen and (max-width: 600px) {
            padding: 20px;
          }
          p {
            margin: 0 0 15px 0;
            font-size: 15px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.02em;
            color: #555555;
            line-height: 1.5;
            text-align: left;
            .bold {
              font-weight: 500;
              color: #111111;
            }
          }
        }
      }
    }
    .vendorList {
      width: 100%;
      height: 260px;
      background: #ffffff;
      border: 1px solid #dddddd;
      @media screen and (max-width: 1050px) {
        height: auto;
      }
      h3 {
        position: relative;
        padding: 10px 10px 10px 35px;
        font-size: 22px;
        font-weight: 500;
        font-family: "NanumSquareNeo";
        color: #ffffff;
        background: rgb(6,66,159);
        background: linear-gradient(90deg, rgba(42,105,201,0) 0%, rgba(42,105,201,1) 0%, rgba(42,105,201,1) 0%, rgba(42,105,201,1) 70%, rgba(87,162,219,1) 100%, rgba(84,457,220,1) 100%);
        @media screen and (max-width: 1150px) {
          font-size: 21px;
          padding: 10px 10px 10px 20px;
        }
        @media screen and (max-width: 1050px) {
          padding: 10px 10px 10px 35px;
        }
        @media screen and (max-width: 600px) {
          font-size: 17px;
          padding: 10px;
          word-break: keep-all;
          line-height: 1.4;
        }
        .title{
          position: relative;
          display: inline-block;
          margin: 0 20px 0 0;
          width: 100px;
          @media screen and (max-width: 600px) {
            width: auto;
            padding-right: 10px;
            margin-right: 10px;
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 2px;
            height: 17px;
            background: #ffffff;
          }
        }
      }
      & > div {
        padding: 30px 20px 20px 35px;
        @media screen and (max-width: 1050px) {
          padding: 30px 30px 20px;
        }
        @media screen and (max-width: 600px) {
          padding: 20px;
        }
        p {
          margin: 0 0 15px 0;
          font-size: 15px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #555555;
          line-height: 1.5;
          text-align: left;
          .bold {
            font-weight: 500;
            color: #111111;
          }
        }
      }
    }
  }
}
// 기술인증 > 기술인증현황
.situation {
  padding: 90px 50px 100px;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 30px;
  }
  .w1320 {
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      text-align: center;
      @media screen and (max-width: 1050px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    p {
      margin: 0 0 35px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #272727;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      @media screen and (max-width: 1050px) {
        display: block;
      }
      .item {
        width: 100%;
        background: #f5f7f9;
        border: 1px solid #dddddd;
        @media screen and (max-width: 1050px) {
          margin-bottom: 30px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
        h3 {
          position: relative;
          padding: 7px 0 6px 35px;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          color: #ffffff;
          background: #3661a1;
          line-height: 1.4;
          word-break: keep-all;
          @media screen and (max-width: 1050px) {
            font-size: 20px;
            padding: 10px 0 10px 30px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
            padding: 10px 20px;
          }
        }
        & > div {
          padding: 20px 20px 30px 35px;
          @media screen and (max-width: 1050px) {
            padding: 20px 30px;
          }
          @media screen and (max-width: 600px) {
            padding: 20px;
          }
          p {
            margin: 0 0 15px 0;
            font-size: 15px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.02em;
            color: #555555;
            line-height: 1.5;
            text-align: left;
            br{
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
            @media screen and (max-width: 1050px) {
              font-size: 14px;
            }
            .bold {
              font-weight: 500;
              color: #111111;
            }
          }
          figure {
            text-align: center;
            display: flex;
            width: 100%;
            img{
              max-width: 100%;
            }
          }
        }

      }
    }
  }
}
// 기술인증 > 국제표준
.standard {
  padding: 90px 50px;
  background: #f5f7f9;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 60px 30px;
  }
  .w1200 {
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      text-align: center;
      @media screen and (max-width: 1050px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    p {
      margin: 0 0 40px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #272727;
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    .flex {
      justify-content: space-between;
      @media screen and (max-width: 1050px) {
        display: block;
      }
      & > div {
        max-width: 600px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #b6c9d4;
        @media screen and (max-width: 1050px) {
         margin: 0 auto;
        }
        &:first-of-type{
          margin-right: 20px;
          @media screen and (max-width: 1050px) {
            margin: 0 auto 30px;
          }
          @media screen and (max-width: 600px) {
            margin: 0 auto 20px;
          }
        }
        h3 {
          padding: 12px 0;
          font-size: 20px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.025em;
          color: #ffffff;
          text-align: center;
          background: #2b3449;
          word-break: keep-all;
          @media screen and (max-width: 600px) {
            font-size: 18px;
            line-height: 1.4;
          }
        }
        figure {
          padding: 30px 20px 40px;
          text-align: center;
          .dis-n_m{
            @media screen and (max-width: 600px) {
             display: none;
            }
          }
          .dis-b_m {
            display: none;
            @media screen and (max-width: 600px) {
              display: inline;
            }
          }
        }
      }
    }
  }
}
//이지링커
.eml {
  background: url(../../public/images/sub_eglinker_mainbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 520px;
  .container {
    padding: 200px 0 0 40px;
    .engTitle {
      margin: 0 0 25px 0;
      font-size: 16px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      color: #e0e4ed;
      line-height: initial;
    }
    h2 {
      margin: 0 0 45px 0;
      font-size: 54px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.05em;
      color: #ffffff;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: 1.9;
      word-break: keep-all;
    }
    &.active_mv {
      .engTitle {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 + p {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
// 이지링커 > 기능체계
.egSystem {
  position: relative;
  padding: 180px 50px 110px;
  @media screen and (max-width: 800px) {
    padding: 140px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 130px 30px 50px;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    line-height: 70px;
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      border-top: none;
      line-height: 80px;
    }
    .tabMenu {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 750px) {
        padding: 0 40px;
        width: auto;
      }
      @media screen and (max-width: 400px) {
        padding: 0 10px;
      }
      &.w800 {
        @media screen and (max-width: 900px) {
          width: 650px;
        }
        @media screen and (max-width: 750px) {
          padding: 0 40px;
          width: auto;
        }
      }
      li {
        position: relative;
        border-bottom: 2px solid transparent;
        &.w450 {
          @media screen and (max-width: 540px) {
            display: none;
          }
        }
        &.w400 {
          display: none;
          @media screen and (max-width: 540px) {
            display: block;
          }
        }
        &.active {
          button {
            position: relative;
            font-weight: 500;
            color: #111111;
            &:after {
              content: "";
              position: absolute;
              top: 70%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 2px;
              background: #111111;
              //border-bottom: 3px solid #111111;
            }
          }
        }
        &.active2 {
          border-bottom: 3px solid #00a1c7;
          button {
            font-weight: 500;
            color: #00a1c7;
          }
        }
        button {
          padding: 25px 0;
          outline: none;
          border: none;
          background: none;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #959595;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
          }
          @media screen and (max-width: 360px) {
            font-size: 13px;
          }
          &.tBtn {
            color: #111111;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }
  .container {
    text-align: center;
    h2 {
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      &:after {
        content: "";
        display: block;
        margin: 30px auto;
        width: 25px;
        height: 1px;
        background: #2e2e2e;
      }
      @media screen and (max-width: 800px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
      @media screen and (max-width: 380px) {
        font-size: 24px;
        line-height: 1.4;
        word-break: keep-all;
      }
    }
    p {
      margin: 0 0 60px 0;
      font-size: 18px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #2d2d2d;
      word-break: keep-all;
      br{
        @media screen and (max-width: 1050px) {
          display: none;
        }
      }
      @media screen and (max-width: 800px) {
        font-size: 17px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
      .bold {
        font-weight: 600;
      }
    }
  }
}
// 이지링커 > 이지링커
.egLinker {
  padding: 100px 50px;
  background: #e6ebf7;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .container {
    justify-content: space-between;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    .titBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 50px;
      @media screen and (max-width: 1150px) {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }
      h2 {
        margin: 0 0 25px 0;
        font-size: 45px;
        font-weight: 800;
        font-family: "Pretendard";
        letter-spacing: -0.015em;
        color: #4f4f4f;
        line-height: 1.15;
        @media screen and (max-width: 800px) {
          font-size: 37px;
        }
        @media screen and (max-width: 600px) {
          font-size: 31px;
        }
        .purple {
          font-size: 45px;
          letter-spacing: -0.025em;
          color: #1c263c;
          @media screen and (max-width: 800px) {
            font-size: 37px;
          }
          @media screen and (max-width: 600px) {
            font-size: 31px;
          }
        }
        br {
          @media screen and (max-width: 1150px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
      p {
        font-size: 18px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.03em;
        color: #3c3c3c;
        @media screen and (max-width: 1150px) {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
        br{
          @media screen and (max-width: 1150px) {
            display: none;
          }
          @media screen and (max-width: 600px) {
            display: initial;
          }
        }
      }
    }
    .itemBox {
      flex: 2;
      justify-content: space-between;
      max-width: 910px;
      width: 100%;
      @media screen and (max-width: 1150px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 600px) {
        display: block;
      }
      & > li {
        padding: 45px 20px 50px;
        width: 32%;
        background: #ffffff;
        text-align: center;
        @media screen and (max-width: 1140px) {
          padding: 45px 20px 40px
        }
        @media screen and (max-width: 800px) {
          padding: 30px 20px 35px;
        }
        @media screen and (max-width: 600px) {
          margin: 0 auto;
          max-width: 280px;
          width: 100%;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 400px){
          max-width: 230px;
        }
        h3 {
          margin: 0 0 15px 0;
          font-size: 22px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          word-break: keep-all;
          line-height: 1.4;
          @media screen and (max-width: 910px) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        p{
          font-size: 18px;
          font-weight: 300;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          word-break: keep-all;
          @media screen and (max-width: 910px) {
            font-size: 15px;
          }
          br {
            @media screen and (max-width: 1300px) {
              display: none;
            }

          }
        }
        &:before {
          content: "";
          display: block;
          margin: 0 auto 10px;
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100%;
        }
        &:nth-child(1) {
          &:before {
            background-image: url(../../public/images/icon/sub_eglinker_linker01.png);
            width: 80px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 60px;
              height: 60px;
            }
          }
        }
        &:nth-child(2) {
          &:before {
            background-image: url(../../public/images/icon/sub_eglinker_linker02.png);
            width: 85px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 65px;
              height: 60px;
            }
          }
        }
        &:nth-child(3) {
          &:before {
            background-image: url(../../public/images/icon/sub_eglinker_linker03.png);
            width: 80px;
            height: 80px;
            @media screen and (max-width: 800px) {
              width: 60px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}
//이지링커 > 주요기능(탭)
.mainFunction {
  padding: 110px 50px;
  @media screen and (max-width: 800px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 70px 30px 80px;
  }
  .w1320 {
    h2 {
      margin-bottom: 80px;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.03em;
      text-align: center;
      color: #111111;
      @media screen and (max-width: 800px) {
        font-size: 32px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    & > ul {
      margin-bottom: 60px;
      justify-content: space-evenly;
      @media screen and (max-width: 800px) {
        margin-bottom: 40px;
      }
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      & > li {
        width: 25%;
        text-align: center;
        padding: 0 10px 25px;
        font-size: 22px;
        font-weight: 400;
        font-family: "NanumSquareNeo";
        letter-spacing: -0.015em;
        color: #646b79;
        cursor: pointer;
        border-bottom: 1px solid #cad1e3;
        word-break: keep-all;
        line-height: 1.4;
        @media screen and (max-width: 1300px) {
          font-size: 20px;
        }
        @media screen and (max-width: 800px) {
          padding: 0 10px 20px;
          font-size: 16px;
        }
        @media screen and (max-width: 600px) {
         width: auto;
          border: 0;
          border-radius: 5px;
          background-color: #f2f4f8;
          padding: 10px;
          display: inline-block;
          color: #6b7587;
          &:first-of-type{
            margin-right: 10px;
            margin-bottom: 10px;
          }
          &:nth-of-type(2){
            margin-bottom: 10px;
          }
          &:nth-of-type(3){
            margin-right: 10px;
          }
        }
        @media screen and (max-width: 420px) {
          font-size: 14px;
        }
        @media screen and (max-width: 390px) {
          font-size: 13px;
        }
        @media screen and (max-width: 360px) {
          font-size: 12px;
          padding: 10px 5px;
        }
        &.on {
          font-weight: 500;
          color: #1c263c;
          border-bottom: 3px solid #1c263c;
          @media screen and (max-width: 600px) {
           border: 0;
            background-color: #2b3449;
            color: #fff;
          }
        }
      }
    }
    .tabData {
      justify-content: space-between;
      @media screen and (max-width: 1050px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      & > figure{
        @media screen and (max-width: 1050px) {
          width: 100%;
          text-align: center;
        }
      }
      .b-img-pc{
        @media screen and (max-width: 1050px) {
          display: none;
        }
      }
      .b-img-m{
        display: none;
        @media screen and (max-width: 1050px) {
         display: inline;
        }
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .b-img-mm{
        display: none;
        @media screen and (max-width: 600px) {
          display: inline;
        }
      }
      .titBox {
        margin-right: 50px;
        width: 390px;
        h3 {
          margin: 10px 0 0 0;
          font-size: 34px;
          font-weight: 500;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.025em;
          color: #1c263c;
          line-height: 1.4;
          word-break: keep-all;
          @media screen and (max-width: 1300px) {
            font-size: 30px;
          }
          @media screen and (max-width: 800px) {
            font-size: 26px;
          }
          @media screen and (max-width: 600px) {
            font-size: 22px;
            margin: 0px 0 20px 0px;
          }
          &:after {
            content: "";
            display: block;
            margin: 35px 0;
            width: 30px;
            height: 1px;
            background: #1c263c;
            @media screen and (max-width: 1050px) {
              margin: 15px auto;
            }
            @media screen and (max-width: 600px) {
              margin: 15px auto 0;
            }
          }
        }
        p {
          margin-bottom: 80px;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #5a6274;
          line-height: 1.7;
          white-space: pre-wrap;
          @media screen and (max-width: 1300px) {
            font-size: 17px;
          }
          @media screen and (max-width: 1250px) {
           white-space: initial;
          }
          @media screen and (max-width: 1200px) {
            margin-bottom: 40px;
          }
          @media screen and (max-width: 800px) {
            font-size: 16px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
            white-space: pre-wrap;
          }
        }
        @media screen and (max-width: 1050px) {
          position: relative;
          width: 100%;
          text-align: center;
          margin-right: 0;

        }
      }
    }
  }
}
//이지링커 > 추천기업
.egRecommend {
  padding: 110px 50px;
  text-align: center;
  background: #e6ebf7;
  @media screen and (max-width: 800px) {
    padding: 100px 40px;
  }
  @media screen and (max-width: 800px) {
    padding: 80px 30px;
  }
  .w1320 {
    text-align: center;
    h2 {
      margin: 0 0 70px 0;
      font-size: 36px;
      font-weight: 400;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 800px) {
        margin-bottom: 40px;
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 30px;
        font-size: 26px;
      }
      .bold {
        font-weight: 500;
      }
    }
    .flex {
      justify-content: space-between;
      @media screen and (max-width: 1150px) {
        flex-wrap: wrap;
      }
      & > li {
        padding: 50px 20px 60px;
        width: 32%;
        background: #ffffff;
        @media screen and (max-width: 1150px) {
          width: 100%;
          position: relative;
          padding-left: 35%;
          margin-bottom: 30px;
        }
        @media screen and (max-width: 800px) {
          padding: 30px 0px 40px 30%;
        }
        @media screen and (max-width: 600px) {
          padding: 190px 20px 45px;
        }
        p {
          font-size: 22px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.03em;
          color: #111111;
          @media screen and (max-width: 800px) {
            font-size: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
          br{
            @media screen and (max-width: 380px) {
              display: none;
            }
          }
          .red {
            font-weight: 500;
            color: #ec1d1d;
          }
          .blue {
            font-weight: 500;
            color: #1c58c5;
          }
          &:first-child {
            &:after {
              content: "";
              display: block;
              margin: 15px auto;
              background-image: url(../../public/images/icon/sub_inbound_arrow.png);
              background-repeat: no-repeat;
              background-position: center;
              width: 17px;
              height: 23px;
            }
          }
        }
        &:before {
          content: "";
          display: block;
          margin: 0 0 35px 0;
          background-repeat: no-repeat;
          background-position: center;
          height: 156px;
          @media screen and (max-width: 1150px) {
            width: 156px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            left: 15%;
          }
          @media screen and (max-width: 800px) {
            left: 10%;
          }
          @media screen and (max-width: 600px) {
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
            width: 125px;
            height: 125px;
            background-size: 100%;
          }
        }
        &:nth-child(1) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_recommend01.png);
          }
        }
        &:nth-child(2) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_recommend02.png);
          }
        }
        &:nth-child(3) {
          &:before {
            background-image: url(../../public/images/icon/sub_inbound_recommend03.png);
          }
        }
      }
    }
  }
}
//제품구성 > CC인증
.ccCertification {
  background: url(../../public/images/sub_cc_mainbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 520px;
  .container {
    padding: 200px 0 0 40px;
    .engTitle {
      margin: 0 0 25px 0;
      font-size: 16px;
      font-weight: 300;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: initial;
    }
    h2 {
      margin: 0 0 45px 0;
      font-size: 54px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.05em;
      color: #ffffff;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      color: #ffffff;
      line-height: 1.9;
      word-break: keep-all;
    }
    &.active_mv {
      .engTitle {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 {
        animation: main_effect 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
      h2 + p {
        animation: main_effect 0.5s 0.5s ease-out;
        animation-fill-mode: both;
        opacity: 1;
      }
    }
  }
}
// CC인증 > 리시브가드 v1.1
.ReceiveGuard {
  position: relative;
  padding: 180px 50px;
  @media screen and (max-width: 980px) {
    padding: 130px 40px 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 140px 30px 80px;
  }
  .menuPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    line-height: 70px;
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      border-top: none;
      line-height: 80px;
    }
    .tabMenu {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 750px) {
        padding: 0 40px;
        width: auto;
      }
      @media screen and (max-width: 400px) {
        padding: 0 30px;
      }
      &.w800 {
        @media screen and (max-width: 900px) {
          width: 650px;
        }
        @media screen and (max-width: 750px) {
          padding: 0 40px;
          width: auto;
        }
      }
      li {
        position: relative;
        border-bottom: 2px solid transparent;
        &.w450 {
          @media screen and (max-width: 540px) {
            display: none;
          }
        }
        &.w400 {
          display: none;
          @media screen and (max-width: 540px) {
            display: block;
          }
        }
        &.active {
          button {
            position: relative;
            font-weight: 500;
            color: #111111;
            &:after {
              content: "";
              position: absolute;
              top: 70%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 2px;
              background: #111111;
              //border-bottom: 3px solid #111111;
            }
          }
        }
        &.active2 {
          border-bottom: 3px solid #00a1c7;
          button {
            font-weight: 500;
            color: #00a1c7;
          }
        }
        button {
          padding: 25px 0;
          outline: none;
          border: none;
          background: none;
          font-size: 18px;
          font-weight: 400;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.02em;
          color: #959595;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
          }
          @media screen and (max-width: 360px) {
            font-size: 13px;
          }
          &.tBtn {
            color: #111111;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }
  .w1200 {
    .flex {
      padding: 0 70px;
      justify-content: space-between;
      @media screen and (max-width: 1250px) {
        padding: 0 40px;
      }
      @media screen and (max-width: 1190px) {
        padding: 0 10px;
      }
      @media screen and (max-width: 1100px) {
        padding: 0;
      }
      @media screen and (max-width: 1060px) {
        display: block;
        text-align: center;
      }
      .description {
        h2 {
          margin: 0 0 30px 0;
          font-size: 44px;
          font-weight: 900;
          font-family: "NanumSquareNeo";
          letter-spacing: -0.015em;
          color: #201141;
          word-break: keep-all;
          @media screen and (max-width: 1050px) {
            font-size: 36px;
          }
          @media screen and (max-width: 600px) {
            font-size: 26px;
            margin-bottom: 25px;
          }
        }
        p {
          &:first-child {
            margin-bottom: 23px;
            font-size: 18px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            color: #555555;
            line-height: initial;
            @media screen and (max-width: 1050px) {
              font-size: 16px;
            }
            @media screen and (max-width: 980px) {
              margin-bottom: 15px;
            }

          }
          &.subTit {
            margin: 0 0 20px 0;
            font-size: 28px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            color: #2e2e2e;
            line-height: initial;
            word-break: keep-all;
            @media screen and (max-width: 1050px) {
              font-size: 24px;
            }
            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }
          &:last-child {
            font-size: 16px;
            font-weight: 400;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            color: #3c3c3c;
            line-height: 1.6;
            word-break: keep-all;
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
            br{
              @media screen and (max-width: 400px){
                display: none;
              }
            }
          }
        }
      }
      figure {
        padding: 33px 0;
        @media screen and (max-width: 1060px) {
          margin: 0 auto;
          width: 350px;
          text-align: center;
        }
        @media screen and (max-width: 980px) {
          max-width: 420px;
          width: 100%;
          height: 150px;
          border: 1px solid #cac7d2;
          padding: 20px 50px;
          box-sizing: border-box;
          margin-bottom: 50px;
          img{
            max-width: 100%;
          }
        }
        @media screen and (max-width: 600px) {
          max-width: 340px;
          height: 130px;
          padding: 15px 45px;
          display: flex;
          align-items: center;
        }
        @media screen and (max-width: 350px){
          padding: 15px 30px;
        }
      }
    }
  }
}
// cc인증 >  cc인증획득
.acquired {
  padding: 75px 40px;
  background: #3f3569;
  @media screen and (max-width: 600px) {
    padding: 80px 30px;
  }
  .w1200 {
    h2 {
      font-size: 26px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.02em;
      text-align: center;
      color: #ffffff;
      line-height: 1.7;
      word-break: keep-all;
      br{
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      .bold {
        font-weight: 500;
      }
      @media screen and (max-width: 980px) {
        font-size: 22px;
      }
      @media screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 2.2;
      }
    }
  }
}
//cc > 추천
.expectedEffect {
  padding: 105px 50px;
  background: #f4f4f4;
  @media screen and (max-width: 980px) {
    padding: 75px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 30px;
  }
  .w1200 {
    h2 {
      margin: 0 0 20px 0;
      font-size: 36px;
      font-weight: 500;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.035em;
      color: #111111;
      text-align: center;
      @media screen and (max-width: 980px) {
        font-size: 32px;
      }
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }
    p {
      margin: 0 0 50px 0;
      font-size: 18px;
      font-weight: 300;
      font-family: "NanumSquareNeo";
      letter-spacing: -0.025em;
      text-align: center;
      color: #272727;
      line-height: 1.6;
      word-break: keep-all;
      @media screen and (max-width: 600px) {
        font-size: 15px;
        margin-bottom: 35px;
      }
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      @media screen and (max-width: 980px) {
        display: block;
        max-width: 580px;
        margin: 0 auto;
      }
      .item {
        display: flex;
        padding: 50px 40px 50px 60px;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 3px 3px 0px rgba(201, 201, 201, 0.3), 0px 1px 5px 0px rgba(201, 201, 201, 0.3);
        @media screen and (max-width: 980px) {
          padding: 45px 30px 45px 60px;
          margin-bottom: 20px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
        @media screen and (max-width: 600px) {
          padding: 40px 35px 45px;
          margin-bottom: 20px;
          flex-wrap: wrap;
          flex-direction: column-reverse;
          text-align: center;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: calc(100% - 140px);
          @media screen and (max-width: 600px) {
            width: 100%;
            text-align: center;
            margin-top: 30px;
          }
          h3 {
            margin: 0 0 20px 0;
            font-size: 24px;
            font-weight: 500;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            color: #111111;
            word-break: keep-all;
            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }
          p {
            margin: 0;
            font-size: 18px;
            font-weight: 300;
            font-family: "NanumSquareNeo";
            letter-spacing: -0.03em;
            color: #111111;
            text-align: initial;
            line-height: 1.6;
            word-break: keep-all;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
            @media screen and (max-width: 600px) {
              font-size: 16px;
            }
            br{
              @media screen and (max-width: 1250px) {
                display: none;
              }
              @media screen and (max-width: 980px) {
                display: initial;
              }
              @media screen and (max-width: 700px) {
                display: none;
              }
              @media screen and (max-width: 600px) {
                display: initial;
              }
              @media screen and (max-width: 500px) {
                display: none;
              }
            }
          }
        }
        &:after {
          content: "";
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center;
          width: 126px;
          height: 126px;
          @media screen and (max-width: 600px) {
            margin: 0 auto;
            width: 120px;
            height: 120px;
          }
        }
        &:nth-child(1) {
         &:after {
           background-image: url(../../public/images/icon/sub_cc_item01.png);
         }
        }
        &:nth-child(2) {
         &:after {
           background-image: url(../../public/images/icon/sub_cc_item02.png);
         }
        }
        &:nth-child(3) {
         &:after {
           background-image: url(../../public/images/icon/sub_cc_item03.png);
         }
        }
        &:nth-child(4) {
         &:after {
           background-image: url(../../public/images/icon/sub_cc_item04.png);
         }
        }
      }
    }
  }
}
.loading {
  padding: 80px 0;
  text-align: center;
  .loadIcon {
    margin: 0 auto 20px auto;
    color: #111111;
    @media screen and (max-width: 800px) {
      width: 50px;
      height: 50px;
    }
    @media screen and (max-width: 400px) {
      width: 40px;
      height: 40px;
    }
  }
  p {
    font-size: 25px;
    font-weight: 500;
    font-family: "NanumSquareNeo";
    letter-spacing: -0.025em;
    color: #111111;
    @media screen and (max-width: 800px) {
      font-size: 20px;
    }
    @media screen and (max-width: 400px) {
      font-size: 17px;
    }
  }
}