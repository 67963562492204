* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

.wrap {
    /*width: 1920px;*/
    margin: 0 auto;
}

.inner {
    width: 1720px;
    margin: 0 auto;
}

.container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.w1570 {
    width: 1570px;
    margin: 0 auto;
}

.w1320{
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
}

.w1300 {
    width: 1300px;
    margin: 0 auto;
}

.w1250 {
    width: 1250px;
    margin: 0 auto;
}

.w1200 {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.w800 {
    width: 800px;
    margin: 0 auto;
}

.w660 {
    max-width: 660px;
    width: 100%;
    margin: auto;
}

.w620 {
    width: 620px;
    margin: 0 auto;
}

.w490 {
    width: 490px;
    margin: 0 auto;
}

.w350 {
    width: 350px;
    margin: 0 auto;
}

.flex {
    display: flex;
}

.ml360 {
    margin: 0 0 0 360px;
}

.gap100 {
    gap: 120px;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

* {
   outline: 1px solid --#FF6600;
}