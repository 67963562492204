.popup_con {
  padding: 10px 25px 30px;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
}
.terms_title {
  margin: 0 0 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  line-height: 25px;
  font-size: 24px;
  font-weight: 700;
}
.terms_text {
  margin: 0 0 45px 0;
}
.terms_text_number {
  margin: 0 0 5px 0;
  font-weight: 500;
}
.terms_text_underText {
  margin: 0 0 5px 0;
}
.terms_text_list {
  margin: 0 0 5px 0;
}
.terms_subTitle {
  margin: 0 0 20px 0;
}
.terms_text_listTitle{
  margin: 0 0 5px 0;
}
.mb30{
  margin: 0 0 30px 0;
}
.mb20{
  margin: 0 0 20px 0;
}
p{
  margin: 0;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  font-family: "NanumSquareNeo";
  letter-spacing: -0.025em;
  line-height: 1.6;
  word-break: keep-all;
}