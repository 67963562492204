footer {
  padding: 60px 0px;
  position: relative;
  @media screen and (max-width: 940px) {
    padding: 55px 0px;
  }
  @media screen and (max-width: 600px) {
    padding: 65px 0px;
  }
  @media screen and (max-width: 350px) {
    padding: 50px 0px;
  }
}
.inner {
  @media screen and (max-width: 1800px) {
    width: auto;
    padding: 0 50px;
  }
  @media screen and (max-width: 940px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 30px;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #bec0c4;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
  .left {
    display: flex;
    gap: 35px;
    @media screen and (max-width: 750px) {
      gap: 25px;
    }
    @media screen and (max-width: 360px) {
      flex-direction: column;
      gap: 15px;
    }
    .about, .contact {
      span {
        display: inline-block;
        font-size: 30px;
        vertical-align: middle;
        color: #272727;
        @media screen and (max-width: 800px) {
          font-size: 22px;
        }
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
        &.bigfont {
          position: relative;
          font-size: 20px;
          font-weight: 600;
          font-family: 'NanumSquareNeo';
          letter-spacing: -0.03em;
          display: flex;
          align-items: center;
          @media screen and (max-width: 940px) {
            font-size: 18px;
          }
          @media screen and (max-width: 750px) {
            font-size: 14px;
            font-weight: 400;
          }
          @media screen and (max-width: 380px) {
            font-size: 13px;
          }
          &:after{
            content: "";
            display: inline-block;
            margin-left: 15px;
            background-image: url(../../../public/images/icon/company_arrow.png);
            background-repeat: no-repeat;
            background-position: center;
            width:10px;
            height: 20px;
            @media screen and (max-width: 940px) {
              margin-left: 15px;
              background-image: url(../../../public/images/icon/company_arrow_m.png);
              background-position: center top;
              width: 7px;
              height: 11px;
            }
          }
        }
      }
    }
  }
  .right {
    display: flex;
    gap: 35px;
    @media screen and (max-width: 750px) {
      gap: 25px;
    }
    @media screen and (max-width: 360px) {
      flex-direction: column;
      gap: 15px;
    }
    div {
      font-size: 14px;
      font-family: 'NanumSquareNeo';
      font-weight: 400;
      @media screen and (max-width: 750px) {
        font-size: 14px;
      }
      @media screen and (max-width: 380px) {
        font-size: 13px;
      }
      button {
        display: inline-block;
        padding: 0;
        color: #484848;
        outline: none;
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:after {
          content: "";
          display: inline-block;
          margin-left: 10px;
          background-image: url(../../../public/images/icon/pr_arrow.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 8px;
          height: 12px;
          @media screen and (max-width: 750px) {
            background-image: url(../../../public/images/icon/company_arrow_m.png);
            background-position: center top;
            width: 7px;
            height: 11px;
          }
        }
      }
    }
  }
}
.bottom {
  margin: 0 0 30px 0;
  @media screen and (max-width: 1000px) {
    margin: 0 0 10px 0;
  }
  li {
    font-size: 14px;
    font-weight: 400;
    font-family: 'NanumSquareNeo';
    color: #484848;
    word-break: keep-all;
    @media screen and (max-width: 1000px) {
      line-height: 1.5;
    }
    @media screen and (max-width: 361px) {
      font-size: 13px;
    }
    &:first-child {
      margin: 0 0 15px 0;
      @media screen and (max-width: 1000px) {
        margin: 0 0 20px 0;
      }
    }
    &.flex {
      gap: 20px;
      @media screen and (max-width: 1000px) {
        margin: 0;
        width: 300px;
        flex-wrap: wrap;
        gap: 5px;
        p:first-of-type {
          margin-right: 15px;
        }
      }
      @media screen and (max-width: 620px) {
        flex-direction: column;
        gap: 5px;
      }
      @media screen and (max-width: 361px) {
        gap: 7px;
      }
    }
    .w1000 {
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
    .w900 {
      display: none;
      @media screen and (max-width: 1000px) {
        display: block;
      }
    }
    .w480{
      display: none;
      @media screen and (max-width: 480px) {
        display: block;
        margin: 0 0 7px 0;
      }
    }
    p {
      color: #484848;
      font-weight: 400;
      span {
        display: inline-block;
        margin: 0 10px 0 0;
        font-weight: 600;
        color: #000000;
        @media screen and (max-width: 361px) {
          margin: 0 7px 0 0;
          font-size: 13px;
        }
      }
    }
  }
}
.copy {
  p {
    font-size: 14px;
    font-weight: 400;
    font-family: 'NanumSquareNeo';
    color: #8e8e8e;
    @media screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
}
.toTop{
  position: fixed;
  bottom: 5%;
  right: 3%;
  cursor: pointer;
  z-index: 7
;
  @media screen and (max-width: 800px) {
    right: 4.5%;
  }
  .totop {
    display: block;
    padding: 15px 15px 12px;
    font-size: 29px;
    color: #000000;
    transform: rotate(180deg);
    border-radius: 50%;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    transition: 0.3s;
    @media screen and (max-width: 800px) {
      padding: 12px 12px 9px;
      font-size: 23px;
    }
    @media screen and (max-width: 400px) {
      padding: 10px 10px 8px;
      font-size: 19px;
    }
    //img {
    //  transform: rotate(180deg);
    //  transition: all 0.3s ease 0s;
    //}
    &.active {
      transform: rotate(0deg);
    }
  }
}