@keyframes ani_1 {
  0% {
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes ani_2 {
  0% {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes ani_3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani_4 {
  0% {
    width: 0%;
  }
  100% {
    width: 1543px;
  }
}

@keyframes activeMenuBar02 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-315deg);
  }
}

@keyframes activeMenuBar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-13px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(315deg);
  }
}

@keyframes main_effect {
  0% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes down {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(5px);
  }
  40% {
    transform: translateY(-5px);
  }
  55%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes moving_icon1 {
  0% {
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;

  }
}

@keyframes moving_icon2 {
  0% {
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
    z-index: -10;
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    z-index: -10;
  }
}

@keyframes sdb04 {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(70%);
  }
  100% {
    transform: translateY(2px);
  }
}

@keyframes spinspin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slidein {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}