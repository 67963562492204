header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  &.fixed {
    .inner {
      line-height: 80px;
      @media screen and (max-width: 800px) {
        line-height: 65px;
      }
      .lnb {
        top: 75px;
      }
    }
    .toggle {
      &.active {
        top: 10px;
        @media screen and (max-width: 1300px) {
          top: 3px;
        }
        @media screen and (max-width: 1000px) {
          top: 0;
        }
      }
    }
  }
  .inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    width: auto;
    line-height: 100px;
    transition: 0.3s ease-in-out;
    @media screen and (max-width: 1550px) {
      padding: 0 50px;
      line-height: 80px;
      width: auto;
    }
    @media screen and (max-width: 940px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 800px) {
      line-height: 65px;
      padding: 0 30px;
    }
    h1 {
      width: 160px;
      @media screen and (max-width: 600px) {
        width: 127px;
      }
    }
  }
}

.gnb {
  display: flex;
  gap: 60px;
  align-items: center;
  @media screen and (max-width: 1550px) {
    margin-right: 0;
  }
}
.gnbList {
  display: flex;
  flex: 1;
  @media screen and (max-width: 1000px) {
    display: none;
  }
  & > li {
    position: relative;
    font-size: 18px;
    font-weight:400;
    font-family: 'NanumSquareNeo';
    cursor: pointer;
    border-bottom: 1px solid transparent;
    @media screen and (max-width: 1300px) {
      font-size: 17px;
    }
    @media screen and (max-width: 1250px) {
      font-size: 16px;
    }
    .gnbItem {
      display: block;
      padding: 0 25px;
      @media screen and (max-width: 1250px) {
        padding: 0 20px;
      }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: transparent;
      }
    }
  }
}
.lnb {
  position: absolute;
  top: 95px;
  left: 50%;
  transform: translateX(-50%);
  //padding: 0 30px;
  line-height: 40px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  background: #ffffff;
  box-sizing: border-box;
  z-index: 999;
  .lnbList {
    display: block;
    width: 100%;
    white-space: nowrap;
    font-size: 16px;
    text-align: center;
    a {
      display: block;
      width: 100%;
      padding: 0 40px;
    }
  }
}
.lnbList:hover {
  font-weight: 500;
  color: #4d76cc;
}
.gnbList > li:nth-child(1) .lnb {
  visibility: hidden;
  height: 250px;
  overflow: hidden;
  opacity: 0;
  transition: all .3s;
  &.active{
    visibility: visible;
    opacity: 1;
    transition: all .3s;
  }
}
.gnbList > li:nth-child(2) .lnb {
  visibility: hidden;
  height: 130px;
  overflow: hidden;
  &.active{
    visibility: visible;
    opacity: 1;
  }
}
.gnbList > li:nth-child(3) .lnb {
  visibility: hidden;
  height: 170px;
  overflow: hidden;
  &.active{
    left: 50%;
    transform: translateX(-50%);
    visibility: visible;
    overflow: hidden;
    opacity: 1;
  }
}
.gnbList > li:nth-child(4) .lnb{
  visibility: hidden;
  height: 210px;
  overflow: hidden;
  &.active{
    visibility: visible;
    overflow: hidden;
    opacity: 1;
  }
}
.gnbList > li:nth-child(5) .lnb {
  visibility: hidden;
  height: 170px;
  overflow: hidden;
  &.active{
    visibility: visible;
    overflow: hidden;
    opacity: 1;
  }
}
.gnbList > li:nth-child(6) .lnb,{
  visibility: hidden;
  height: 130px;
  overflow: hidden;
  a {
    display: block;
    width: 100%;
  }
  &.active{
    visibility: visible;
    overflow: hidden;
    opacity: 1;
  }
}
.toggle {
  position: relative;;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 100;
  @media screen and (max-width: 800px) {
    width: 25px;
  }
  &.active {
    z-index: 100;
    span {
      background: #222222;
      &:nth-of-type(1) {
        transform:rotate(-45deg) translate(5px, -10px);
      }
      &:nth-of-type(2) {
        transform: rotate(45deg) translate(3px, 7px);
      }
      &:nth-of-type(3) {
        opacity: 0;
      }
    }
  }

  span {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 2px;
    background: #222222;
    transition: all 0.1s;
    &:nth-child(1) {
      bottom: 0;
    }

    &:nth-child(2) {
      top: 0;
    }

    &:nth-child(3) {
      top: 8px;
      width: 22px;
      @media screen and (max-width: 800px) {
        width: 19px;
      }
    }
  }
}
.hiddenMenu {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  color: #3e3e3e;
  z-index: 5;
  @media screen and (max-width: 1300px) {
    padding: 0 20px;
    width: auto;
  }
  @media screen and (max-width: 1000px) {
    padding: 0;
  }
  .hiddenLogo {
    display: flex;
    justify-content: space-between;
    width: 1725px;
    line-height: 100px;
    margin: 0 auto;
    @media screen and (max-width: 1300px) {
      padding: 0 30px;
      width: auto;
      line-height: 80px;
    }
    @media screen and (max-width: 1000px) {
      padding: 0 0 0 50px;
      display: none;
    }
  }
  .hiddenGnb {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 1300px;
    margin: 130px auto 0;
    z-index: 2;
    @media screen and (max-width: 1300px) {
      padding: 0 30px;
      width: auto;
    }
    @media screen and (max-width: 1000px) {
      margin: 100px auto 0;
      padding: 0;
      flex-direction: column;
      background: #f5f6f8;
      border-top: 1px solid #c3c3c3;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #f5f6f8;
        z-index: -1;
      }
    }
    @media screen and (max-width: 800px) {
      margin: 65px auto 0;
    }
    & > li {
      @media screen and (max-width: 1000px) {
        padding: 0 0 0 30px;
        color: #696969;
        &.active {
          background: #ffffff;
          color: #4d76cc;
          button{
            color: #4d76cc;
          }
          .hiddenLnb {
            display: block;
          }
        }
      }
      @media screen and (max-width: 500px) {
        padding: 0 0 0 25px;
      }
    }
    .gnbList {
      display: inline-block;
      margin: 0 0 50px 0;
      padding: 0;
      font-size: 28px;
      font-weight: 600;
      font-family: 'NanumSquareNeo';
      letter-spacing: -0.04em;
      border: 0;
      background-color: transparent;
      text-align: left;
      color: #696969;
      @media screen and (max-width: 1000px) {
        margin: 0;
        height: 100%;
        font-size: 17px;
        font-weight: 500;
        line-height: 70px;
        display: block;
        width: 100%;
      }
      @media screen and (max-width: 400px) {
        line-height: 60px;
      }
    }
  }
}
.hiddenLnb {
  display: flex;
  flex-direction: column;
  margin: 0 0 120px 0;
  @media screen and (max-width: 1300px) {
    margin: 0 0 100px 0;
  }
  @media screen and (max-width: 1000px) {
    position: absolute;
    display: none;
    padding: 0 0 0 30px;
    top: 0;
    left: 200px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    background: #ffffff;
  }
  @media screen and (max-width: 500px) {
    padding: 0 0 0 30px;
    left: 170px;
  }
  .lngList {
    display: block;
    margin: 0 0 30px 0;
    font-size: 20px;
    font-weight: 400;
    font-family: 'NanumSquareNeo';
    letter-spacing: -0.025em;
    color: #959ba3;
    @media screen and (max-width: 1200px) {
      font-size: 17px;
      color: #696969;
    }
    @media screen and (max-width: 1000px) {
      margin: 0;
      line-height: 70px;
    }
    @media screen and (max-width: 400px) {
      margin: 0;
      font-size: 16px;
      line-height: 60px;
    }
    a {
      display: block;
      width: 100%;
    }
    &:last-child {
      margin: 0;
    }
    &:hover {
      color: #3869c1;
      font-weight: 500;
    }
  }
}
.h_contact {
  color: transparent;
  position: relative;
  &.inner {
    @media screen and (max-width: 1300px) {
      width: auto;
    }
  }
  .contit {
    display: block;
    margin: 0 0 0 auto;
    @media screen and (max-width: 1300px) {
      position: absolute;
      top: 0;
      right: -3%;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
    figure {
      &:after {
        content: "";
        display: inline-block;
        background-image: url(../../../public/images/icon/contactus_01.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 1210px;
        height: 120px;
        transition: 0.5s;
        @media screen and (max-width: 1300px) {
          background-position: right 50px center;
          background-size: 80%;
        }
        @media screen and (max-width: 1150px) {
          background-size: 75%;
        }
      }
    }
  }
}
.contit:hover {
  figure {
    &:after {
      content: "";
      display: inline-block;
      background-image: url(../../../public/images/icon/contactus_02.png);
      background-repeat: no-repeat;
      background-position: center;
      width: 1210px;
      height: 120px;
      transition: 0.5s;
      @media screen and (max-width: 1300px) {
        background-position: right 50px center;
        background-size: 80%;
      }
      @media screen and (max-width: 1150px) {
        background-size: 75%;
      }
    }
  }
}