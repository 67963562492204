@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 400;
    src: url('../../public/fonts/NotoSansKR-Regular.woff') format('woff');
}

@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/NotoSansKR-Medium.woff') format('woff');
}

@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 600;
    src: url('../../public/fonts/NotoSansKR-Bold.woff') format('woff');
}

@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 700;
    src: url('../../public/fonts/NotoSansKR-Black.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    src: url('../../public/fonts/Pretendard-Light.woff2') format('font-woff2');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url('../../public/fonts/Pretendard-Regular.woff2') format('font-woff2');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/Pretendard-Medium.woff2') format('font-woff2');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    src: url('../../public/fonts/Pretendard-Bold.woff2') format('font-woff2');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    src: url('../../public/fonts/Pretendard-ExtraBold.woff2') format('font-woff2');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    src: url('../../public/fonts/Pretendard-Black.woff') format('font-woff2');
}

@font-face {
    font-family: "NanumSquareNeo";
    font-style: normal;
    font-weight: 200;
    src: url('../../public/fonts/NanumSquareNeoTTF-aLt.woff') format('woff');
}

@font-face {
    font-family: "NanumSquareNeo";
    font-style: normal;
    font-weight: 300;
    src: url('../../public/fonts/NanumSquareNeoTTF-bRg.woff') format('woff');
}

@font-face {
    font-family: "NanumSquareNeo";
    font-style: normal;
    font-weight: 400;
    src: url('../../public/fonts/NanumSquareNeoTTF-cBd.woff') format('woff');
}

@font-face {
    font-family: "NanumSquareNeo";
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/NanumSquareNeoTTF-dEb.woff') format('woff');
}

@font-face {
    font-family: "NanumSquareNeo";
    font-style: normal;
    font-weight: 600;
    src: url('../../public/fonts/NanumSquareNeoTTF-eHv.woff') format('woff');
}

@font-face {
    font-family: "ITC Eras";
    font-style: normal;
    font-weight: 800;
    src: url('../../public/fonts/ITCErasStd-Bold.woff') format('woff');
}

@font-face {
    font-family: "ErasITC";
    font-style: normal;
    font-weight: 800;
    src: url('../../public/fonts/ErasBoldITC.ttf') format('truetype');
}

@font-face {
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 200;
    src: url('../../public/fonts/NanumSquareL.woff') format('woff');
}

@font-face {
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 300;
    src: url('../../public/fonts/NanumSquareR.woff') format('woff');
}

@font-face {
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 400;
    src: url('../../public/fonts/NanumSquareB.woff') format('woff');
}

@font-face {
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/NanumSquareEB.woff') format('woff');
}